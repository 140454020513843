import styled from 'styled-components';

import { GlobalAnimationStyleObject } from '../../../../sites/animations';
import { StyledNextImageWrapper } from '../../../basic/NextImage/NextImageStyles';
import { MarginWrapper } from '../../../styles/contentBlockMedia';
import { CustomSizeDescription, MediaFontSize } from '../../../styles/fontSize';
import {
  ColourTheme,
  ContentBlockAnimationMediaAndText50PercentSplit,
  FontSize,
  Margin,
  Maybe,
  MultipleDefaultContentPositions,
  TextAlignment,
} from '../../../types/middleware-types';
import { StyledCarouselProductItemWrapper } from '../ContentBlockCarousel/CarouselProductItem/CarouselProductItemStyles';
import {
  StyledModuleItemLinkWrapper,
  StyledModuleItemWrapper,
} from '../MediaModuleItem/ModuleItemStyles';

interface StyledMediaAndText50PercentSplitWrapperProps {
  $mediaTopOnMobile: boolean;
  $mediaLeftOnDesktop: boolean;
  $marginDesktop: Margin;
  $marginMobile: Margin;
  $backgroundColour?: Maybe<string>;
  $absolute: boolean;
  $animate: boolean;
  $animationType?: Maybe<ContentBlockAnimationMediaAndText50PercentSplit>;
  $animationStyle?: GlobalAnimationStyleObject;
  $contentDefaultPositionDesktop: MultipleDefaultContentPositions;
}

export const StyledMediaAndText50PercentSplitWrapper = styled.div<StyledMediaAndText50PercentSplitWrapperProps>`
  display: flex;
  overflow: hidden;
  flex-direction: ${({ $mediaTopOnMobile }) => ($mediaTopOnMobile ? 'column' : 'column-reverse')};

  ${MarginWrapper};
  
  @media ${({ theme }) => theme.devices.large} {
    flex-direction: ${({ $mediaLeftOnDesktop }) => ($mediaLeftOnDesktop ? 'row' : 'row-reverse')};
  }
`;

interface StyledMediaAndText50PercentSplitImageTextWrapperProps {
  $animate?: boolean;
  $animationType?: Maybe<ContentBlockAnimationMediaAndText50PercentSplit>;
  $animationStyle?: GlobalAnimationStyleObject;
  $moduleIndex?: number;
}

export const StyledMediaAndText50PercentSplitImageTextWrapper = styled.div<StyledMediaAndText50PercentSplitImageTextWrapperProps>`
  position: relative;
  width: 100%;

  @media ${({ theme }) => theme.devices.large} {
    width: 50%;
    display: flex;
  }
  ${({ $animationType, theme }) =>
  $animationType === 'MOVEFADE'
    ? `
    transform: translateY(-100%);
    background: ${theme.vars.white};`
    : ''};
  ${({ $animate, $animationType, $animationStyle }) => {
      if ($animate) {
        if ($animationType === 'MOVEFADE') {
          return $animationStyle?.scrollDown;
        }
      }
      return null;
    }}

  ${StyledModuleItemWrapper} {
    align-self: center;
  }
  ${StyledModuleItemLinkWrapper} {
    align-self: center;
  }

  video,
  picture,
  ${StyledNextImageWrapper} {
    source,
    img {
      width: 100%;
      ${({ $animationType }) => ($animationType === 'MOVEFADE' ? 'opacity: 0' : '')};
      ${({ $animate, $animationType, $animationStyle, $moduleIndex }) => {
      if ($animate) {
        if (
          ($animationType === 'BACKGROUNDSHRINK' && $moduleIndex === 0) ||
          $animationType === 'BACKGROUNDPARALLAX'
        ) {
          return $animationStyle?.zoomIn;
        }
        if ($animationType === 'MOVEFADE') {
          return $animationStyle?.fadeIn;
        }
      }
      return null;
    }}
    }
  }
`;

interface StyledMediaAndText50PercentSplitContentWrapperProps {
  $animate: boolean;
  $animationType?: Maybe<ContentBlockAnimationMediaAndText50PercentSplit>;
  $animationStyle: GlobalAnimationStyleObject;
  $absolute: boolean;
  $top?: Maybe<number>;
  $left?: Maybe<number>;
  $contentDefaultPositionDesktop: MultipleDefaultContentPositions;
  $textAlignment?: Maybe<TextAlignment>;
  $colourTheme: ColourTheme;
  $fontMediaSize: FontSize;
}

export const StyledMediaAndText50PercentSplitContentWrapper = styled.div<StyledMediaAndText50PercentSplitContentWrapperProps>`
  padding: ${({ theme }) => theme.vars.sizeM};
  text-align: ${({ $textAlignment }) =>
  $textAlignment !== 'CENTRE' ? $textAlignment?.toLowerCase() : 'center'};
  color: ${({ theme, $colourTheme }) => {
    if ($colourTheme === 'LIGHT') {
      return theme.blockTextColors.light;
    }
    if ($colourTheme === 'DARK') {
      return theme.blockTextColors.dark;
    }
    return theme.blockTextColors.default;
  }};

  @media ${({ theme }) => theme.devices.large} {
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: ${({ $contentDefaultPositionDesktop, $top }) => {
    if ($contentDefaultPositionDesktop === 'ABOVE' || $top !== null) {
      return 'flex-start';
    }
    if ($contentDefaultPositionDesktop === 'BELOW') {
      return 'flex-end';
    }
    return 'center';
  }};
    justify-self: ${({ $left }) => {
    if ($left !== null) {
      return 'flex-start';
    }
    return 'center';
  }};
    padding-top: ${({ $top }) => ($top === null ? 0 : $top)}%;
    padding-left: 3rem;
    padding-right: 3rem;
  }

  p {
    font-weight: 400;
    margin: 0;
    ${CustomSizeDescription}
    line-height: 2.2rem !important;
  }

  .dangerous-content {
    a {
      color: inherit;
    }

    h1 {
      @media ${({ theme }) => theme.devices.smallOnly} {
        line-height: unset;
      }
    }
  }

  .content-links {
    display: block;
    margin-left: ${({ $textAlignment }) =>
    $textAlignment === 'CENTRE' || $textAlignment === 'RIGHT' ? 'auto' : 0};
    margin-right: ${({ $textAlignment }) => ($textAlignment === 'CENTRE' ? 'auto' : 0)};

    a {
      display: inline-block;
      width: fit-content;
      margin: 1.6rem 1.6rem 1.6rem 0;
      background: ${({ theme }) => theme.vars.black};
      color: ${({ theme }) => theme.vars.white};
      border: 1px solid ${({ theme }) => theme.vars.black};
      transition: all 0.3s;
      padding: 1rem;
      text-transform: uppercase;

      div {
        font-size: 1.2rem;
      }

      &:hover,
      &:focus {
        text-decoration: ${({ theme }) => theme.buttons.primaryHover.textDecoration};
        background: ${({ theme }) => theme.buttons.primaryHover.background};
        color: ${({ theme }) => theme.buttons.primaryHover.color};
        border-color: ${({ theme }) => theme.buttons.primaryHover.borderColor};
      }
    }
    ${({ $animate, $animationType, $animationStyle }) =>
      $animate && $animationType === 'BACKGROUNDPARALLAX' && $animationStyle.fadeIn}
  }

  .custom-size-title {
    overflow: hidden;

    h2 {
      ${({ $animate, $animationType, $animationStyle }) =>
        $animate && $animationType === 'BACKGROUNDPARALLAX' && $animationStyle.moveUp}
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-top: ${({ theme }) => theme.vars.sizeXL};
  }
  ${MediaFontSize};
`;

export const StyledMediaAndText50PercentSplitCarouselWrapper = styled.div`
  margin-top: ${({ theme }) => theme.vars.sizeXXL};
  margin-bottom: ${({ theme }) => theme.vars.sizeXXL};

  ${StyledModuleItemWrapper} {
    width: 66.66%;
    flex: 0 0 66.66%;
  }

  ${StyledModuleItemLinkWrapper} {
    width: 66.66%;
    flex: 0 0 66.66%;
  }

  ${StyledCarouselProductItemWrapper} {
    width: calc(66.66% - 24px);
    flex: 0 0 calc(66.66% - 24px);
  }

  @media ${({ theme }) => theme.devices.large} {
    ${StyledModuleItemWrapper} {
      width: auto;
      flex: 0 0 100%;
    }

    ${StyledModuleItemLinkWrapper} {
      width: auto;
      flex: 0 0 100%;
    }

    ${StyledCarouselProductItemWrapper} {
      width: auto;
      flex: 0 0 100%;
    }
  }
`;
