/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import Link from 'next/link';

import { GlobalAnimationsStyle } from '../../../../sites/animations';
import {
  BlockMediaBasicItem,
  ColourTheme,
  ContentBlockAnimationMediaAndText50PercentSplit,
  FontSize,
  HtmlLink,
  Margin,
  Maybe,
  MediaAndText50PercentSplitAccordion as MediaAndText50PercentSplitAccordionType,
  MultipleDefaultContentPositions,
  ProductListItem,
  TextAlignment,
} from '../../../types/middleware-types';
import { isImageV2 } from '../../../types/type-checkers';
import formatPromoEvent from '../../../utils/DataLayer/formatPromoEvent';
import Carousel from '../../Carousel';
import { SliderSettings } from '../../Slider/Slider.types';
import CarouselProductItem from '../ContentBlockCarousel/CarouselProductItem';
import ModuleItem from '../MediaModuleItem';
import MediaAndText50PercentSplitAccordion from './MeadiaAndText50PercentSplitAccordion';
import {
  StyledMediaAndText50PercentSplitCarouselWrapper,
  StyledMediaAndText50PercentSplitContentWrapper,
  StyledMediaAndText50PercentSplitImageTextWrapper,
  StyledMediaAndText50PercentSplitWrapper,
} from './MediaAndText50PercentSplitStyles';

export interface MediaAndText50PercentSplitStructureProps {
  id: string;
  carouselItems?: Array<
    ProductListItem & {
      marginMobile: { leftPixels: number; rightPixels: number };
      marginDesktop: { leftPixels: number; rightPixels: number };
      campaignId?: Maybe<string>;
    }
  > | null;
  scrollableOnDesktop?: boolean;
  arrowType?: string;
  marginDesktop: Margin;
  marginMobile: Margin;
  textAlignment?: Maybe<TextAlignment>;
  fontSize: FontSize;
  colourTheme: ColourTheme;
  backgroundColour?: Maybe<string>;
  title?: Maybe<string>;
  subtitle?: Maybe<string>;
  callToActions?: Maybe<Array<HtmlLink>>;
  contentDefaultPositionDesktop: MultipleDefaultContentPositions;
  contentHtml: string;
  mediaItem: BlockMediaBasicItem;
  mediaLeftOnDesktop: boolean;
  mediaTopOnMobile: boolean;
  absolute: boolean;
  top?: Maybe<number>;
  left?: Maybe<number>;
  pageName: Maybe<string> | undefined;
  forwardedRef: (node: HTMLDivElement) => void;
  animate: boolean;
  animationType?: Maybe<ContentBlockAnimationMediaAndText50PercentSplit>;
  animationStyle: GlobalAnimationsStyle;
  moduleIndex: number;
  hasCarousel: boolean;
  sliderSettings: SliderSettings;
  showArrows: boolean;
  accordion?: Maybe<Array<Maybe<MediaAndText50PercentSplitAccordionType>>>;
  useReactRoutingLinks: boolean;
}

const MediaAndText50PercentSplitStructure: FC<MediaAndText50PercentSplitStructureProps> = ({
  id,
  mediaItem,
  marginMobile,
  marginDesktop,
  callToActions,
  title,
  subtitle,
  textAlignment,
  colourTheme,
  fontSize,
  contentDefaultPositionDesktop,
  absolute,
  top,
  left,
  contentHtml,
  mediaLeftOnDesktop,
  mediaTopOnMobile,
  pageName,
  forwardedRef,
  animationType,
  animate,
  animationStyle,
  moduleIndex,
  hasCarousel,
  carouselItems,
  sliderSettings,
  showArrows,
  scrollableOnDesktop,
  backgroundColour,
  accordion,
  useReactRoutingLinks,
}) => {
  return (
    <StyledMediaAndText50PercentSplitWrapper
      ref={forwardedRef}
      $marginMobile={marginMobile}
      $marginDesktop={marginDesktop}
      $backgroundColour={backgroundColour}
      $mediaLeftOnDesktop={mediaLeftOnDesktop}
      $mediaTopOnMobile={mediaTopOnMobile}
      $absolute={absolute}
      $contentDefaultPositionDesktop={contentDefaultPositionDesktop}
      data-hookid={`${pageName}ModuleK`}
      $animationType={animationType}
      $animate={animate}
      $animationStyle={animationStyle()}
    >
      <StyledMediaAndText50PercentSplitImageTextWrapper
        $animationType={animationType}
        $animate={animate}
        $animationStyle={animationStyle()}
        $moduleIndex={moduleIndex}
      >
        <ModuleItem
          {...mediaItem}
          id={id}
          imgPriority={moduleIndex === 0}
        />
      </StyledMediaAndText50PercentSplitImageTextWrapper>
      <StyledMediaAndText50PercentSplitImageTextWrapper>
        <StyledMediaAndText50PercentSplitContentWrapper
          $textAlignment={textAlignment}
          $colourTheme={colourTheme}
          $absolute={absolute}
          $top={top}
          $left={left}
          $contentDefaultPositionDesktop={contentDefaultPositionDesktop}
          $animationType={animationType}
          $animate={animate}
          $animationStyle={animationStyle()}
          $fontMediaSize={fontSize}
        >
          {title && (
            <div className="custom-size-title">
              <h2>{title}</h2>
            </div>
          )}
          {subtitle && <p className="custom-size-subtitle">{subtitle}</p>}
          <div className="dangerous-content" dangerouslySetInnerHTML={{ __html: contentHtml }} />
          {hasCarousel && carouselItems && (
            <StyledMediaAndText50PercentSplitCarouselWrapper>
              <Carousel
                items={carouselItems}
                component={CarouselProductItem}
                scrollableOnMobile={true}
                scrollableOnDesktop={scrollableOnDesktop}
                showArrows={showArrows}
                settings={sliderSettings}
              />
            </StyledMediaAndText50PercentSplitCarouselWrapper>
          )}
          <div className="content-links">
            {callToActions &&
              callToActions?.length > 0 &&
              callToActions.map((cta, index) => (
                <>
                  {useReactRoutingLinks ? (
                    <Link href={cta.url} passHref legacyBehavior>
                      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions  */}
                      <a
                        data-hookid={`${cta.url.split('/').pop()}CareersButton`}
                        className="custom-size-link"
                        key={`${cta.url}${cta.newWindow}${cta.text}`}
                        {...(cta.newWindow ? { target: '_blank', rel: 'noreferrer' } : {})}
                        onClick={() =>
                          formatPromoEvent(
                            title,
                            index + 1,
                            cta.url,
                            isImageV2(mediaItem?.media) ? mediaItem?.media?.desktop.src : '',
                            `cta${index + 1}`,
                            'click',
                          )}
                      >
                        <div>{cta.text}</div>
                      </a>
                    </Link>
                  ) : (
                    <a
                      data-hookid={`${cta.url.split('/').pop()}CareersButton`}
                      className="custom-size-link"
                      key={`${cta.url}${cta.newWindow}${cta.text}`}
                      href={cta.url}
                      {...(cta.newWindow ? { target: '_blank', rel: 'noreferrer' } : {})}
                      onClick={() =>
                        formatPromoEvent(
                          title,
                          index + 1,
                          cta.url,
                          isImageV2(mediaItem?.media) ? mediaItem?.media?.desktop.src : '',
                          `cta${index + 1}`,
                          'click',
                        )}
                    >
                      <div>{cta.text}</div>
                    </a>
                  )}
                </>
              ))}
          </div>
          {accordion && accordion.length > 0 && (
            <MediaAndText50PercentSplitAccordion accordion={accordion} fontSize={fontSize} />
          )}
        </StyledMediaAndText50PercentSplitContentWrapper>
      </StyledMediaAndText50PercentSplitImageTextWrapper>
    </StyledMediaAndText50PercentSplitWrapper>
  );
};

export default MediaAndText50PercentSplitStructure;
