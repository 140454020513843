'use client'

import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { set } from 'local-storage';

import { GlobalAnimationsStyle } from '../../../../../sites/animations';
import { selectPageName, selectShowBrandName } from '../../../../features/static';
import {
  Margin,
  Maybe,
  Media,
  ProductListItem,
  ProductMedia,
  TextAlignment,
} from '../../../../types/middleware-types';
import { DEFAULT_ON_SCROLL_STYLE } from '../../../../utils/customHooks/useAnimationOnScroll';
import CarouselProductItemStructure from './CarouselProductItemStructure';

export interface CarouselProductItemProps extends Omit<ProductListItem, 'media'> {
  animationType?: string;
  animate?: boolean;
  animationStyle?: GlobalAnimationsStyle;
  index?: number;
  marginMobile: Margin;
  marginDesktop: Margin;
  textAlignment?: TextAlignment;
  colourTheme?: string;
  media: Maybe<Media> | ProductMedia | undefined;
  campaignId?: Maybe<string>;
  pageName: string;
  isNewInPLP: boolean | null;
  showBrandNames?: boolean;
  showPrices?: boolean;
  showProductNames?: boolean;
}

const CarouselProductItem: FC<CarouselProductItemProps> = ({
  animationType = '',
  animate = false,
  animationStyle = DEFAULT_ON_SCROLL_STYLE,
  index = 0,
  marginMobile,
  marginDesktop,
  textAlignment,
  colourTheme = 'DEFAULT',
  price,
  media,
  campaignId,
  pageName,
  isNewInPLP,
  showBrandNames,
  showPrices,
  showProductNames,
  ...rest
}) => {
  const showBrandName = useSelector(selectShowBrandName);
  const animStyle = animationStyle ? animationStyle(index * 200) : {};
  const pageNameData = useSelector(selectPageName);

  // For GTM PDP datalayer.
  const addProductPositionToLocalStorage = (productPosition: number, lineNumber: string) => {
    set('previous_product_position', {
      position: productPosition.toString(),
      lineNum: lineNumber,
      campaignId,
    });
  };

  const componentProps = {
    product: {
      ...rest,
      price,
      media,
      imgSizes: '(max-width: 1024px) 70vw, 25vw',
    },
    images: media,
    showQuickBuy: false,
    addProductPositionToLocalStorage,
    marginMobile,
    marginDesktop,
    textAlignment,
    colourTheme,
    animate,
    animationType,
    index,
    animationStyle: animStyle,
    showBrandName,
    pageName: pageName || pageNameData,
    isNewInPLP,
    showBrandNames,
    showPrices,
    showProductNames,
  };

  return <CarouselProductItemStructure {...componentProps} />;
};

export default CarouselProductItem;
