'use client'

import React, { FC, ReactElement, useState } from 'react';

import { Margin } from '../../../types/middleware-types';
import TabsStructure from './TabsStructure';

export interface Tab {
  title: string;
  rawHtml: Node | string;
}

export interface TabProps {
  marginMobile: Margin;
  marginDesktop: Margin;
  tabs: Tab[];
}

const Tabs: FC<TabProps> = ({
  marginMobile,
  marginDesktop,
  tabs,
}: TabProps): ReactElement | null => {
  const [activeTab, setActiveTab] = useState(0);

  const changeActiveTab = (tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  const componentProps = {
    tabsData: tabs,
    activeTab,
    changeActiveTab,
    marginMobile,
    marginDesktop,
  };

  return tabs?.length > 0 ? <TabsStructure {...componentProps} /> : null;
};

export default Tabs;
