import styled from 'styled-components';

import { StyledNextImageWrapper } from '../../../basic/NextImage/NextImageStyles';
import { Margin, Maybe, MultipleDefaultContentPositions } from '../../../types/middleware-types';
import { StyledMobileCarousel } from '../../Carousel/MobileCarousel/MobileCarouselStyles';
import {
  StyledModuleItemLinkWrapper,
  StyledModuleItemWrapper,
} from '../MediaModuleItem/ModuleItemStyles';

interface StyledMediaCarouselWrapperProps {
  $marginDesktop: Margin;
  $marginMobile: Margin;
  $backgroundColour: Maybe<string>;
  $contentDefaultPositionDesktop: MultipleDefaultContentPositions;
  $contentDefaultPositionMobile: MultipleDefaultContentPositions;
  $shouldScrollOnMobile: boolean;
}

export const StyledMediaCarouselWrapper = styled.div<StyledMediaCarouselWrapperProps>`
  position: relative;
  display: flex;
  width: auto;
  background-color: ${({ $backgroundColour }) => $backgroundColour};
  flex-direction: ${({ $contentDefaultPositionMobile }) =>
  $contentDefaultPositionMobile === 'ABOVE' ? 'column' : 'column-reverse'};
  padding: ${({
    $marginMobile: { topPixels, rightPixels, bottomPixels, leftPixels },
    $shouldScrollOnMobile,
  }) =>
    $shouldScrollOnMobile
      ? `${topPixels ?? 0}px ${rightPixels ?? 0}px ${bottomPixels ?? 0}px ${leftPixels ?? 0}px`
      : `${topPixels ?? 0}px ${rightPixels ?? 80}px ${bottomPixels ?? 0}px ${leftPixels ?? 80}px`};

  @media ${({ theme }) => theme.devices.medium} {
    flex-direction: ${({ $contentDefaultPositionDesktop }) =>
        $contentDefaultPositionDesktop === 'ABOVE' ? 'column' : 'column-reverse'};
  }

  @media ${({ theme }) => theme.devices.large} {
    padding: ${({ $marginDesktop: { topPixels, rightPixels, bottomPixels, leftPixels } }) =>
      `${topPixels ?? 0}px ${rightPixels ?? 0}px ${bottomPixels ?? 0}px ${leftPixels ?? 0}px`};
  }
`;

export const StyledMediaCarouselCarouselWrapper = styled.div`
  position: relative;
  overflow-x: hidden;

  ${StyledMobileCarousel} {
    ${StyledModuleItemLinkWrapper} {
      flex: 0 0 calc(66.6% - 2rem);
    }
    ${StyledModuleItemWrapper} {
      flex: 0 0 calc(66.6% - 2rem);
    }
  }

  button {
    height: 4rem;
    width: 3.2rem;
    background-color: ${({ theme }) => theme.vars.white};
    z-index: 3;

    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.vars.white};
    }

    svg {
      height: ${({ theme }) => theme.vars.sizeXL};
      width: ${({ theme }) => theme.vars.sizeXL};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  button::before {
    display: none;
  }
`;

export const StyledMediaCarouselItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

interface StyledMediaCarouselOneItemWrapper {
  width: number;
}

export const StyledMediaCarouselOneItemWrapper = styled.div<StyledMediaCarouselOneItemWrapper>`
  box-sizing: border-box;
  padding: 1.6rem 0.6rem;
  width: 50%;

  @media ${({ theme }) => theme.devices.large} {
    width: ${({ width }) => width}%;
    padding: 0 0.6rem;
  }
`;

export const StyledMediaCarouselBackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;

  ${StyledNextImageWrapper} {
    height: 100%;

    > div {
      height: 100%;
    }
  }
`;
