import styled from 'styled-components';

import { GlobalAnimationStyleObject } from '../../../../sites/animations';
import { StyledNextImageWrapper } from '../../../basic/NextImage/NextImageStyles';
import { MarginWrapper } from '../../../styles/contentBlockMedia';
import { MediaFontSize } from '../../../styles/fontSize';
import {
  ColourTheme,
  ContentBlockAnimationMediaFocus,
  ContentCustomPosition,
  FontSize,
  Margin,
  Maybe,
  TextAlignment,
} from '../../../types/middleware-types';

interface StyledMediaFocusWrapperProps {
  $marginDesktop: Margin;
  $marginMobile: Margin;
  $backgroundColour?: Maybe<string>;
  $mediaLeftOnDesktop: boolean;
  $mediaTopOnMobile: boolean;
}

export const StyledMediaFocusWrapper = styled.div<StyledMediaFocusWrapperProps>`
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  align-items: center;
  width: auto;
  flex-direction: ${({ $mediaTopOnMobile }) => ($mediaTopOnMobile ? 'column' : 'column-reverse')};
  ${MarginWrapper};

  @media ${({ theme }) => theme.devices.large} {
    flex-direction: ${({ $mediaLeftOnDesktop }) => ($mediaLeftOnDesktop ? 'row' : 'row-reverse')};
  }
`;

interface StyledLargeImageContainerProps {
  $animate: boolean;
  $animationType?: Maybe<ContentBlockAnimationMediaFocus>;
  $animationStyle: GlobalAnimationStyleObject;
  $moduleIndex: number;
}

export const StyledLargeImageContainer = styled.div<StyledLargeImageContainerProps>`
  width: 100%;
  overflow: hidden;

  @media ${({ theme }) => theme.devices.large} {
    width: 70%;
  }

  a {
    display: block;
    ${({ $animationType, theme }) =>
  $animationType === 'MOVEFADE'
    ? `
      transform: translateY(-100%);
      background: ${theme.vars.white};`
    : ''};
    ${({ $animate, $animationType, $animationStyle }) => {
      if ($animate) {
        if ($animationType === 'MOVEFADE') {
          return $animationStyle.scrollDown;
        }
      }
      return null;
    }}
  }

  video,
  picture,
  ${StyledNextImageWrapper} {
    source,
    img {
      width: 100%;
      display: block;
      ${({ $animationType }) => ($animationType === 'MOVEFADE' ? 'opacity: 0' : '')};
      ${({ $animate, $animationType, $animationStyle, $moduleIndex }) => {
      if ($animate) {
        if (
          ($animationType === 'BACKGROUNDSHRINK' && $moduleIndex === 0) ||
            $animationType === 'BACKGROUNDPARALLAX'
        ) {
          return $animationStyle.zoomIn;
        }
        if ($animationType === 'MOVEFADE') {
          return $animationStyle.fadeIn;
        }
      }
      return null;
    }}
    }
  }
`;

interface StyledSmallImageContentProps {
  $contentPositionDesktop: ContentCustomPosition;
}

export const StyledSmallImageContent = styled.div<StyledSmallImageContentProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  align-self: stretch;
  flex-direction: row;

  @media ${({ theme }) => theme.devices.large} {
    width: fit-content;
    margin-top: ${({ $contentPositionDesktop }) =>
  $contentPositionDesktop.topPercent === null ? '1.5rem' : '0'};
    top: ${({ $contentPositionDesktop }) =>
    $contentPositionDesktop.topPercent === null ? 50 : $contentPositionDesktop.topPercent ?? 0}%;
    left: ${({ $contentPositionDesktop }) => $contentPositionDesktop.leftPercent ?? 0}%;
    flex-direction: column;
    justify-content: center;
    transform: ${({ $contentPositionDesktop }) =>
      `translateY(${$contentPositionDesktop.topPercent === null ? -50 : 0}%)`};
  }
`;

export const StyledSmallImageContainer = styled.div`
  padding: 4rem 0;
  position: relative;
  align-self: stretch;
  width: 100%;

  @media ${({ theme }) => theme.devices.large} {
    width: 30%;
    padding: 0;
  }
`;

export const StyledSmallImageLink = styled.a`
  @media ${({ theme }) => theme.devices.large} {
    margin-top: 0;
    height: 20vw;
    width: 20vw;
  }

  margin: 0;
  height: 40vw;
  width: 40vw;
  max-height: 22rem;
  max-width: 22rem;

  video,
  picture {
    source,
    img {
      height: 100%;
      width: 100%;
    }
  }
`;

interface StyledTextContainerProps {
  $textAlignment: Maybe<TextAlignment>;
  $colourTheme: ColourTheme;
  $fontMediaSize: FontSize;
  $animate: boolean;
  $animationType?: Maybe<ContentBlockAnimationMediaFocus>;
  $animationStyle: GlobalAnimationStyleObject;
  $contentPositionDesktop: ContentCustomPosition;
}

export const StyledTextContainer = styled.div<StyledTextContainerProps>`
  max-width: 40vw;
  display: block;
  text-align: ${({ $textAlignment }) =>
  $textAlignment !== 'CENTRE' ? $textAlignment?.toLowerCase() : 'center'};
  color: ${({ theme, $colourTheme }) => {
    if ($colourTheme === 'LIGHT') {
      return theme.blockTextColors.light;
    }
    if ($colourTheme === 'DARK') {
      return theme.blockTextColors.dark;
    }
    return theme.blockTextColors.default;
  }};

  @media ${({ theme }) => theme.devices.large} {
    max-width: 22rem;
    width: 20vw;
    ${({ $contentPositionDesktop }) =>
    $contentPositionDesktop.topPercent === null &&
      `
        position: absolute;
        bottom: 100%;
        margin-bottom: 1.5rem;
      `}
  }

  .custom-size-title {
    overflow: hidden;
  }

  h2 {
    ${({ $animate, $animationType, $animationStyle }) =>
        $animate && $animationType === 'BACKGROUNDPARALLAX' && $animationStyle.moveUp}
  }

  a {
    ${({ theme }) => theme.types.tertiaryLinkStyles};
    color: inherit;
    font-weight: 400;
    text-transform: uppercase;
    text-align: ${({ $textAlignment }) =>
          $textAlignment !== 'CENTRE' ? $textAlignment?.toLowerCase() : 'center'};
    ${({ $animate, $animationType, $animationStyle }) =>
            $animate && $animationType === 'BACKGROUNDPARALLAX' && $animationStyle.fadeIn}
  }
  ${MediaFontSize};
`;
