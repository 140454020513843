import React, { FC } from 'react';

import { GlobalAnimationsStyle } from '../../../../../sites/animations';
import {
  BlockMediaCarouselItem,
  ColourTheme,
  ContentCustomPosition,
  FontSize,
  HtmlLink,
  Margin,
  Maybe,
  MultipleDefaultContentPositions,
  ProductListItem,
  TextAlignment,
} from '../../../../types/middleware-types';
import Carousel from '../../../Carousel';
import { SliderSettings } from '../../../Slider/Slider.types';
import ModuleContent from '../../MediaModuleContent';
import MediaModuleItem from '../../MediaModuleItem';
import CarouselProductItem from '../CarouselProductItem';
import { StyledCarouselABCDCarouselWrapper } from '../ContentBlockCarouselStyles';
import { StyledCarouselBCWrapper } from './CarouselBCStyles';

interface CarouselBCStructureProps {
  marginDesktop: Margin;
  marginMobile: Margin;
  title?: Maybe<string>;
  subtitle?: Maybe<string>;
  callToAction?: Maybe<HtmlLink>;
  textAlignment?: Maybe<TextAlignment>;
  colourTheme: ColourTheme;
  contentCustomPositionDesktop: ContentCustomPosition;
  contentDefaultPositionDesktop: MultipleDefaultContentPositions;
  contentCustomPositionMobile: ContentCustomPosition;
  contentDefaultPositionMobile: MultipleDefaultContentPositions;
  items: Array<
    (BlockMediaCarouselItem | ProductListItem) & {
      pageName: string;
      fontSizeParent: FontSize;
      componentName: string;
      marginMobile: Margin;
      marginDesktop: Margin;
      textAlignment?: Maybe<TextAlignment>;
      colourTheme: ColourTheme;
      campaignId?: Maybe<string>;
      sizes?: string;
      showBrandNames?: boolean;
      showPrices?: boolean;
      showProductNames?: boolean;
    }
  >;
  settings: SliderSettings;
  shouldScrollOnDesktop?: boolean;
  showArrows: boolean;
  pageName: string;
  forwardedRef: (node: HTMLDivElement) => void;
  animationType?: string;
  animate?: boolean;
  animationStyle: GlobalAnimationsStyle;
  isProductCarousel: boolean;
  fontSize: FontSize;
  backgroundColour: string;
  is404: boolean;
}

const CarouselBCStructure: FC<CarouselBCStructureProps> = ({
  marginDesktop,
  marginMobile,
  title = '',
  subtitle = '',
  callToAction,
  textAlignment,
  colourTheme,
  contentCustomPositionDesktop,
  contentDefaultPositionDesktop,
  contentCustomPositionMobile,
  contentDefaultPositionMobile,
  items,
  settings,
  shouldScrollOnDesktop = false,
  showArrows,
  pageName = '',
  forwardedRef,
  animationType = '',
  animate = false,
  animationStyle,
  isProductCarousel,
  fontSize,
  backgroundColour,
  is404,
}) => {
  return (
    <StyledCarouselBCWrapper
      $marginDesktop={marginDesktop}
      $marginMobile={marginMobile}
      $backgroundColour={backgroundColour}
      $showArrows={showArrows}
      data-hookid={`${pageName}CarouselBC`}
      ref={forwardedRef}
      $contentDefaultPositionDesktop={contentDefaultPositionDesktop}
      $contentDefaultPositionMobile={contentDefaultPositionMobile}
      $is404={is404}
    >
      <StyledCarouselABCDCarouselWrapper>
        <Carousel
          items={items}
          component={isProductCarousel ? CarouselProductItem : MediaModuleItem}
          scrollableOnMobile={true}
          scrollableOnDesktop={shouldScrollOnDesktop}
          showArrows={showArrows}
          settings={settings}
          animationType={animationType}
          animate={animate}
          animationStyle={animationStyle}
        />
      </StyledCarouselABCDCarouselWrapper>
      <ModuleContent
        {...{
          title,
          subtitle,
          callToAction,
          textAlignment,
          colourTheme,
          contentCustomPositionDesktop,
          contentDefaultPositionDesktop,
          contentCustomPositionMobile,
          contentDefaultPositionMobile,
          fontSize,
        }}
      />
    </StyledCarouselBCWrapper>
  );
};

export default CarouselBCStructure;
