import React, { FC } from 'react';

import { GlobalAnimationsStyle } from '../../../../sites/animations';
import {
  ContentBlockAnimationMediaBasic,
  ContentBlockCharityScheme,
  Maybe,
} from '../../../types/middleware-types';
import ModuleContent from '../MediaModuleContent';
import ModuleItem from '../MediaModuleItem';
import {
  StyledModuleItemsWrapper,
  StyledModuleWrapper,
} from './CharitySchemeStyles';

export interface CharitySchemeStructureProps extends ContentBlockCharityScheme {
  pageName: string | undefined;
  forwardedRef: (node: HTMLDivElement) => void;
  animate: boolean;
  animationType?: Maybe<ContentBlockAnimationMediaBasic>;
  animationStyle: GlobalAnimationsStyle;
  moduleIndex: number;
  amountWithSymbol: string
}

const CharitySchemeStructure: FC<CharitySchemeStructureProps> = ({
  id,
  title,
  subtitle,
  callToAction,
  textAlignment,
  colourTheme,
  fontSize,
  contentDefaultPositionDesktop,
  contentCustomPositionDesktop,
  contentDefaultPositionMobile,
  contentCustomPositionMobile,
  marginDesktop,
  marginMobile,
  item,
  forwardedRef,
  animationType,
  animate,
  animationStyle,
  pageName,
  moduleIndex,
  backgroundColour,
  amountWithSymbol
}) => {
  return (
    <StyledModuleWrapper
      ref={forwardedRef}
      $marginMobile={marginMobile}
      $marginDesktop={marginDesktop}
      $backgroundColour={backgroundColour}
      $contentDefaultPositionDesktop={contentDefaultPositionDesktop}
      $contentDefaultPositionMobile={contentDefaultPositionMobile}
      data-hookid={`${pageName}CharityScheme`}
    >
      <StyledModuleItemsWrapper
        $animationType={animationType}
        $animate={animate}
        $animationStyle={animationStyle()}
      >
        <ModuleItem
          id={id}
          {...item}
          animationType={animationType}
          animate={animate}
          animationStyle={animationStyle}
          moduleIndex={moduleIndex}
          fontSizeParent={fontSize}
          numberTitle={amountWithSymbol}
        />
      </StyledModuleItemsWrapper>
      <ModuleContent
        title={title}
        subtitle={subtitle}
        callToAction={callToAction}
        textAlignment={textAlignment}
        colourTheme={colourTheme}
        contentCustomPositionDesktop={contentCustomPositionDesktop}
        contentDefaultPositionDesktop={contentDefaultPositionDesktop}
        fontSize={fontSize}
        contentCustomPositionMobile={contentCustomPositionMobile}
        contentDefaultPositionMobile={contentDefaultPositionMobile}
      />
    </StyledModuleWrapper>
  );
};

export default CharitySchemeStructure;
