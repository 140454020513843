import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectPageName , selectToggles } from '../../../features/static';
import {
  ContentBlockAnimationMediaFocus,
  ContentBlockMediaFocus,
  HtmlLink,
  Maybe,
  TextAlignment,
} from '../../../types/middleware-types';
import useAnimationOnScroll from '../../../utils/customHooks/useAnimationOnScroll';
import parseCMSMediaProp from '../../../utils/formatters/parseCMSMediaProp';
import { MediaFocusStructure } from './MediaFocusStructure';

export interface MediaFocusProps extends ContentBlockMediaFocus {
  index?: number;
  mediaFocusCta: HtmlLink;
  animationMediaFocus?: Maybe<ContentBlockAnimationMediaFocus>;
  mediaFocusTitle: string;
  mediaFocusSubtitle: string;
}

// Module H
const MediaFocus: FC<MediaFocusProps> = ({
  imageLarge,
  imageCropped,
  mediaFocusCta: callToAction,
  colourTheme,
  marginDesktop,
  marginMobile,
  contentCustomPositionDesktop,
  mediaFocusTitle: title,
  mediaFocusSubtitle: subtitle,
  textAlignment = TextAlignment.Centre,
  mediaLeftOnDesktop,
  mediaTopOnMobile,
  animationMediaFocus: animationType,
  index = 0,
  fontSize,
  backgroundColour = 'transparent',
}) => {
  const { enteredView, animationStyle, forwardedRef } = useAnimationOnScroll(animationType);
  const pageName = useSelector(selectPageName);
  const toggles = useSelector(selectToggles);
  const { engs14345ReactRoutingATagsToLinks } = toggles;

  const componentProps = {
    largeMediaObject: parseCMSMediaProp(imageLarge),
    smallMediaObject: parseCMSMediaProp(imageCropped),
    callToAction,
    marginDesktop,
    marginMobile,
    colourTheme,
    contentCustomPositionDesktop,
    title,
    subtitle,
    textAlignment,
    mediaLeftOnDesktop,
    mediaTopOnMobile,
    forwardedRef,
    animationType,
    animate: enteredView,
    animationStyle,
    pageName,
    index,
    fontSize,
    backgroundColour,
    useReactRoutingLinks: engs14345ReactRoutingATagsToLinks,
  };

  return <MediaFocusStructure {...componentProps} />;
};

export default MediaFocus;
