import styled from 'styled-components';

import { MarginWrapper } from '../../../styles/contentBlockMedia';
import { Margin } from '../../../types/middleware-types';

interface StyledTabHolderProps {
  $marginMobile: Margin;
  $marginDesktop: Margin;
}

export const StyledTabHolder = styled.div<StyledTabHolderProps>`
  ${MarginWrapper};
`;

export const StyledTabs = styled.div`
  width: 100%;
  display: flex;
`;

export const StyledTab = styled.button<{ $tabActive: boolean }>`
  width: 50%;
  background: ${({ theme }) => theme.vars.white};
  border: ${({ $tabActive, theme }) => ($tabActive ? `1px solid ${theme.vars.greyDarkest}` : 'none')};
  margin-bottom: ${({ $tabActive }) => ($tabActive ? '-1px' : 0)};
  border-bottom: none;
  padding: ${({ theme }) => theme.vars.sizeL};
  outline: none;
  cursor: pointer;
  font-size: ${({ theme }) => theme.vars.sizeM};
  color: ${({ $tabActive, theme }) => ($tabActive ? theme.vars.black : theme.vars.greyDark)};
`;

export const StyledTabContent = styled.div`
  border: ${({ theme }) => `1px solid ${theme.vars.greyDarkest}`};
  padding: ${({ theme }) => theme.vars.sizeL};
  text-align: center;

  @media ${({ theme }) => theme.devices.smallOnly} {
    border-left: none;
    border-right: none;
  }
`;
