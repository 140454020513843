import React, { FC } from 'react';
import parse from 'html-react-parser';
import Head from 'next/head';

import { Margin } from '../../../types/middleware-types';
import { StyledRawHtmlWrapper } from './RawHtmlStyles';

interface RawHtmlStructureProps {
  rawHtml: string;
  containsScript: boolean;
  id?: string;
  marginMobile?: Margin;
  marginDesktop?: Margin;
  pageName: string | undefined;
}

const RawHtmlStructure: FC<RawHtmlStructureProps> = ({
  rawHtml,
  containsScript,
  id,
  marginMobile,
  marginDesktop,
  pageName,
}) => {
  return (
    <>
      {containsScript ? (
        <Head>{parse(rawHtml)}</Head>
      ) : (
        <StyledRawHtmlWrapper
          $marginDesktop={marginDesktop}
          $marginMobile={marginMobile}
          id={id}
          data-hookid={`${pageName}RawHtml`}
          dangerouslySetInnerHTML={{ __html: rawHtml }} // Not sanitised as we need to inject script tags
        />
      )}
    </>
  );
};

export default RawHtmlStructure;
