import styled from 'styled-components';

import { MarginWrapper } from '../../../../styles/contentBlockMedia';
import { Margin } from '../../../../types/middleware-types';
import { StyledMobileCarousel } from '../../../Carousel/MobileCarousel/MobileCarouselStyles';
import { StyledModuleContentWrapper } from '../../MediaModuleContent/ModuleContentStyles';
import {
  StyledModuleItemLinkWrapper,
  StyledModuleItemWrapper,
} from '../../MediaModuleItem/ModuleItemStyles';

interface StyledCarouselDWrapperProps {
  $marginMobile: Margin;
  $marginDesktop: Margin;
  $backgroundColour: string;
  $top: number;
}

export const StyledCarouselDWrapper = styled.div<StyledCarouselDWrapperProps>`
  display: block;
  position: relative;

  ${MarginWrapper};
  @media ${({ theme }) => theme.devices.large} {
    display: flex;
  }
`;

interface StyledCarouselDMediaWrapperProps {
  $top: number;
}

export const StyledCarouselDMediaWrapper = styled.div<StyledCarouselDMediaWrapperProps>`
  width: 100%;

  @media ${({ theme }) => theme.devices.large} {
    position: sticky;
    margin-right: ${({ theme }) => theme.vars.sizeXS};
    width: 50%;
    top: ${({ $top }) => $top}px;
    align-self: flex-start;
  }

  ${StyledModuleItemWrapper} {
    ${StyledModuleContentWrapper} {
      padding-top: 4.8rem;

      @media ${({ theme }) => theme.devices.large} {
        padding-top: ${({ theme }) => theme.vars.sizeXS};
      }
    }
  }

  ${StyledModuleItemLinkWrapper} {
    ${StyledModuleContentWrapper} {
      padding-top: 4.8rem;

      @media ${({ theme }) => theme.devices.large} {
        padding-top: ${({ theme }) => theme.vars.sizeXS};
      }
    }
  }
`;

export const StyledCarouselDScrollbarWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 4.8rem;
  left: 0;

  @media ${({ theme }) => theme.devices.large} {
    width: 50%;
    height: 100%;
    position: static;
  }

  ${StyledMobileCarousel} {
    padding-bottom: 2.4rem;

    .content {
      padding-bottom: 0.8rem;
    }
  }
`;

export const StyledCarouselDDesktopScrollbar = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

export const StyledCarouselDDesktopScrolbarItem = styled.div`
  width: 50%;
  margin-bottom: 1rem;
`;
