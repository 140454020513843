import React, { FC } from 'react';

import { ContentBlockLoyaltyScheme, LoyaltyTier } from '../../../types/middleware-types';
import LoyaltySchemeStructure from './LoyaltySchemeStructure';

export interface LoyaltySchemeProps extends Omit<ContentBlockLoyaltyScheme, 'title' | 'subtitle'> {
  titleLoyalty: string;
  subtitleLoyalty: string;
}

export interface LoyaltyTierFormated extends LoyaltyTier {
  tierLength?: number;
}

const LoyaltyScheme: FC<LoyaltySchemeProps> = ({
  tiers,
  currentPoints,
  currentTier,
  ...otherProps
}) => {
  /**
   * it computes the length of the bar between the tiers
   * @returns array of tiers extended with tierLength
   */
  const formatTiers = (): Array<LoyaltyTierFormated> => {
    const tierLength = 100 / (tiers.length - 1);
    return tiers.map((tier, index) => {
      if (index === tiers.length - 1) {
        return tier;
      }
      return { ...tier, tierLength };
    });
  };

  /**
   * it computes the current tier position from the right side which is used as a length of gray bar
   * @returns the tier poistion
   */
  const getCurrentPosition = () => {
    const tiersNumber = tiers.length;
    const tiersEnd = tiers[tiersNumber - 1].minPoints;
    const tiersStart = tiers[0].minPoints;
    const numberOfPoints = tiersEnd - tiersStart;
    const tierLength = 100 / (tiersNumber - 1);
    if (numberOfPoints < currentPoints) {
      return 0;
    }
    const activeTier = tiers.find((tier) => tier.id === currentTier);
    if (!activeTier) {
      return 100;
    }
    return (
      100 -
      ((currentTier - 1) * tierLength +
        ((currentPoints - activeTier.minPoints + +(currentPoints <= activeTier.minPoints)) *
          tierLength) /
          (activeTier.maxPoints - activeTier.minPoints))
    );
  };

  /**
   * finds the active tier name
   * @returns acitve tier name
   */
  const getCurrentTierName = () => {
    const userCurrentTier = tiers.find((tier) => tier.id === currentTier);
    return userCurrentTier ? userCurrentTier.name : '';
  };

  const props = {
    ...otherProps,
    tiers,
    currentPoints,
    currentTier,
    tiersFormated: formatTiers(),
    currentPosition: getCurrentPosition(),
    currentTierName: getCurrentTierName(),
  };

  return <LoyaltySchemeStructure {...props} />;
};

export default LoyaltyScheme;
