import React, { FC, MutableRefObject } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleRight, faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';

import formatPromoEvent from '../../../../utils/DataLayer/formatPromoEvent';
import ScreenReaderOnlyText from '../../../ScreenReaderOnlyText';
import { SizesGroupProps } from './SizesGroup.types';
import { StyledSizesGroupLinks, StyledSizesGroupWrapper } from './SizesGroupStyles';

export interface SizesGroupStructureProps extends SizesGroupProps {
  isDesktop: boolean;
  isActive: boolean;
  handlePlusMinusClick: () => void;
  handleRightClick: () => void;
  scrollRef: MutableRefObject<HTMLDivElement | null>;
  useReactRoutingLinks: boolean;
}

const SizesGroupStructure: FC<SizesGroupStructureProps> = ({
  title,
  links,
  isDesktop,
  isActive,
  handlePlusMinusClick,
  handleRightClick,
  scrollRef,
  fontSize,
  shouldCenter,
  sizesTextColourHex,
  sizesLinkColourHex,
  useReactRoutingLinks,
}) => {
  return (
    <StyledSizesGroupWrapper $sizesTextColourHex={sizesTextColourHex}>
      <h3 className="custom-size-description">{title}</h3>
      {!isDesktop && (
        <button
          id={`plus-minus-${title}`}
          data-hookid={`plus-minus-${title}`}
          onClick={handlePlusMinusClick}
          type="button"
        >
          <ScreenReaderOnlyText text="Show sizes toggle" />
          <FontAwesomeIcon icon={isActive ? faMinus as IconProp : faPlus as IconProp} />
        </button>
      )}
      {(isDesktop || isActive) && (
        <StyledSizesGroupLinks
          $fontMediaSize={fontSize}
          $shouldCenter={shouldCenter}
          $sizesLinkColourHex={sizesLinkColourHex}
        >
          <div ref={scrollRef}>
            {useReactRoutingLinks && links.map((link, i) => (
              <Link href={link.url} key={`${link.text}${link.url}`} passHref legacyBehavior>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <a
                  className="custom-size-link"
                  {...(link.newWindow ? { target: '_blank', rel: 'noreferrer' } : {})}
                  onClick={() =>
                    formatPromoEvent(link.text, i + 1, link.url, '', `cta${i + 1}`, 'click')}
                >
                  {link.text}
                </a>
              </Link>
            ))}
            {!useReactRoutingLinks && links.map((link, i) => (
              <a
                className="custom-size-link"
                href={link.url}
                {...(link.newWindow ? { target: '_blank', rel: 'noreferrer' } : {})}
                key={`${link.text}${link.url}`}
                onClick={() =>
                  formatPromoEvent(link.text, i + 1, link.url, '', `cta${i + 1}`, 'click')}
              >
                {link.text}
              </a>
            ))}
          </div>
          {!isDesktop && (
            <button data-hookid={`right-${title}`} id={`right-${title}`} type="button" onClick={handleRightClick}>
              <ScreenReaderOnlyText text="Right arrow" />
              <FontAwesomeIcon icon={faAngleRight as IconProp} />
            </button>
          )}
        </StyledSizesGroupLinks>
      )}
    </StyledSizesGroupWrapper>
  );
};

export default SizesGroupStructure;
