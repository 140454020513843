import styled from 'styled-components';

import { MarginWrapper } from '../../../styles/contentBlockMedia';
import { MediaFontSize } from '../../../styles/fontSize';
import { FontSize, Margin, Maybe } from '../../../types/middleware-types';

interface StyledMediaShopBySizeWrapperProps {
  $marginDesktop: Margin;
  $marginMobile: Margin;
  $shouldCenterContent: boolean;
  $mediaItemOnLeft: boolean;
  $fontMediaSize: FontSize;
}

export const StyledMediaShopBySizeWrapper = styled.div<StyledMediaShopBySizeWrapperProps>`
  display: flex;

  ${MarginWrapper};
  @media ${({ theme }) => theme.devices.large} {
    flex-direction: ${({ $mediaItemOnLeft }) => ($mediaItemOnLeft ? 'row-reverse' : 'row')};
    text-align: ${({ $shouldCenterContent }) => ($shouldCenterContent ? 'center' : 'left')};
  }
  ${MediaFontSize};
`;

interface StyledMediaShopBySizeSizesWrapperProps {
  $shouldTakeFullWidth: boolean;
  $backgroundColor?: Maybe<string>;
  $sizesTextColourHex?: Maybe<string>;
}

export const StyledMediaShopBySizeSizesWrapper = styled.div<StyledMediaShopBySizeSizesWrapperProps>`
  box-sizing: border-box;
  width: 100%;
  padding: ${({ theme }) => theme.vars.sizeM};
  background-color: ${({ $backgroundColor }) => $backgroundColor ?? 'transparent'};
  color: ${({ $sizesTextColourHex, theme }) => $sizesTextColourHex ?? theme.blockTextColors.default};

  @media ${({ theme }) => theme.devices.large} {
    width: ${({ $shouldTakeFullWidth }) => ($shouldTakeFullWidth ? 100 : 50)}%;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const StyledMediaShopBySizeMediaWrapper = styled.div`
  display: none;
  width: 50%;

  @media ${({ theme }) => theme.devices.large} {
    display: block;
    position: relative;
    align-self: center;

    .custom-size-title {
      position: absolute;
      z-index: 5;
      top: ${({ theme }) => theme.vars.sizeM};
      left: ${({ theme }) => theme.vars.sizeM};

      h2 {
        font-weight: normal;
        color: #${({ color }) => color};
      }
    }
  }
`;

export const StyledMediaShopBySizeSplitLine = styled.div`
  display: none;
  height: auto;
  width: 2px;
  background-color: ${({ theme }) => theme.vars.greyDark};
  margin: 0 1.6rem;

  @media ${({ theme }) => theme.devices.large} {
    display: block;
  }
`;
