import { SliderSettings } from '../../Slider/Slider.types';

export const settings: SliderSettings = {
  arrows: false,
  arrowWidth: 25,
  arrowHeight: 25,
  infinite: true,
  speed: 1250,
  slidesToShow: 4,
  slidesToScroll: 1,
  lazyLoad: true,
  dots: false,
};
