import React, { FC } from 'react';

import Accordion from '../../../Accordion';
import { StyledMediaAndText50PercentSplitAccordionWrapper } from './MediaAndText50PercentSplitAccordionStyles';
import { Media50SplitAccordionDataType } from '.';

interface MediaAndText50PercentSplitAccordionStructureProps {
  accordionData: Array<Media50SplitAccordionDataType>;
  defaultOpen: string;
  pageName: string | undefined;
  fontSize?: string;
}

const MediaAndText50PercentSplitAccordionStructure: FC<
  MediaAndText50PercentSplitAccordionStructureProps
> = ({ accordionData, defaultOpen, pageName, fontSize }) => {
  return (
    <StyledMediaAndText50PercentSplitAccordionWrapper $fontMediaSize={fontSize}>
      {accordionData && (
        <Accordion
          openByDefault={defaultOpen}
          allowMultipleOpen={false}
          data={accordionData}
          titleComponent="h4"
          pageName={pageName}
        />
      )}
    </StyledMediaAndText50PercentSplitAccordionWrapper>
  );
};

export default MediaAndText50PercentSplitAccordionStructure;
