import styled from 'styled-components';

import { CustomSizeSubtitle } from '../../../../styles/fontSize';
import {
  StyledAccordion,
  StyledAccordionContent,
  StyledAccordionTrigger,
} from '../../../Accordion/AccordionStyles';
import { StyledTextBlock } from '../../Text/TextBlockStyles';

interface StyledMediaAndText50PercentSplitAccordionWrapperProps {
  $fontMediaSize?: string;
}

export const StyledMediaAndText50PercentSplitAccordionWrapper = styled.div<StyledMediaAndText50PercentSplitAccordionWrapperProps>`
  ${StyledAccordion} {
    > div {
      border-bottom: 1px solid ${({ theme }) => theme.pdpContent.accordionTrigger.borderColor};
      ${StyledAccordionTrigger} {
        padding: 1rem 0;

        h3,
        h4 {
          ${CustomSizeSubtitle}
          text-transform: none;
          margin: 0;
          color: ${({ theme }) => theme.vars.black};
        }

        span {
          color: ${({ theme }) => theme.vars.black};
        }
      }
      ${StyledAccordionContent} {
        margin: 0.2rem 0 1.2rem;
      }
      ${StyledTextBlock} {
        p {
          ${CustomSizeSubtitle}
        }
      }
    }
  }
`;
