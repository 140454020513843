'use client'

import React, { ComponentType, FC } from 'react';
import { useSelector } from 'react-redux';

import { selectPageName } from '../../../features/static';
import {
  ContentBlockAnimationMediaCarouselSimple,
  ContentBlockAnimationMediaCarouselWithMediaItem,
  ContentBlockAnimationProductCarousel,
  ContentBlockAnimationProductCarouselWithMediaItem,
} from '../../../types/middleware-types';
import useAnimationOnScroll from '../../../utils/customHooks/useAnimationOnScroll';
import CarouselA, { CarouselAProps } from './CarouselA';
import CarouselBC, { CarouselBCProps } from './CarouselBC';
import CarouselD, { CarouselDProps } from './CarouselD';
import CarouselProductItem, { CarouselProductItemProps } from './CarouselProductItem';

const LAYOUTS: {
  [key: string]:
  | ComponentType<CarouselAProps>
  | ComponentType<CarouselBCProps>
  | ComponentType<CarouselDProps>
  | ComponentType<CarouselProductItemProps>;
} = {
  CarouselA,
  CarouselBC,
  CarouselD,
  CarouselProductItem,
};

export interface ContentBlockCarouselProps {
  __typename?: string;
  pageName?: string | null,
  mediaItemStyle?: string;
  animationProductCarousel?: ContentBlockAnimationProductCarousel;
  animationProductCarouselWithMediaItem?: ContentBlockAnimationProductCarouselWithMediaItem;
  animationMediaCarouselWithMediaItem?: ContentBlockAnimationMediaCarouselWithMediaItem;
  animationMediaCarouselSimple?: ContentBlockAnimationMediaCarouselSimple;
}

const ContentBlockCarousel: FC<ContentBlockCarouselProps> = ({
  __typename,
  mediaItemStyle,
  animationProductCarousel,
  animationProductCarouselWithMediaItem,
  animationMediaCarouselWithMediaItem,
  animationMediaCarouselSimple,
  ...otherProps
}) => {
  let layout = '';

  const pageName = useSelector(selectPageName);

  if (
    __typename === 'ContentBlockProductCarouselWithMediaItem' ||
    __typename === 'ContentBlockMediaCarouselWithMediaItem'
  ) {
    if (mediaItemStyle === 'SEPARATE') {
      layout = 'CarouselA';
    }

    if (mediaItemStyle === 'STICKY') {
      layout = 'CarouselD';
    }
  }

  if (
    __typename === 'ContentBlockProductCarousel' ||
    __typename === 'ContentBlockMediaCarouselSimple'
  ) {
    layout = 'CarouselBC';
  }

  const animationType =
    animationProductCarousel ||
    animationProductCarouselWithMediaItem ||
    animationMediaCarouselWithMediaItem ||
    animationMediaCarouselSimple;

  const { enteredView, animationStyle, forwardedRef } = useAnimationOnScroll(animationType);

  const componentProps = {
    // Ignore added as any data type can be passed
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(otherProps as any),
    mediaItemStyle,
    pageName,
    forwardedRef,
    animationType,
    animate: enteredView,
    animationStyle,
  };

  const Component = LAYOUTS[layout];

  return Component ? <Component {...componentProps} /> : null;
};

export default ContentBlockCarousel;
