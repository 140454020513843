/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { GlobalAnimationsStyle } from '../../../../sites/animations';
import MediaElement from '../../../basic/MediaElement';
import { CustomMedia } from '../../../types/custom-types';
import {
  ContentBlockAnimationCelebrityStyleCarousel,
  ContentBlockCelebrityStyleCarousel,
} from '../../../types/middleware-types';
import { DEFAULT_ON_SCROLL_STYLE } from '../../../utils/customHooks/useAnimationOnScroll';
import cleanHtml from '../../../utils/formatters/cleanHtml';
import Carousel from '../../Carousel';
import CelebrityCard from '../../CelebrityCard';
import { StyledCelebrityCardButton } from '../../CelebrityCard/CelebrityCardStyles';
import { SliderSettings } from '../../Slider/Slider.types';
import { CelebrityCard as ICelebrityCard, CurrentPanel } from './CelebrityCarousel.types';
import {
  StyledCelebrityCarouselContent,
  StyledCelebrityCarouselDangerousContent,
  StyledCelebrityCarouselPanel,
  StyledCelebrityCarouselPanelBrand,
  StyledCelebrityCarouselPanelContentWrapper,
  StyledCelebrityCarouselPanelImageWrapper,
  StyledCelebrityCarouselPanelTitle,
} from './CelebrityCarouselStyles';

interface CelebrityCarouselStructureProps
  extends Omit<ContentBlockCelebrityStyleCarousel, 'id' | 'itemCallToActionText' | 'items'> {
  sliderSettings: SliderSettings;
  celebrityCards: ICelebrityCard[];
  currentPanel: CurrentPanel;
  shownPanel: boolean;
  showPanel: (shown: boolean) => void;
  panelImage: CustomMedia;
  pageName: string | undefined;
  animationType?: ContentBlockAnimationCelebrityStyleCarousel;
  animate: boolean;
  animationStyle?: GlobalAnimationsStyle;
  showBrandName: boolean;
}

const CelebrityCarouselStructure: FC<CelebrityCarouselStructureProps> = ({
  marginMobile,
  marginDesktop,
  sliderSettings,
  celebrityCards,
  title,
  seeMoreCallToAction,
  currentPanel,
  shownPanel,
  showPanel,
  panelImage,
  pageName,
  animationType,
  animate,
  animationStyle = DEFAULT_ON_SCROLL_STYLE,
  showBrandName,
  backgroundColour,
}) => (
  <StyledCelebrityCarouselContent
    $marginMobile={marginMobile}
    $marginDesktop={marginDesktop}
    $backgroundColour={backgroundColour}
    data-hookid={`${pageName}CelebrityCarousel`}
  >
    <h2>
      <span>{title}</span>
    </h2>
    <Carousel
      showArrows={false}
      items={celebrityCards}
      component={CelebrityCard}
      settings={sliderSettings}
      animationType={animationType}
      animate={animate}
      animationStyle={animationStyle}
    />
    <StyledCelebrityCarouselPanel $active={shownPanel}>
      <StyledCelebrityCardButton
        data-hookid={`${pageName}CelebrityCarouselButton`}
        type="button"
        onClick={() => showPanel(false)}
      >
        <FontAwesomeIcon icon={faTimes as IconProp} />
      </StyledCelebrityCardButton>
      <StyledCelebrityCarouselPanelContentWrapper>
        {currentPanel?.data?.product.name && (
          <StyledCelebrityCarouselPanelTitle>
            {currentPanel.data.product.name}
          </StyledCelebrityCarouselPanelTitle>
        )}
        {currentPanel?.data?.product.brand && showBrandName && (
          <StyledCelebrityCarouselPanelBrand>
            {currentPanel.data.product.brand}
          </StyledCelebrityCarouselPanelBrand>
        )}
        <StyledCelebrityCarouselDangerousContent>
          <div
            dangerouslySetInnerHTML={{
              __html: cleanHtml(currentPanel?.data?.contentHtml),
            }}
          />
        </StyledCelebrityCarouselDangerousContent>
      </StyledCelebrityCarouselPanelContentWrapper>
      <StyledCelebrityCarouselPanelImageWrapper href="">
        <MediaElement {...panelImage} sizes="70vw" />
        <span>Shop Now</span>
      </StyledCelebrityCarouselPanelImageWrapper>
    </StyledCelebrityCarouselPanel>
    <a
      href={seeMoreCallToAction.url}
      {...(seeMoreCallToAction.newWindow ? { target: '_blank', rel: 'noreferrer' } : {})}
    >
      {seeMoreCallToAction.text}
    </a>
  </StyledCelebrityCarouselContent>
);

export default CelebrityCarouselStructure;
