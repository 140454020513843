import styled from 'styled-components';

export const StyledCategoryTitle = styled.h2`
  line-height: 2rem;
`

export const StyledCarouselItemCard = styled.a`
  flex: 1 0 32%;
  ${StyledCategoryTitle} {
    ${({ theme }) => theme.types.labelStyle};
  }
`

export const StyledCarouselItemCardImageWrapper = styled.div`
  justify-content: center;
  margin: 0.2rem;

  img {
    width: 100%;
  }
`