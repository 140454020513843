import styled from 'styled-components';

import { GlobalAnimationStyleObject } from '../../../../sites/animations';
import { StyledButton } from '../../../basic/Buttons/ButtonStyles';
import { FullMediaWrapper } from '../../../styles/contentBlockMedia';
import { MediaFontSize } from '../../../styles/fontSize';
import {
  CallToActionArrangement,
  CallToActionStyle,
  ColourTheme,
  ContentBlockAnimationMediaCtAs,
  FontSize,
  Margin,
  Maybe,
  MultipleDefaultContentPositions,
} from '../../../types/middleware-types';
import { StyledModuleContentWrapper } from '../MediaModuleContent/ModuleContentStyles';

interface StyledMediaCTAsProps {
  $marginDesktop: Margin;
  $marginMobile: Margin;
  $backgroundColour?: Maybe<string>;
  $absoluteDesktop: boolean;
  $absoluteMobile: boolean;
  $contentDefaultPositionDesktop: MultipleDefaultContentPositions;
  $contentDefaultPositionMobile: MultipleDefaultContentPositions;
}

export const StyledMediaCTAs = styled.div<StyledMediaCTAsProps>`
  overflow: hidden;
  position: relative;
  display: flex;
  ${FullMediaWrapper};
`;

export const StyledMediaContainer = styled.div`
  width: 100%;
  position: relative;

  video,
  picture {
    source,
    img {
      width: 100%;
    }
  }

  ${StyledModuleContentWrapper} {
    padding: 0;
  }
`;

interface StyledContentContainerProps {
  $animate: boolean;
  $animationType?: Maybe<ContentBlockAnimationMediaCtAs>;
  $animationStyle: GlobalAnimationStyleObject;
  $absoluteMobile: boolean;
  $topMobile?: Maybe<number>;
  $leftMobile?: Maybe<number>;
  $absoluteDesktop: boolean;
  $topDesktop?: Maybe<number>;
  $leftDesktop?: Maybe<number>;
  $contentDefaultPositionDesktop: MultipleDefaultContentPositions;
  $contentDefaultPositionMobile: MultipleDefaultContentPositions;
  $textAlignment?: string;
  $colourTheme: ColourTheme;
  $fontMediaSize: FontSize;
  $callToActionArrangement: CallToActionArrangement;
  $callToActionStyle: CallToActionStyle;
}

export const StyledContentContainer = styled.div<StyledContentContainerProps>`
  margin: 0;
  display: inline-block;
  box-sizing: border-box;
  text-align: ${({ $textAlignment }) => $textAlignment ?? 'center'};
  ${({ $animate, $animationType, $animationStyle }) =>
  $animate && $animationType === 'BACKGROUNDPARALLAX' && $animationStyle.fadeIn};
  position: ${({ $absoluteMobile }) => ($absoluteMobile ? 'absolute' : 'relative')};
  top: ${({ $topMobile, $contentDefaultPositionMobile }) => {
    if ($contentDefaultPositionMobile !== 'INSIDE') {
      return 0;
    }
    return $topMobile === null ? 50 : $topMobile;
  }}%;
  left: ${({ $leftMobile }) => ($leftMobile === null ? 50 : $leftMobile)}%;
  transform: ${({ $topMobile, $leftMobile, $contentDefaultPositionMobile }) => {
    if ($contentDefaultPositionMobile !== 'INSIDE') {
      if ($leftMobile === null) {
        return 'translate(-50%, 0)';
      }
      return 'translate(0, 0)';
    }
    if ($topMobile !== null && $leftMobile !== null) {
      return 'translate(0, 0)';
    }
    if ($topMobile === null && $leftMobile !== null) {
      return 'translate(0, -50%)';
    }
    if ($topMobile !== null && $leftMobile === null) {
      return 'translate(-50%, 0)';
    }
    return 'translate(-50%, -50%)';
  }};
  color: ${({ theme, $colourTheme }) => {
    if ($colourTheme === 'LIGHT') {
      return theme.blockTextColors.light;
    }
    if ($colourTheme === 'DARK') {
      return theme.blockTextColors.dark;
    }
    return theme.blockTextColors.default;
  }};

  @media ${({ theme }) => theme.devices.smallOnly} {
    width: ${({ $leftMobile, $callToActionArrangement, $callToActionStyle }) =>
    $callToActionArrangement === 'HORIZONTALROW' &&
      $callToActionStyle === 'TEXTLINK' &&
      $leftMobile === null &&
      '90%'};
  }

  @media ${({ theme }) => theme.devices.medium} {
    position: ${({ $absoluteDesktop }) => ($absoluteDesktop ? 'absolute' : 'relative')};
    top: ${({ $topDesktop, $contentDefaultPositionDesktop }) => {
        if ($contentDefaultPositionDesktop !== 'INSIDE') {
          return 0;
        }
        return $topDesktop === null ? 50 : $topDesktop;
      }}%;
    left: ${({ $leftDesktop }) => ($leftDesktop === null ? 50 : $leftDesktop)}%;
    transform: ${({ $topDesktop, $leftDesktop, $contentDefaultPositionDesktop }) => {
        if ($contentDefaultPositionDesktop !== 'INSIDE') {
          if ($leftDesktop === null) {
            return 'translate(-50%, 0)';
          }
          return 'translate(0, 0)';
        }
        if ($topDesktop !== null && $leftDesktop !== null) {
          return 'translate(0, 0)';
        }
        if ($topDesktop === null && $leftDesktop !== null) {
          return 'translate(0, -50%)';
        }
        if ($topDesktop !== null && $leftDesktop === null) {
          return 'translate(-50%, 0)';
        }
        return 'translate(-50%, -50%)';
      }};
  }

  h4 {
    margin-bottom: ${({ $callToActionStyle }) => ($callToActionStyle === 'BUTTON' ? '1.6rem' : 0)};
  }
  ${MediaFontSize};
`;

interface StyledCTAContainerProps {
  $ctaBackground: Maybe<string>;
  $callToActionArrangement: CallToActionArrangement;
  $callToActionStyle: CallToActionStyle;
  $contentDefaultPositionDesktop: MultipleDefaultContentPositions;
  $contentDefaultPositionMobile: MultipleDefaultContentPositions;
  $leftMobile?: Maybe<number>;
  $textAlignment?: string;
}

export const StyledCTAContainer = styled.div<StyledCTAContainerProps>`
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: ${({ $textAlignment }) =>
  ($textAlignment === 'left' && 'flex-start') ||
    ($textAlignment === 'right' && 'flex-end') ||
    'center'};
  ${({ $callToActionArrangement }) =>
      $callToActionArrangement === 'COLUMNS' &&
    `
      flex-wrap: wrap;
      width: 34.8rem;
      justify-content: space-between;
  `}
  margin-top: ${({ $callToActionStyle, $contentDefaultPositionMobile }) =>
      $callToActionStyle === 'BUTTON' && $contentDefaultPositionMobile === 'BELOW' ? '2rem' : 0};

  @media ${({ theme }) => theme.devices.smallOnly} {
    justify-content: ${({ $leftMobile, $callToActionArrangement, $callToActionStyle }) =>
        $callToActionArrangement === 'HORIZONTALROW' &&
      $callToActionStyle === 'TEXTLINK' &&
      $leftMobile === null &&
      'center'};
    ${({ $callToActionArrangement }) =>
        $callToActionArrangement === 'COLUMNS' &&
      `
        margin: 0 2rem;
        width: auto;
      `
    }
  }

  @media ${({ theme }) => theme.devices.large} {
    margin-top: ${({ $callToActionStyle, $contentDefaultPositionDesktop }) =>
      $callToActionStyle === 'BUTTON' && $contentDefaultPositionDesktop === 'BELOW' ? '2rem' : 0};
  }

  ${StyledButton} {
    ${({ theme, $callToActionStyle }) =>
        $callToActionStyle === 'TEXTLINK' && theme.types.tertiaryLinkStyles};
    height: unset;
    min-height: 3.8rem;
    text-transform: unset;
    font-size: inherit;
    line-height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ $callToActionStyle }) => $callToActionStyle === 'TEXTLINK' && 'color: inherit'};
  }

  a {
    display: inline-block;
    width: 16rem;
    color: inherit;
    ${({ $callToActionStyle }) =>
          $callToActionStyle === 'BUTTON' &&
      `
        margin: 0 1.6rem 2.8rem;
    `}
    ${({ $callToActionArrangement, $callToActionStyle }) =>
        ($callToActionArrangement === 'COLUMNS' &&
        $callToActionStyle === 'BUTTON' &&
        `
        margin: 0;
        margin-bottom: 2.8rem;
        &:nth-last-child(-n+2) {
          margin-bottom: 0;
        }
    `) ||
      ($callToActionArrangement === 'HORIZONTALROW' &&
        $callToActionStyle === 'TEXTLINK' &&
        `
        width: unset;
        margin: 0 2rem;
        &:first-child {
          margin-left: 0;
          button {
            padding-left: 0;
          }
        }
        &:last-child {
          margin-right: 0;
          button {
            padding-right: 0;
          }
        }
    `)}
  }

  button {
    line-height: inherit;
    font-size: inherit;
    color: inherit;
    background: ${({ $callToActionStyle, $ctaBackground }) =>
          $callToActionStyle === 'BUTTON' && $ctaBackground ? $ctaBackground : 'transparent'};

    &:hover {
      color: inherit;
    }
  }
`;
