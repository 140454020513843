import styled from 'styled-components';

import { StyledCarouselProductItemWrapper } from './CarouselProductItem/CarouselProductItemStyles';
import { StyledMobileCarousel } from '../../Carousel/MobileCarousel/MobileCarouselStyles';
import { StyledSlider } from '../../Slider/SliderStyles';
import {
  StyledModuleItemLinkWrapper,
  StyledModuleItemWrapper,
} from '../MediaModuleItem/ModuleItemStyles';

export const StyledCarouselABCDCarouselWrapper = styled.div`
  width: 100%;
  ${StyledSlider} {
    display: none;
  }
  ${StyledMobileCarousel} {
    white-space: initial;
    display: flex;
    padding: 0 0 2rem;
  }

  ${StyledModuleItemWrapper} {
    width: 66.66%;
    flex: 0 0 66.66%;
  }

  ${StyledModuleItemLinkWrapper} {
    width: 66.66%;
    flex: 0 0 66.66%;
  }

  ${StyledCarouselProductItemWrapper} {
    width: calc(66.66% - 24px);
    flex: 0 0 calc(66.66% - 24px);
  }

  @media ${({ theme }) => theme.devices.large} {
    width: 75%;

    ${StyledSlider} {
      display: block;
    }
    ${StyledMobileCarousel} {
      display: none;
    }

    ${StyledModuleItemWrapper} {
      width: auto;
      flex: 0 0 100%;
    }

    ${StyledModuleItemLinkWrapper} {
      width: auto;
      flex: 0 0 100%;
    }

    ${StyledCarouselProductItemWrapper} {
      width: auto;
      flex: 0 0 100%;
    }
  }
`;
