'use client'

import React, { FC } from 'react';
import { usePathname } from 'next/navigation';
import { useTheme } from 'styled-components';

import { GlobalAnimationsStyle } from '../../../../../sites/animations';
import {
  BlockMediaCarouselItem,
  ContentBlockMediaCarouselSimple,
  ContentBlockProductCarousel,
  ProductListItem,
  ProductRecommendations,
} from '../../../../types/middleware-types';
import useMobileWatcher from '../../../../utils/customHooks/useMobileWatcher';
import { settings } from '../settings';
import CarouselBCStructure from './CarouselBCStructure';

interface ProductCarouselBCProps extends ContentBlockProductCarousel {
  pageName: string;
  forwardedRef: (node: HTMLDivElement) => void;
  animationType?: string;
  animate?: boolean;
  animationStyle: GlobalAnimationsStyle;
  mediaCarouselSimpleItems?: Array<BlockMediaCarouselItem>;
}

interface MediaCarouselBCProps extends ContentBlockMediaCarouselSimple {
  pageName: string;
  forwardedRef: (node: HTMLDivElement) => void;
  animationType?: string;
  animate?: boolean;
  animationStyle: GlobalAnimationsStyle;
  mediaCarouselSimpleItems?: Array<BlockMediaCarouselItem>;
  campaign?: ProductRecommendations;
  showBrandNames?: boolean;
  showPrices?: boolean;
  showProductNames?: boolean;
}

export type CarouselBCProps = ProductCarouselBCProps | MediaCarouselBCProps;

const CarouselBC: FC<CarouselBCProps> = ({
  id,
  marginDesktop,
  marginMobile,
  title,
  subtitle,
  callToAction,
  textAlignment,
  colourTheme,
  contentCustomPositionDesktop,
  contentDefaultPositionDesktop,
  contentCustomPositionMobile,
  contentDefaultPositionMobile,
  campaign,
  mediaCarouselSimpleItems: items = [],
  arrowPositionDesktop,
  scrollStyleOnDesktop,
  pageName,
  forwardedRef,
  animationType,
  animate,
  animationStyle,
  fontSize,
  backgroundColour,
  showBrandNames,
  showPrices,
  showProductNames,
}) => {
  const theme = useTheme();
  const { isDesktop } = useMobileWatcher(['desktop'], theme.vars);

  const products: ProductListItem[] = campaign?.products ?? [];

  const path = usePathname();
  const is404 = path?.includes('404') || false;

  const hasNoItems =
    (products.length === 0 && items.length === 0) ||
    (products.length > 0 && Object.values(products).every((x) => !x.lineNumber)) ||
    (items.length > 0 && Object.values(items).every((x) => !x.colourTheme));

  const componentProps = {
    id,
    marginDesktop,
    marginMobile,
    title,
    subtitle,
    callToAction,
    textAlignment,
    colourTheme,
    contentCustomPositionDesktop,
    contentDefaultPositionDesktop,
    contentCustomPositionMobile,
    contentDefaultPositionMobile,
    items: (items.length ? items : products).map(
      (productOrItem: ProductListItem | BlockMediaCarouselItem) => ({
        ...productOrItem,
        fontSizeParent: fontSize,
        pageName,
        componentName: 'CarouselBC',
        marginMobile: { rightPixels: 24 },
        marginDesktop: { leftPixels: 8, rightPixels: 8 },
        textAlignment,
        colourTheme,
        campaignId: campaign?.id,
        sizes: '(max-width: 1024px) 70vw, 25vw',
        showBrandNames,
        showPrices,
        showProductNames,
      }),
    ),
    settings: {
      ...settings,
      arrows: arrowPositionDesktop === 'SIDE',
      infinite: (items || products).length > (settings.slidesToShow as number),
    },
    showArrows:
      !!isDesktop && arrowPositionDesktop === 'BOTTOM' && scrollStyleOnDesktop !== 'SCROLLBAR',
    shouldScrollOnDesktop: scrollStyleOnDesktop === 'SCROLLBAR',
    pageName,
    forwardedRef,
    animationType,
    animate,
    animationStyle,
    fontSize,
    isProductCarousel: products.length > 0,
    backgroundColour: backgroundColour ?? 'transparent',
    is404,
  };
  return (
    <>
      {componentProps.items.length > 0 && !hasNoItems && (
        <CarouselBCStructure {...componentProps} />
      )}
    </>
  );
};

export default CarouselBC;
