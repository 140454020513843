import styled, { createGlobalStyle } from 'styled-components';

import { Maybe } from '../../types/middleware-types';

interface Props {
  $mobileBackground?: Maybe<string>;
  $desktopBackground?: Maybe<string>;
  $top: number;
}

export const GlobalBackgroundStyle = createGlobalStyle<Props>`
  main {
    display: inline-block;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    background-image: ${({ $mobileBackground }) =>
  $mobileBackground ? `url(${$mobileBackground})` : ''};
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position-y: ${({ $top }) => `${$top ?? 0}px`};
    z-index: 1;

    @media ${({ theme }) => theme.devices.large} {
      background-image: ${({ $desktopBackground }) =>
    $desktopBackground ? `url(${$desktopBackground})` : ''};
    }
  }
`;

export const StyledBackground = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -999;
`;

export const StyledBackgroundImageContainer = styled.div<{ $top: number }>`
  width: 100%;
  padding-top: ${({ $top }) => `${$top}px`};
  overflow: hidden;

  img {
    width: 100%;
  }
`;

export const StyledLogoContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: -999;
`;

export const StyledLogoImageContainer = styled.div`
  width: 100%;
  position: sticky;
  bottom: 0;

  img {
    width: 100%;
  }
`;
