
import styled from 'styled-components';

import { StyledCategoryTitle } from './CarouselItemCard/CarouselItemCardStyles';
import { ColourTheme, Margin, Maybe, TextAlignment } from "../../../types/middleware-types";
import { StyledMobileCarousel } from '../../Carousel/MobileCarousel/MobileCarouselStyles';

interface ContainerProps {
  $backgroundColour?: string;
  $marginMobile?: Margin
  $textAlignment?: Maybe<TextAlignment>;
  $colourTheme?: ColourTheme;
}

export const StyledCarouselSliderWrapper = styled.div<ContainerProps>`
  margin: ${({ $marginMobile }) => `${$marginMobile?.topPixels}px ${$marginMobile?.rightPixels}px ${$marginMobile?.bottomPixels}px ${$marginMobile?.leftPixels}px`};
  background-color: ${({ $backgroundColour }) => $backgroundColour || 'transparent'};
  width: 100%;

  @media ${({ theme }) => theme.devices.large} {
    display: none;
  }

  ${StyledCategoryTitle} {
    text-align: ${({ $textAlignment }) =>
  $textAlignment !== 'CENTRE' ? $textAlignment?.toLowerCase() : 'center'};
    color: ${({ theme, $colourTheme }) => {
    if ($colourTheme === 'LIGHT') {
      return theme.blockTextColors.light;
    }
    if ($colourTheme === 'DARK') {
      return theme.blockTextColors.dark;
    }
    return theme.blockTextColors.default;
  }};
  }
  
  ${StyledMobileCarousel} {
    display: flex;
    padding: 0;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`



