import React, { FC } from 'react';

import { FontSize, Margin } from '../../../types/middleware-types';
import Accordion from '../../Accordion';
import { StyledTextBlock, StyledTextBlockWrapper } from './TextBlockStyles';

interface Props {
  animation?: string | null;
  marginDesktop: Margin;
  marginMobile: Margin;
  contentHtmlRequired: string;
  textBlockAlignment: string;
  accordionTitle?: string;
  isAccordion?: boolean;
  accordionOpenByDefault: boolean;
  fontSize: FontSize;
}

const TextBlockStructure: FC<Props> = ({
  animation,
  marginDesktop,
  marginMobile,
  contentHtmlRequired,
  textBlockAlignment,
  accordionTitle,
  isAccordion,
  accordionOpenByDefault,
  fontSize,
}) => {
  const openByDefault = accordionOpenByDefault && accordionTitle;
  if (isAccordion) {
    return (
      <StyledTextBlockWrapper $marginDesktop={marginDesktop} $marginMobile={marginMobile}>
        <Accordion
          title={accordionTitle}
          openByDefault={`${openByDefault}`}
          openAllOnClick={false}
          allowMultipleOpen={false}
          toggleOnHover={false}
          openByDefaultOnMobile={false}
          pageName={undefined}
          component={null}
          titleComponent={null}
        >
          <StyledTextBlock
            $fontMediaSize={fontSize}
            $animationType={animation}
            $textAlignment={textBlockAlignment || 'CENTRE'}
            dangerouslySetInnerHTML={{ __html: contentHtmlRequired }}
          />
        </Accordion>
      </StyledTextBlockWrapper>
    )
  }
  return (
    <StyledTextBlockWrapper $marginDesktop={marginDesktop} $marginMobile={marginMobile}>
      <div>
        <StyledTextBlock
          $fontMediaSize={fontSize}
          $animationType={animation}
          $textAlignment={textBlockAlignment || 'CENTRE'}
          dangerouslySetInnerHTML={{ __html: contentHtmlRequired }}
        />
      </div>
    </StyledTextBlockWrapper>
  );
};

export default TextBlockStructure;
