import React, { FC } from 'react';

import {
  ContentBlockMediaCarouselSimpleMobileOnly,
  Maybe,
  TextAlignment,
} from '../../../types/middleware-types';
import { BlockMediaCarouselSimpleMobileOnlyItemVariation } from './CarouselItemCard';
import MediaCarouselSimpleMobileOnlyStructure from './MediaCarouselSimpleMobileOnlyStructure';

export interface MediaCarouselSimpleMobileOnlyProps
  extends Omit<ContentBlockMediaCarouselSimpleMobileOnly, 'items' | 'textAlignment'> {
  backgroundColour?: string;
  categoryTextAlignment?: Maybe<TextAlignment>;
  items: Array<BlockMediaCarouselSimpleMobileOnlyItemVariation>;
}

const MediaCarouselSimpleMobileOnly: FC<MediaCarouselSimpleMobileOnlyProps> = ({
  id,
  backgroundColour,
  marginMobile,
  items,
  categoryTextAlignment: textAlignment,
  colourTheme,
}) => {

  const componentProps = {
    id,
    backgroundColour,
    marginMobile,
    items,
    textAlignment,
    colourTheme,
  };

  return <MediaCarouselSimpleMobileOnlyStructure {...componentProps} />;
};

export default MediaCarouselSimpleMobileOnly;
