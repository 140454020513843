import styled from 'styled-components';

import { GlobalAnimationStyleObject } from '../../../../sites/animations';
import { StyledNextImageWrapper } from '../../../basic/NextImage/NextImageStyles';
import { FullMediaWrapper } from '../../../styles/contentBlockMedia';
import { Margin, Maybe, MultipleDefaultContentPositions } from '../../../types/middleware-types';
import { StyledModuleContentWrapper } from '../MediaModuleContent/ModuleContentStyles';
import {
  StyledModuleItemLinkWrapper,
  StyledModuleItemMediaWrapper,
  StyledModuleItemWrapper,
} from '../MediaModuleItem/ModuleItemStyles';

interface StyledModuleWrapperProps {
  $marginMobile: Margin;
  $marginDesktop: Margin;
  $contentDefaultPositionDesktop: MultipleDefaultContentPositions;
  $contentDefaultPositionMobile: MultipleDefaultContentPositions;
  $backgroundColour?: Maybe<string>;
}

export const StyledModuleWrapper = styled.div<StyledModuleWrapperProps>`
  display: flex;
  overflow: hidden;
  width: auto;
  justify-content: flex-start;
  position: relative;
  padding: 1em 0;
  ${FullMediaWrapper};

  ${StyledModuleContentWrapper} {
    z-index: 5;
  }
`;

interface StyledModuleItemsWrapperProps {
  $animate?: boolean;
  $animationType?: Maybe<string>;
  $animationStyle: GlobalAnimationStyleObject;
  $itemTop: number;
}

export const StyledModuleItemsWrapper = styled.div<StyledModuleItemsWrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media ${({ theme }) => theme.devices.large} {
    flex-direction: row;
    width: auto;
    ${StyledModuleItemLinkWrapper}
    ,
    ${StyledModuleItemWrapper} {
      overflow: unset;
      z-index: 1;
      transition: transform 0.5s;

      ${StyledModuleItemMediaWrapper} {
        width: 120%;
      }

      ${StyledModuleContentWrapper} {
        z-index: 3;
      }

      &:nth-child(2),
      &:nth-child(4) {
        z-index: 2;
        ${StyledModuleItemMediaWrapper} {
          width: 120%;
          margin-top: ${({ $itemTop }) => $itemTop}px;
          margin-left: -10%;
        }
      }

      &:nth-child(3) {
        ${StyledModuleItemMediaWrapper} {
          margin-left: -10%;
        }
      }

      &:nth-child(5) {
        ${StyledModuleItemMediaWrapper} {
          margin-left: -20%;
        }
      }

      &:hover {
        ${({ $animationType }) =>
  $animationType === 'BLOCKOVERLAP' && 'z-index: 3; transform: translateY(-1rem);'}
      }
    }
  }

  video,
  picture,
  ${StyledNextImageWrapper} {
    source,
    img {
      display: block;
      ${({ $animate, $animationType, $animationStyle }) =>
    $animate && $animationType === 'BACKGROUNDPARALLAX' && $animationStyle.zoomIn};
    }
  }
`;
