import styled from 'styled-components';

import { GlobalAnimationStyleObject } from '../../../../sites/animations';
import { StyledNextImageWrapper } from '../../../basic/NextImage/NextImageStyles';
import { FullMediaWrapper } from '../../../styles/contentBlockMedia';
import {
  ContentBlockAnimationMediaBasic,
  Margin,
  Maybe,
  MultipleDefaultContentPositions,
} from '../../../types/middleware-types';

interface StyledModuleWrapperProps {
  $marginMobile: Margin;
  $marginDesktop: Margin;
  $backgroundColour?: Maybe<string>;
  $contentDefaultPositionDesktop: MultipleDefaultContentPositions;
  $contentDefaultPositionMobile: MultipleDefaultContentPositions;
}

export const StyledModuleWrapper = styled.div<StyledModuleWrapperProps>`
  display: flex;
  width: auto;
  justify-content: flex-start;
  position: relative;
  ${FullMediaWrapper};
`;

interface StyledModuleItemsWrapperProps {
  $animate: boolean;
  $animationType?: Maybe<ContentBlockAnimationMediaBasic>;
  $animationStyle: GlobalAnimationStyleObject;
}

export const StyledModuleItemsWrapper = styled.div<StyledModuleItemsWrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media ${({ theme }) => theme.devices.large} {
    flex-direction: row;
    width: auto;
  }

  video,
  picture,
  ${StyledNextImageWrapper} {
    source,
    img {
      display: block;
      ${({ $animate, $animationType, $animationStyle }) =>
  $animate && $animationType === 'BACKGROUNDPARALLAX' && $animationStyle.zoomIn};
    }
  }
`;
