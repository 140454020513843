import React, { FC } from 'react';
import Link from 'next/link';

import { GlobalAnimationsStyle } from '../../../../sites/animations';
import { StyledButton } from '../../../basic/Buttons/ButtonStyles';
import {
  BlockMediaBasicItem,
  CallToActionArrangement,
  CallToActionStyle,
  ColourTheme,
  ContentBlockAnimationMediaCtAs,
  FontSize,
  HtmlLink,
  Margin,
  Maybe,
  MultipleDefaultContentPositions,
} from '../../../types/middleware-types';
import { isImageV2 } from '../../../types/type-checkers';
import formatPromoEvent from '../../../utils/DataLayer/formatPromoEvent';
import ModuleItem from '../MediaModuleItem';
import {
  StyledContentContainer,
  StyledCTAContainer,
  StyledMediaContainer,
  StyledMediaCTAs,
} from './MediaCTAsStyles';

export interface MediaCTAsStructureProps {
  id: string;
  marginDesktop: Margin;
  marginMobile: Margin;
  textAlignment?: string;
  fontSize: FontSize;
  colourTheme: ColourTheme;
  backgroundColour: Maybe<string>;
  callToActions?: Maybe<Array<HtmlLink>>;
  contentDefaultPositionDesktop: MultipleDefaultContentPositions;
  contentDefaultPositionMobile: MultipleDefaultContentPositions;
  mediaItem: Maybe<BlockMediaBasicItem>;
  top?: Maybe<number>;
  left?: Maybe<number>;
  pageName: Maybe<string> | undefined;
  forwardedRef: (node: HTMLDivElement) => void;
  animate: boolean;
  animationType?: Maybe<ContentBlockAnimationMediaCtAs>;
  animationStyle: GlobalAnimationsStyle;
  moduleIndex: number;
  callToActionButtonBackgroundColourHex: Maybe<string>;
  callToActionStyle: CallToActionStyle;
  callToActionArrangement: CallToActionArrangement;
  callToActionIntro?: Maybe<string>;
  buttonType: string;
  absoluteDesktop: boolean;
  absoluteMobile: boolean;
  topDesktop?: Maybe<number>;
  leftDesktop?: Maybe<number>;
  topMobile?: Maybe<number>;
  leftMobile?: Maybe<number>;
  previewImage: boolean;
  useReactRoutingLinks: boolean;
}

const MediaCTAsStructure: FC<MediaCTAsStructureProps> = ({
  id,
  marginDesktop,
  marginMobile,
  textAlignment,
  colourTheme,
  callToActionButtonBackgroundColourHex,
  contentDefaultPositionDesktop,
  contentDefaultPositionMobile,
  absoluteDesktop,
  topDesktop,
  leftDesktop,
  absoluteMobile,
  topMobile,
  leftMobile,
  callToActionArrangement,
  callToActionStyle,
  callToActionIntro,
  callToActions,
  mediaItem,
  pageName,
  forwardedRef,
  animationType,
  animate,
  animationStyle,
  moduleIndex,
  buttonType,
  fontSize,
  backgroundColour,
  previewImage,
  useReactRoutingLinks,
}) => (
  <StyledMediaCTAs
    ref={forwardedRef}
    $marginMobile={marginMobile}
    $marginDesktop={marginDesktop}
    $backgroundColour={backgroundColour}
    $absoluteDesktop={absoluteDesktop}
    $absoluteMobile={absoluteMobile}
    $contentDefaultPositionDesktop={contentDefaultPositionDesktop}
    $contentDefaultPositionMobile={contentDefaultPositionMobile}
    data-hookid={`${pageName}MediaCTAs`}
  >
    {mediaItem && (
      <StyledMediaContainer>
        {previewImage ? (
          <ModuleItem
            {...mediaItem}
            animationType={animationType}
            animate={animate}
            animationStyle={animationStyle}
            moduleIndex={moduleIndex}
            id={id}
            sizes="100vw"
            imgPriority={moduleIndex === 0}
          />
        ) : (
          <ModuleItem
            {...mediaItem}
            animationType={animationType}
            animate={animate}
            animationStyle={animationStyle}
            moduleIndex={moduleIndex}
            id={id}
            sizes="100vw"
            imgPriority={moduleIndex === 0}
          />
        )}
      </StyledMediaContainer>
    )}
    <>
      <StyledContentContainer
        $textAlignment={textAlignment}
        $absoluteMobile={absoluteMobile}
        $topMobile={topMobile}
        $leftMobile={leftMobile}
        $absoluteDesktop={absoluteDesktop}
        $topDesktop={topDesktop}
        $leftDesktop={leftDesktop}
        $callToActionArrangement={callToActionArrangement}
        $callToActionStyle={callToActionStyle}
        $contentDefaultPositionDesktop={contentDefaultPositionDesktop}
        $contentDefaultPositionMobile={contentDefaultPositionMobile}
        $animationType={animationType}
        $animate={animate}
        $animationStyle={animationStyle()}
        $fontMediaSize={fontSize}
        $colourTheme={colourTheme}
      >
        {callToActionIntro && <h4 className="custom-size-description">{callToActionIntro}</h4>}
        <StyledCTAContainer
          $textAlignment={textAlignment}
          $ctaBackground={callToActionButtonBackgroundColourHex}
          $callToActionArrangement={callToActionArrangement}
          $callToActionStyle={callToActionStyle}
          $contentDefaultPositionDesktop={contentDefaultPositionDesktop}
          $contentDefaultPositionMobile={contentDefaultPositionMobile}
          $leftMobile={leftMobile}
        >
          {callToActions &&
            callToActions?.length > 0 &&
            callToActions.map((cta, i) => (
              <React.Fragment key={i}>
                {cta?.url && (
                  <>
                    {useReactRoutingLinks ? (
                      <Link href={cta.url} passHref legacyBehavior>
                        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
                        <a
                          key={`MediaCTAs${cta.url}${cta.text}${i}`}
                          className="custom-size-link"
                          {...(cta.newWindow ? { target: '_blank', rel: 'noreferrer' } : {})}
                          onClick={() =>
                            formatPromoEvent(
                              cta.text,
                              i + 1,
                              cta.url,
                              isImageV2(mediaItem?.media) ? mediaItem?.media?.desktop.src : '',
                              `cta${i + 1}`,
                              'click',
                            )}
                        >
                          {/* Given a negative z-index as the interactive element should be the wrapping <a> */}
                          <StyledButton $btnType={buttonType} as="span" tabIndex={-1}>
                            {cta.text}
                          </StyledButton>
                        </a>
                      </Link>
                    ) : (
                      <a
                        // eslint-disable-next-line react/no-array-index-key
                        key={`MediaCTAs${cta.url}${cta.text}${i}`}
                        className="custom-size-link"
                        href={cta.url}
                        {...(cta.newWindow ? { target: '_blank', rel: 'noreferrer' } : {})}
                        onClick={() =>
                          formatPromoEvent(
                            cta.text,
                            i + 1,
                            cta.url,
                            isImageV2(mediaItem?.media) ? mediaItem?.media?.desktop.src : '',
                            `cta${i + 1}`,
                            'click',
                          )}
                      >
                        {/* Given a negative z-index as the interactive element should be the wrapping <a> */}
                        <StyledButton $btnType={buttonType} as="span" tabIndex={-1}>
                          {cta.text}
                        </StyledButton>
                      </a>
                    )}
                  </>

                )}
              </React.Fragment>
            ))}
        </StyledCTAContainer>
      </StyledContentContainer>
    </>
  </StyledMediaCTAs>
);

export default MediaCTAsStructure;
