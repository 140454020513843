'use client'

import React, { FC } from 'react';

import { FontSize, Margin } from '../../../types/middleware-types';
import cleanHtml from '../../../utils/formatters/cleanHtml';
import TextBlockStructure from './TextBlockStructure';

export interface ContentTextBlockProps {
  contentHtmlRequired: string;
  animation?: string | null;
  marginDesktop: Margin;
  marginMobile: Margin;
  textBlockAlignment: string;
  fontSize: FontSize;
  accordionTitle?: string;
  isAccordion?: boolean;
  accordionOpenByDefault: boolean;
}

const ContentTextBlock: FC<ContentTextBlockProps> = ({
  contentHtmlRequired,
  animation,
  marginDesktop,
  marginMobile,
  textBlockAlignment,
  fontSize,
  accordionTitle,
  isAccordion,
  accordionOpenByDefault,
}) => {
  const componentProps = {
    contentHtmlRequired: cleanHtml(contentHtmlRequired),
    animation,
    marginDesktop,
    marginMobile,
    textBlockAlignment,
    fontSize,
    accordionTitle,
    isAccordion,
    accordionOpenByDefault,
  };
  return <TextBlockStructure {...componentProps} />;
};

export default ContentTextBlock;
