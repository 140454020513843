import styled from 'styled-components';

import { MarginWrapper } from '../../../../styles/contentBlockMedia';
import { Margin, Maybe } from '../../../../types/middleware-types';
import {
  StyledSlider,
  StyledSliderArrowContainer,
  StyledSliderWrapper,
} from '../../../Slider/SliderStyles';

interface StyledCarouselAWrapperProps {
  $marginMobile: Margin;
  $marginDesktop: Margin;
  $backgroundColour: Maybe<string>;
  $showArrows: boolean;
}

export const StyledCarouselAWrapper = styled.div<StyledCarouselAWrapperProps>`
  display: flex;
  flex-direction: column;

  ${MarginWrapper};
  @media ${({ theme }) => theme.devices.large} {
    flex-direction: row;
  }

  ${StyledSlider} {
    padding: 0 !important;
    overflow-x: hidden;

    @media ${({ theme }) => theme.devices.large} {
      padding: 0 0.8rem !important;
    }
  }

  ${StyledSliderWrapper} {
    padding-left: 33%;

    @media ${({ theme }) => theme.devices.large} {
      padding-left: 0;
      margin: 0 ${({ $showArrows }) => ($showArrows ? '0' : '1rem')};
    }
  }

  ${StyledSliderArrowContainer} {
    button {
      width: 3.2rem;
    }
  }
`;

export const StyledCarouselAMediaItemWrapper = styled.div`
  width: 100%;

  @media ${({ theme }) => theme.devices.large} {
    width: 25%;
  }
`;
