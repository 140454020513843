import React, { FC } from 'react';

import { GlobalAnimationsStyle } from '../../../../sites/animations';
import {
  ContentBlockAnimationMediaShopBySize,
  ContentBlockMediaShopBySize,
  Maybe,
} from '../../../types/middleware-types';
import ModuleItem from '../MediaModuleItem';
import {
  StyledMediaShopBySizeMediaWrapper,
  StyledMediaShopBySizeSizesWrapper,
  StyledMediaShopBySizeSplitLine,
  StyledMediaShopBySizeWrapper,
} from './MediaShopBySizeStyles';
import SizesGroup from './SizesGroup';

export interface MediaShopBySizeStructureProps extends ContentBlockMediaShopBySize {
  animate?: boolean;
  animationType?: Maybe<ContentBlockAnimationMediaShopBySize>;
  animationStyle: GlobalAnimationsStyle;
  pageName: Maybe<string> | undefined;
  forwardedRef: (node: HTMLDivElement) => void;
  moduleIndex: number;
}

const MediaShopBySizeStructure: FC<MediaShopBySizeStructureProps> = ({
  id,
  marginDesktop,
  marginMobile,
  mediaPromoTitle,
  fontSize,
  mediaItem,
  sizesItem,
  sizesBackgroundColourHex,
  sizesTextColourHex,
  sizesLinkColourHex,
  mediaItemOnLeft,
  pageName,
  forwardedRef,
  animationType,
  animate,
  animationStyle,
  moduleIndex
}) => {
  return (
    <StyledMediaShopBySizeWrapper
      ref={forwardedRef}
      $marginDesktop={marginDesktop}
      $marginMobile={marginMobile}
      $mediaItemOnLeft={mediaItemOnLeft}
      $shouldCenterContent={!mediaItem}
      data-hookid={`${pageName}MediaShopBySize`}
      $fontMediaSize={fontSize}
    >
      <StyledMediaShopBySizeSizesWrapper
        $shouldTakeFullWidth={!mediaItem}
        $backgroundColor={sizesBackgroundColourHex}
        $sizesTextColourHex={sizesTextColourHex}
      >
        <div className="custom-size-title">
          <h2>{sizesItem.title}</h2>
        </div>
        <p className="custom-size-subtitle">{sizesItem.subtitle}</p>
        {sizesItem.linkGroups.map((linkGroup) => (
          <SizesGroup
            {...{
              ...linkGroup,
              fontSize,
              shouldCenter: !mediaItem,
              sizesTextColourHex,
              sizesLinkColourHex,
            }}
            key={linkGroup.title}
          />
        ))}
      </StyledMediaShopBySizeSizesWrapper>
      {mediaItem && <StyledMediaShopBySizeSplitLine />}
      {mediaItem && (
        <StyledMediaShopBySizeMediaWrapper color={mediaPromoTitle?.colourHex}>
          {mediaPromoTitle && (
            <div className="custom-size-title">
              <h2>{mediaPromoTitle.title}</h2>
            </div>
          )}
          <ModuleItem
            id={id}
            {...mediaItem}
            hideOnMobile={false}
            animationType={animationType}
            animate={animate}
            animationStyle={animationStyle}
            moduleIndex={moduleIndex}
            fontSizeParent={fontSize}
            sizes="50vw"
          />

        </StyledMediaShopBySizeMediaWrapper>
      )}
    </StyledMediaShopBySizeWrapper>
  );
};

export default MediaShopBySizeStructure;
