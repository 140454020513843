import React, { FC } from 'react';

import { GlobalAnimationsStyle } from '../../../../../sites/animations';
import {
  BlockMediaCarouselItem,
  FontSize,
  Margin,
  Maybe,
  Media,
  ProductListItem,
  ProductMedia,
} from '../../../../types/middleware-types';
import { DEFAULT_ON_SCROLL_STYLE } from '../../../../utils/customHooks/useAnimationOnScroll';
import Carousel from '../../../Carousel';
import { SliderSettings } from '../../../Slider/Slider.types';
import ModuleItem from '../../MediaModuleItem';
import CarouselProductItem from '../CarouselProductItem';
import { StyledCarouselABCDCarouselWrapper } from '../ContentBlockCarouselStyles';
import { StyledCarouselAMediaItemWrapper, StyledCarouselAWrapper } from './CarouselAStyles';

interface CarouselAStructureProps {
  id: string;
  isProductCarousel: boolean;
  mediaItem: Omit<BlockMediaCarouselItem, 'media'> & {
    fontSizeParent: FontSize;
    media: Maybe<Media> | ProductMedia | undefined;
    marginDesktop: Margin;
    marginMobile: Margin;
  };
  carouselItems: Array<
    (BlockMediaCarouselItem | ProductListItem) & {
      pageName: string;
      fontSizeParent: FontSize;
      componentName: string;
      marginMobile: Margin;
      marginDesktop: Margin;
      campaignId?: Maybe<string>;
      sizes?: string;
      showBrandNames?: boolean;
      showPrices?: boolean;
      showProductNames?: boolean;
    }
  >;
  marginDesktop: Margin;
  marginMobile: Margin;
  sliderSettings: SliderSettings;
  shouldScrollOnDesktop: boolean;
  showArrows: boolean;
  pageName?: string;
  forwardedRef: (node: HTMLDivElement) => void;
  animationType?: string;
  animate?: boolean;
  animationStyle?: GlobalAnimationsStyle;
  backgroundColour: string;
}

const CarouselAStructure: FC<CarouselAStructureProps> = ({
  id,
  isProductCarousel,
  mediaItem,
  carouselItems,
  marginMobile,
  marginDesktop,
  sliderSettings,
  shouldScrollOnDesktop,
  showArrows,
  pageName = '',
  forwardedRef,
  animationType = '',
  animate = false,
  animationStyle = DEFAULT_ON_SCROLL_STYLE,
  backgroundColour,
}) => {
  return (
    <StyledCarouselAWrapper
      $marginMobile={marginMobile}
      $marginDesktop={marginDesktop}
      $backgroundColour={backgroundColour}
      data-hookid={`${pageName}CarouselA`}
      ref={forwardedRef}
      $showArrows={showArrows}
    >
      <StyledCarouselAMediaItemWrapper>
        <ModuleItem
          {...mediaItem}
          id={id}
          animationType={animationType}
          animate={animate}
          animationStyle={animationStyle}
          sizes="(max-width: 1024px) 100vw, 25vw"
        />
      </StyledCarouselAMediaItemWrapper>
      <StyledCarouselABCDCarouselWrapper>
        <Carousel
          items={carouselItems}
          component={isProductCarousel ? CarouselProductItem : ModuleItem}
          scrollableOnMobile={true}
          scrollableOnDesktop={shouldScrollOnDesktop}
          showArrows={showArrows}
          settings={sliderSettings}
          animationType={animationType}
          animate={animate}
          animationStyle={animationStyle}
        />
      </StyledCarouselABCDCarouselWrapper>
    </StyledCarouselAWrapper>
  );
};

export default CarouselAStructure;
