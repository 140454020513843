import styled from 'styled-components';

import { Margin } from '../../../types/middleware-types';

interface Props {
  $marginMobile?: Margin;
  $marginDesktop?: Margin;
}

const formatMargin = (margin: Margin): string =>
  `${margin.topPixels ?? 0}px ${margin.rightPixels ?? 0}px ${margin.bottomPixels ?? 0}px ${
    margin.leftPixels ?? 0
  }px`;

export const StyledRawHtmlWrapper = styled.div<Props>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: auto;
  margin: ${({ $marginMobile }) => $marginMobile && formatMargin($marginMobile)};

  @media ${({ theme }) => theme.devices.large} {
    margin: ${({ $marginDesktop }) => $marginDesktop && formatMargin($marginDesktop)};
  }

  iframe {
    margin-left: auto;
    margin-right: auto;

    &.align-left {
      margin-left: 0;
      margin-right: auto;
    }

    &.align-right {
      margin-left: auto;
      margin-right: 0;
    }
  }
`;
