import styled from 'styled-components';

import { MarginWrapper, MarginWrapperProps } from '../../../styles/contentBlockMedia';
import { StyledSliderWrapper } from '../../Slider/SliderStyles';

export const StyledCelebrityCarouselContent = styled.div<MarginWrapperProps>`
  color: ${({ theme }) => theme.types[theme.celebrity.card.color]} ${MarginWrapper};

  h2 {
    ${({ theme }) => theme.types.h3Style};
    color: inherit;
    text-align: left;
    text-transform: uppercase;
    margin: 1.6rem 0 1.6rem 1.6rem;
    justify-content: center;
    display: flex;
    align-items: center;
    align-content: center;

    @media ${({ theme }) => theme.devices.medium} {
      text-align: center;
      margin: 3.2rem 0 0.5rem;
    }

    @media ${({ theme }) => theme.devices.large} {
      ${({ theme }) => theme.types.h1Style};
    }

    span {
      flex: 0 0 auto;
      margin-right: 12px;
    }

    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background-color: ${({ theme }) => theme.vars.greyDarkest};

      @media ${({ theme }) => theme.devices.medium} {
        display: none;
      }
    }
  }

  a {
    ${({ theme }) => theme.types.tertiaryLinkStyles}
    text-align: center;
    width: 100%;
    display: block;
    color: inherit;
    margin: 1.6rem 0;
    text-decoration: underline;
    font-weight: 400;
  }

  ${StyledSliderWrapper} {
    padding-left: 33%;

    @media ${({ theme }) => theme.devices.medium} {
      padding-left: 0;
    }
  }
`;

export const StyledCelebrityCarouselPanel = styled.div<{ $active: boolean }>`
  max-height: ${({ $active }) => ($active ? 80 : 0)}rem;
  transition: max-height 1.5s ease-out;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 1.6rem;
  position: relative;
  align-items: center;
  color: ${({ theme }) => theme.types[theme.celebrity.card.color]};
  overflow-y: hidden;

  @media ${({ theme }) => theme.devices.medium} {
    display: none;
  }
`;

export const StyledCelebrityCarouselPanelContentWrapper = styled.div`
  margin: 1.6rem 0;
`;

export const StyledCelebrityCarouselPanelTitle = styled.p`
  text-transform: none;
  margin: 8px 0;
  color: inherit;
`;

export const StyledCelebrityCarouselPanelBrand = styled.p`
  ${({ theme }) => theme.types.h4Style}
  text-transform: none;
  margin: 8px 0;
  color: inherit;
`;

export const StyledCelebrityCarouselDangerousContent = styled.div`
  margin-top: ${({ theme }) => theme.vars.sizeM};
  ${({ theme }) => theme.types.labelSmallStyle}
  text-align: left;
  color: inherit;

  p {
    margin: 0;
  }
`;

export const StyledCelebrityCarouselPanelImageWrapper = styled.a`
  margin-left: ${({ theme }) => theme.vars.sizeM};
  flex: 0 0 16rem;
  color: inherit;

  img {
    height: 16rem;
    width: 16rem;
  }

  span {
    ${({ theme }) => theme.types.tertiaryLinkStyles}
    text-align: center;
    display: block;
    text-decoration: underline;
    font-weight: 400;
  }
`;
