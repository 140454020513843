import React, { FC } from 'react';

import { GlobalAnimationsStyle } from '../../../../sites/animations';
import MediaElement from '../../../basic/MediaElement';
import { CustomMedia } from '../../../types/custom-types';
import {
  BlockMediaCarouselItem,
  ColourTheme,
  HtmlLink,
  Margin,
  Maybe,
  MultipleDefaultContentPositions,
  TextAlignment,
} from '../../../types/middleware-types';
import Carousel from '../../Carousel';
import { SliderSettings } from '../../Slider/Slider.types';
import ModuleContent from '../MediaModuleContent';
import ModuleItem from '../MediaModuleItem';
import {
  StyledMediaCarouselBackgroundWrapper,
  StyledMediaCarouselCarouselWrapper,
  StyledMediaCarouselItemsWrapper,
  StyledMediaCarouselOneItemWrapper,
  StyledMediaCarouselWrapper,
} from './MediaCarouselStyles';

export interface MediaCarouselStructureProps {
  id: string;
  contentDefaultPositionDesktop: MultipleDefaultContentPositions;
  contentDefaultPositionMobile: MultipleDefaultContentPositions;
  backgroundColour: Maybe<string>;
  marginDesktop: Margin;
  marginMobile: Margin;
  animate: boolean;
  animationType?: Maybe<string>;
  animationStyle: GlobalAnimationsStyle;
  shouldScrollOnMobile: boolean;
  shouldScrollOnDesktop: boolean;
  forwardedRef: (node: HTMLDivElement) => void;
  settings: SliderSettings;
  oneElementWidth: number;
  isDesktop?: boolean;
  background: CustomMedia;
  pageName: Maybe<string> | undefined;
  moduleItemsProps: Array<BlockMediaCarouselItem>;
  contentProps: {
    title?: Maybe<string>;
    subtitle?: Maybe<string>;
    callToAction?: Maybe<HtmlLink>;
    colourTheme: ColourTheme;
    textAlignment: Maybe<TextAlignment>;
    contentDefaultPositionMobile: MultipleDefaultContentPositions;
  };
}

const MediaCarouselStructure: FC<MediaCarouselStructureProps> = ({
  id,
  contentDefaultPositionDesktop,
  contentDefaultPositionMobile,
  marginDesktop,
  marginMobile,
  moduleItemsProps,
  contentProps,
  shouldScrollOnMobile,
  shouldScrollOnDesktop,
  oneElementWidth,
  settings,
  background,
  pageName,
  forwardedRef,
  animationType,
  animate,
  animationStyle,
  isDesktop,
  backgroundColour
}) => {
  return (
    <StyledMediaCarouselWrapper
      $marginMobile={marginMobile}
      $marginDesktop={marginDesktop}
      $backgroundColour={backgroundColour}
      $contentDefaultPositionDesktop={contentDefaultPositionDesktop}
      $contentDefaultPositionMobile={contentDefaultPositionMobile}
      $shouldScrollOnMobile={shouldScrollOnMobile}
      data-hookid={`${pageName}ModuleF`}
      ref={forwardedRef}
    >
      {background && (
        <StyledMediaCarouselBackgroundWrapper>
          <MediaElement {...background} />
        </StyledMediaCarouselBackgroundWrapper>
      )}
      <ModuleContent {...contentProps} />

      {(shouldScrollOnMobile && !isDesktop) || (shouldScrollOnDesktop && isDesktop) ? (
        <StyledMediaCarouselCarouselWrapper>
          <Carousel
            showArrows={false}
            items={moduleItemsProps}
            component={ModuleItem}
            settings={settings}
            animationType={animationType}
            animate={animate}
            animationStyle={animationStyle}
            scrollableOnMobile={true}
          />

        </StyledMediaCarouselCarouselWrapper>
      ) : (
        <StyledMediaCarouselItemsWrapper>
          {moduleItemsProps.map((itemProp, i) => (
            <StyledMediaCarouselOneItemWrapper
              width={oneElementWidth}
              // --- temporary key until real data
              // eslint-disable-next-line react/no-array-index-key
              key={`${id}-${contentProps.title}-${i}`}
            >
              <ModuleItem
                id={id}
                animationType={animationType}
                animate={animate}
                animationStyle={animationStyle}
                {...itemProp}
                index={i}
              />
            </StyledMediaCarouselOneItemWrapper>
          ))}
        </StyledMediaCarouselItemsWrapper>
      )}
    </StyledMediaCarouselWrapper>
  );
};

export default MediaCarouselStructure;
