export const settings: Record<string, boolean | number | null> = {
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: true,
  swipe: true,
  dots: false,
};

export const mobileSettings: Record<string, boolean | number | null> = {
  ...settings,
  arrows: false,
  slidesToShow: 2.5,
};
