'use client'

import React, { ElementType, FC } from 'react';
import { useSelector } from 'react-redux';

import { selectPageName } from '../../../../features/static';
import {
  FontSize,
  Maybe,
  MediaAndText50PercentSplitAccordion as MediaAndText50PercentSplitAccordionType,
  TextAlignment,
} from '../../../../types/middleware-types';
import Text, { ContentTextBlockProps } from '../../Text';
import MediaAndText50PercentSplitAccordionStructure from './MediaAndText50PercentSplitAccordionStructure';

interface MediaAndText50PercentSplitAccordionProps {
  accordion: Array<Maybe<MediaAndText50PercentSplitAccordionType>>;
  fontSize: FontSize;
}

export type Media50SplitAccordionDataType = {
  content: ContentTextBlockProps;
  title: string;
  itemComponent: ElementType;
};

const AccordionComponent: FC<{ data: { content: ContentTextBlockProps } }> = ({
  data: { content },
}) => <Text {...content} />;

const MediaAndText50PercentSplitAccordion: FC<MediaAndText50PercentSplitAccordionProps> = ({
  accordion,
  fontSize,
}) => {
  let defaultOpen = '';
  const pageName = useSelector(selectPageName);
  const accordionData = accordion.reduce((previous: Array<Media50SplitAccordionDataType>, item) => {
    if (!item) {
      return previous;
    }

    if (item.accordionOpenByDefault) {
      defaultOpen = item.title;
    }

    previous.push({
      content: {
        ...item,
        contentHtmlRequired: item.contentHtml,
        marginDesktop: { rightPixels: 14 },
        marginMobile: { rightPixels: 14 },
        textBlockAlignment: TextAlignment.Left,
        fontSize,
      },
      title: item.title,
      itemComponent: AccordionComponent,
    });

    return previous;
  }, []);

  const props = { accordionData, defaultOpen, pageName, fontSize };

  return <>{accordionData && <MediaAndText50PercentSplitAccordionStructure {...props} />}</>;
};

export default MediaAndText50PercentSplitAccordion;
