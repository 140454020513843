'use client'

import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrencyData, selectPageName } from '../../../features/static/selectors';
import {
  ContentBlockAnimationMediaBasic,
  ContentBlockCharityScheme,
  Maybe,
} from '../../../types/middleware-types';
import useAnimationOnScroll from '../../../utils/customHooks/useAnimationOnScroll';
import CharitySchemeStructure from './CharitySchemeStructure';

export interface CharitySchemeProps extends ContentBlockCharityScheme {
  index?: number;
  animationCharityScheme?: Maybe<ContentBlockAnimationMediaBasic>;
}

const CharityScheme: FC<CharitySchemeProps> = ({
  animationCharityScheme: animationType,
  index: moduleIndex = 0,
  item,
  startAmount,
  endAmount,
  startDate,
  endDate,
  ...otherProps
}) => {
  const currencyData = useSelector(selectCurrencyData);
  const pageName = useSelector(selectPageName);
  const finalStartAmount = Number(startAmount?.replace(/,/g, ''))
  const finalEndAmount = Number(endAmount?.replace(/,/g, ''))
  const { enteredView, animationStyle, forwardedRef } = useAnimationOnScroll(animationType);
  const [showComponent, setShowComponent] = useState(false);

  useEffect(()=> {
    setShowComponent(true)
  },[])

  /**
   * function used to calculate the time needed for the amount to increase by 1
   * @returns calculated time increment
   */
  const getIncTime = (): number | null => {
    if (!startDate || !endDate) return null;

    const startDateMilliseconds = new Date(startDate).getTime();
    const endDateMilliseconds = new Date(endDate).getTime();

    const incTime = Math.round((endDateMilliseconds - startDateMilliseconds) / (finalEndAmount - finalStartAmount));

    return incTime;
  }

  const getInitAmountFromLocalStorage = (): number | null => {
    if (typeof window === 'undefined') return 0;

    const initAmountString = localStorage.getItem('charityTotal');

    return initAmountString !== null ? Number(initAmountString) || null : null;
  }

  /**
   * function used to linearly map the date range [startDate - endDate] into the amount range [startAmount - endAmount]
   * @returns calculated amount for the current date
   */
  const getCurrentLinearAmount = () => {
    if (!startDate || !endDate) return null;

    const currentDateMilliseconds = Date.now();
    const startDateMilliseconds = new Date(startDate).getTime();
    const endDateMilliseconds = new Date(endDate).getTime();

    if (startDateMilliseconds >= endDateMilliseconds) return null;

    const dateDiffPercent = (currentDateMilliseconds - startDateMilliseconds) / (endDateMilliseconds - startDateMilliseconds);

    return Math.round(finalStartAmount + dateDiffPercent * (finalEndAmount - finalStartAmount));
  }

  const initCurrentLinearAmount = getCurrentLinearAmount();

  const getInitTotal = () => {
    if (initCurrentLinearAmount === null) return null;
    if (typeof window === 'undefined') return 0;

    let initTotal = initCurrentLinearAmount;
    const lsAmount = getInitAmountFromLocalStorage();
    if (lsAmount !== null) {
      // If the stored amount is too far off the predicted amount, set init amount to a closer value
      initTotal = Math.abs(initCurrentLinearAmount - lsAmount) > 20 ? initCurrentLinearAmount - 20 : lsAmount;
    }
    localStorage.setItem('charityTotal', `${initTotal}`)

    return initTotal;
  }

  const [total, setTotal] = useState(getInitTotal());
  const [linearTotal, setLinearTotal] = useState(initCurrentLinearAmount);

  useEffect(() => {
    if (total !== null && linearTotal !== null && linearTotal > total + 10) {
      const rand = Math.floor(Math.random() * 10);
      const newTotal = Math.min(Math.max(total + rand, finalStartAmount), finalEndAmount);
      localStorage.setItem('charityTotal', `${newTotal}`)
      setTotal(newTotal);
    }
  }, [linearTotal])

  useEffect(() => {
    const updateLinearTotal = () => {
      setLinearTotal(getCurrentLinearAmount());
    }
    let timer: NodeJS.Timeout | null = null;
    const incTime = getIncTime();

    if (incTime !== null && incTime > 0) {
      timer = setInterval(updateLinearTotal, incTime);
    }

    return () => {
      if (timer) {
        clearInterval(timer)
      }
    };
  }, [])

  const formatAmount = (num: number): string => {
    if (num < 1000) return `${num}`;
    const numString = num.toString();
    const indexOfLastComma = numString.length % 3 || 3;
    const formattedAmount = [
      numString.slice(0, indexOfLastComma),
      ...numString.slice(indexOfLastComma).match(/.{1,3}/g) ?? []
    ].join(',');

    return formattedAmount;
  }

  const getSymbol = () => {
    return currencyData?.symbol
  }

  const currentAmount = total !== null && formatAmount(total);
  const amountWithSymbol = process.env.NEXT_PUBLIC_MIDDLEWARE_SITEID === 'kurtgeigerglobal' || (typeof window !== 'undefined' && window.location.host.includes('global.kurtgeiger')) ? '£' : `${getSymbol()}${currentAmount}`;

  const componentProps = {
    ...otherProps,
    moduleIndex,
    animate: enteredView,
    animationStyle,
    forwardedRef,
    pageName,
    item,
    startAmount,
    endAmount,
    startDate,
    endDate,
    amountWithSymbol
  };

  /* We have to check this otherwise we get a hydration error
  TODO: check how to output this value server side
  */
  return <>{showComponent && total !== 0 && <CharitySchemeStructure {...componentProps} />}</>;
};

export default CharityScheme;
