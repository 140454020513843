'use client'

import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParallaxController } from 'react-scroll-parallax';
import { useTheme } from 'styled-components';

import { selectPageName } from '../../../features/static/selectors';
import {
  BlockMediaBasicItem,
  ContentBlockMediaParallax,
  Maybe,
  TextAlignment,
} from '../../../types/middleware-types';
import useMobileWatcher from '../../../utils/customHooks/useMobileWatcher';
import { isInView } from '../../../utils/helpers/isInView';
import MediaParallaxStructure from './MediaParallaxStructure';

export interface MediaParallaxProps extends ContentBlockMediaParallax {
  parallaxItems?: Maybe<Array<Maybe<BlockMediaBasicItem>>>;
}

// Module E2
const MediaParallax: FC<MediaParallaxProps> = ({
  parallaxItems: mediaItems,
  fontSize,
  textAlignment = TextAlignment.Centre,
  backgroundColour = 'transparent',
  staticImageIndex,
  ...rest
}) => {
  const theme = useTheme();
  const { isDesktop } = useMobileWatcher(['desktop'], theme.vars);
  const [items, setItems] = useState<Array<Maybe<BlockMediaBasicItem & { velocity?: number }>>>([]);
  const parallaxController = useParallaxController();
  const [element, setElement] = useState<Maybe<HTMLDivElement>>(null);
  const [parallaxUpdated, setParallaxUpdated] = useState(false);
  const pageName = useSelector(selectPageName);

  useEffect(() => {
    const addItemsVelocity = (): Array<Maybe<BlockMediaBasicItem & { velocity?: number }>> => {
      const VELOCITY = 300;
      let movingItems = 0;
      const staticItemIndex = Math.max(1, Math.min(staticImageIndex, mediaItems?.length || 0));
      return (
        mediaItems?.map((item, i) => {
          if (i === staticItemIndex - 1 || !item) {
            return item;
          }
          movingItems += 1;
          return { ...item, velocity: movingItems * VELOCITY };
        }) || []
      );
    };
    setItems(addItemsVelocity());
  }, [mediaItems]);

  useEffect(() => {
    const onScroll = (): void => {
      if (element && !parallaxUpdated && isInView(element)) {
        parallaxController?.update();
        setParallaxUpdated(true);
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [element, parallaxUpdated]);

  const onRefChange = useCallback((el: HTMLDivElement): void => {
    setElement(el);
  }, []);

  const componentProps = {
    ...rest,
    textAlignment,
    backgroundColour,
    items,
    isDesktop: !!isDesktop,
    pageName,
    fontSize,
  };

  return (
    <>{parallaxController && <MediaParallaxStructure {...componentProps} ref={onRefChange} />}</>
  );
};

export default MediaParallax;

