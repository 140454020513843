export const settings = {
  arrows: false,
  infinite: true,
  speed: 1250,
  autoplay: true,
  autoplaySpeed: 2500,
  adaptiveHeight: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  swipe: true,
  dots: false,
  autoplayPauseOnHover: true,
};

export const mobileSettings = {
  ...settings,
  slidesToShow: 1.5,
};
