import React, { FC } from 'react';

import { isCMSBlocks, isCMSBlocksCarousel } from '../../types/type-checkers';
import ComponentPicker from '../../utils/ComponentPicker';
import Background from '../Background';
import ContentBlockCarousel from '../CMSModules/ContentBlockCarousel';
import { CMSBlocksProps } from './BlocksArray.types';


const BlocksArrayStructure: FC<CMSBlocksProps> = ({ blocks, logo, background }) => {
  return (
    <>
      <Background logo={logo} background={background} />
      {blocks &&
        blocks.length > 0 &&
        blocks.map((componentProps, index) => {
          const props = { ...componentProps, index };
          if (!componentProps.id) {
            return null;
          }
          return (
            <div key={`${componentProps.id}`} data-hookid="componentBlock">
              {isCMSBlocks(componentProps) ? (
                <>
                  <ComponentPicker
                    /* eslint no-underscore-dangle: 0 */
                    componentName={componentProps?.__typename?.split('ContentBlock')[1]}
                    layout={componentProps.__typename}
                    {...props}
                  />
                  {isCMSBlocksCarousel(componentProps) && <ContentBlockCarousel {...componentProps} />}
                </>
              ) : (
                ''
              )}
            </div>
          );
        })}
    </>
  );
};

export default BlocksArrayStructure;
