// @ts-nocheck
import styled from 'styled-components';

import { MarginWrapper } from '../../../styles/contentBlockMedia';
import { CustomSizeDescription, MediaFontSize } from '../../../styles/fontSize';
import { FontSize, Margin } from '../../../types/middleware-types';
import { StyledAccordionTrigger } from '../../Accordion/AccordionStyles';

interface WrapperProps {
  $marginMobile: Margin;
  $marginDesktop: Margin;
}

interface Props {
  $textAlignment: string;
  $animationType?: string | null;
  $fontMediaSize: FontSize;
}

export const StyledTextBlockWrapper = styled.div<WrapperProps>`
  ${MarginWrapper};

  ${StyledAccordionTrigger} {
    ${({ theme }) => theme.types.h1Style};
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.vars.black};

    span {
      margin-left: 2.2rem;
      font-size: ${({ theme }) => theme.vars.sizeM};
    }
  }
`;

export const StyledTextBlock = styled.div<Props>`
  /* stylelint-disable */
  & > * {
    text-align: ${({ $textAlignment }) =>
  $textAlignment === 'CENTRE' ? 'center' : $textAlignment.toLowerCase()};
    line-height: unset;
    ${({ $animationType }) =>
    ($animationType === 'MOVEFADE' || $animationType === 'BLOCKMOVEFADE') && 'overflow: hidden;'}
  }

  p {
    ${CustomSizeDescription};
  }

  ${MediaFontSize}
`;