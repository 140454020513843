'use client'

import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectElements } from '../../features/elements';
import { ImageV2, Maybe } from '../../types/middleware-types';
import parseCMSMediaProp from '../../utils/formatters/parseCMSMediaProp';
import BackgroundStructure from './BackgroundStructure';

export interface BackgroundProps {
  background?: Maybe<ImageV2>;
  logo?: Maybe<ImageV2>;
}

const Background: FC<BackgroundProps> = ({ background, logo }) => {
  const { promoBanner } = useSelector(selectElements);
  const [promoBannerHeight, setPromoBannerHeight] = useState(0);

  useEffect(() => {
    setPromoBannerHeight(promoBanner ? promoBanner.clientHeight : 0);
  }, [promoBanner]);

  const componentProps = {
    background: parseCMSMediaProp(background),
    logo: parseCMSMediaProp(logo),
    topOffset: promoBannerHeight,
  };

  return <BackgroundStructure {...componentProps} />;
};

export default Background;
