import React, { FC } from 'react';

import { CMSBlocksProps } from './BlocksArray.types';
import BlocksArrayStructure from './BlocksArrayStructure';

const BlocksArray: FC<CMSBlocksProps> = ({ blocks, logo, background }) => {
  const props = { blocks, logo, background };
  return <BlocksArrayStructure {...props} />;
};

export default BlocksArray;