import { ComponentType } from 'react';

import CelebrityStyleCarousel from '../../components/CMSModules/CelebrityStyleCarousel';
import CharityScheme from '../../components/CMSModules/CharityScheme';
import ContentBlockCarousel from '../../components/CMSModules/ContentBlockCarousel';
import LoyaltyScheme from '../../components/CMSModules/LoyaltyScheme';
import MediaAndText50PercentSplit from '../../components/CMSModules/MediaAndText50PercentSplit';
import MediaBanner from '../../components/CMSModules/MediaBanner';
import MediaBasic from '../../components/CMSModules/MediaBasic';
import MediaCarousel from '../../components/CMSModules/MediaCarousel';
import MediaCarouselSimpleMobileOnly from '../../components/CMSModules/MediaCarouselSimpleMobileOnly';
import MediaCTAs from '../../components/CMSModules/MediaCTAs';
import MediaDoubleHeightImageOnLeftSide from '../../components/CMSModules/MediaDoubleHeightImageOnLeftSide';
import MediaFocus from '../../components/CMSModules/MediaFocus';
import MediaOverlap from '../../components/CMSModules/MediaOverlap';
import MediaParallax from '../../components/CMSModules/MediaParallax';
import MediaShopBySize from '../../components/CMSModules/MediaShopBySize';
import RawHtml from '../../components/CMSModules/RawHtml';
import Tabs from '../../components/CMSModules/Tabs';
import Text from '../../components/CMSModules/Text';


interface componentListInterface {
  components: {
    // TODO: Investigate the type for a dynamic return
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: ComponentType<any>;
  };
}

export const componentList: componentListInterface = {
  components: {
    ContentBlockCarousel,
    MediaBasic,
    MediaBanner,
    MediaAndText50PercentSplit,
    MediaCarousel,
    MediaCTAs,
    MediaDoubleHeightImageOnLeftSide,
    MediaFocus,
    MediaOverlap,
    MediaParallax,
    MediaShopBySize,
    Text,
    RawHtml,
    CelebrityStyleCarousel,
    Tabs,
    LoyaltyScheme,
    CharityScheme,
    MediaCarouselSimpleMobileOnly
  },
};
