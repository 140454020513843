import styled from 'styled-components';

import { MarginWrapper, MarginWrapperProps } from '../../../styles/contentBlockMedia';

export const StyledLoyaltyWrapper = styled.div<MarginWrapperProps>`
  ${MarginWrapper}
`;

export const StyledLoyaltyHeader = styled.div`
  text-align: center;
`;

export const StyledTierInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 4rem 0;

  h3:nth-child(2) {
    margin: 2rem 0;
  }

  @media ${({ theme }) => theme.devices.large} {
    flex-direction: row;

    h3:nth-child(2) {
      margin: 0 8rem;
    }
  }
`;

export const StyledRainbow = styled.div`
  width: 100%;
  height: 1rem;
  position: relative;
  display: flex;
  background: linear-gradient(45deg, #008000, #0000FF, #4B0082, #EE82EE, #FF0000, #FFA500, #FFFF00);
  align-items: center;
`;

interface StyledGrayOverlayProps {
  $length: number;
}

export const StyledGrayOverlay = styled.div<StyledGrayOverlayProps>`
  width: ${({ $length }) => $length}%;
  position: absolute;
  background: ${({ theme }) => theme.vars.greyDark};
  right: 0;
  height: 1rem;
  z-index: 0;
`;

export const StyledTierSquare = styled.div`
  position: relative;
  width: 1rem;
  height: 1rem;
  border: 0.1rem solid ${({ theme }) => theme.vars.white};
  box-sizing: border-box;
  background: transparent;
  z-index: 1;
`;

interface StyeldTierValueProps {
  $length: number;
}
export const StyeldTierValue = styled.div<StyeldTierValueProps>`
  position: relative;
  width: ${({ $length }) => $length}%;
  flex: 1 1 ${({ $length }) => $length}%;
  height: 1rem;
  border-top: 0.3rem solid 	#FFFFFF;
  border-bottom: 0.3rem solid 	#FFFFFF;
  box-sizing: border-box;
  background: transparent;
  z-index: 1;
`;
interface StyledTierNameProps {
  $currentIndex: number;
  $numberOfTiers: number;
}
export const StyledTierName = styled.p<StyledTierNameProps>`
  position: absolute;
  left: 50%;
  top: 0;
  white-space: nowrap;
  transform: translateX(${({ $currentIndex, $numberOfTiers }) => ($currentIndex === 0 && '-0.5rem') || ($currentIndex === $numberOfTiers - 1 && 'calc(-100% + 0.5rem)') || '-50%'});
  ${({ theme }) => theme.types.labelSmallStyle};
`;

export const StyledLoyaltyDescription = styled.p`
  ${({ theme }) => theme.types.labelSmallStyle};
  margin-top: 4rem;
  text-align: center;
`;
