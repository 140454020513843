import styled from 'styled-components';

import { GlobalAnimationStyleObject } from '../../../../sites/animations';
import { StyledNextImageWrapper } from '../../../basic/NextImage/NextImageStyles';
import { FullMediaWrapper } from '../../../styles/contentBlockMedia';
import {
  ContentBlockAnimationMediaBasic,
  Margin,
  Maybe,
  MultipleDefaultContentPositions,
} from '../../../types/middleware-types';
import {
  StyledModuleItemLinkWrapper,
  StyledModuleItemWrapper,
} from '../MediaModuleItem/ModuleItemStyles';

interface StyledModuleWrapperProps {
  $marginDesktop: Margin;
  $marginMobile: Margin;
  $backgroundColour?: Maybe<string>;
  $contentDefaultPositionDesktop: MultipleDefaultContentPositions;
  $contentDefaultPositionMobile: MultipleDefaultContentPositions;
}

export const StyledModuleWrapper = styled.div<StyledModuleWrapperProps>`
  display: flex;
  width: auto;
  justify-content: flex-start;
  position: relative;
  flex-direction: column;
  padding: 1em 0;
  ${FullMediaWrapper};
`;

interface StyledModuleItemsWrapperProps {
  $animate: boolean;
  $animationType?: Maybe<ContentBlockAnimationMediaBasic>;
  $animationStyle: GlobalAnimationStyleObject;
}

export const StyledModuleItemsWrapper = styled.div<StyledModuleItemsWrapperProps>`
  position: relative;
  display: flex;

  @media ${({ theme }) => theme.devices.smallOnly} {
    flex-direction: column;
  }

  video,
  picture,
  ${StyledNextImageWrapper} {
    source,
    img {
      display: block;
      ${({ $animate, $animationType, $animationStyle }) =>
  $animate && $animationType === 'BACKGROUNDPARALLAX' && $animationStyle.zoomIn};
    }
  }
`;

interface StyledModuleItemsColProps {
  $leftColumnWidth: string;
}

export const StyledModuleItemsCol = styled.div<StyledModuleItemsColProps>`
  :nth-child(1) {
    flex: 1 1
      ${({ $leftColumnWidth }) =>
  ($leftColumnWidth === '33%' && '33.33%') ||
        ($leftColumnWidth === '66%' && '66.66%') ||
        '50%'};
  }

  :nth-child(2) {
    flex: 1 1
      ${({ $leftColumnWidth }) =>
          ($leftColumnWidth === '33%' && '66.66%') ||
        ($leftColumnWidth === '66%' && '33.33%') ||
        '50%'};
    display: flex;
    flex-direction: column;
  }

  ${StyledModuleItemWrapper} {
    width: 100%;
    justify-content: center;
    flex: 0 0 50%;
  }

  ${StyledModuleItemLinkWrapper} {
    width: 100%;
    justify-content: center;
    flex: 0 0 50%;
  }
`;
