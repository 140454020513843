'use client'

import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectPageName } from '../../../features/static';
import { Margin } from '../../../types/middleware-types';
import RawHtmlStructure from './RawHtmlStructure';

export interface RawHtmlProps {
  rawHtml: string;
  id?: string;
  marginMobile?: Margin;
  marginDesktop?: Margin;
}

const RawHtml: FC<RawHtmlProps> = ({ rawHtml, id, marginMobile, marginDesktop }) => {
  const containsScript = rawHtml.indexOf('<script') !== -1;
  const pageName = useSelector(selectPageName);

  const componentProps = {
    rawHtml,
    containsScript,
    id,
    marginMobile,
    marginDesktop,
    pageName,
  };

  return <RawHtmlStructure {...componentProps} />;
};

export default RawHtml;
