import React, { FC } from 'react';

import CarouselItemCard from './CarouselItemCard/CarouselItemCardStructure';
import { ColourTheme, Margin, Maybe, TextAlignment } from '../../../types/middleware-types';
import Carousel from '../../Carousel';
import MobileCarousel from '../../Carousel/MobileCarousel';
import { BlockMediaCarouselSimpleMobileOnlyItemVariation } from './CarouselItemCard';
import { StyledCarouselSliderWrapper } from './MediaCarouselSimpleMobileOnlyStyles';

export interface MediaCarouselSimpleMobileOnlyStructureProps {
  backgroundColour?: string;
  marginMobile: Margin;
  textAlignment?: Maybe<TextAlignment>;
  colourTheme?: ColourTheme;
  items: Array<BlockMediaCarouselSimpleMobileOnlyItemVariation>;
  newCarousel?: boolean;
}

const MediaCarouselSimpleMobileOnlyStructure: FC<MediaCarouselSimpleMobileOnlyStructureProps> = ({
  backgroundColour,
  marginMobile,
  items,
  textAlignment,
  colourTheme,
  newCarousel,
}) => (
  <StyledCarouselSliderWrapper
    $textAlignment={textAlignment}
    $colourTheme={colourTheme}
    $backgroundColour={backgroundColour}
    $marginMobile={marginMobile}
  >
    {newCarousel ? <Carousel items={items} component={CarouselItemCard} lsIgnore={true} mobileOnly />
      : <MobileCarousel items={items} component={CarouselItemCard} lsIgnore={true} />}
  </StyledCarouselSliderWrapper>
);

export default MediaCarouselSimpleMobileOnlyStructure;
