import React, { FC, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { selectToggles } from '../../../../features/static';
import useMobileWatcher from '../../../../utils/customHooks/useMobileWatcher';
import { SizesGroupProps } from './SizesGroup.types';
import SizesGroupStructure from './SizesGroupStructure';

const SizesGroup: FC<SizesGroupProps> = ({
  title,
  links,
  fontSize,
  shouldCenter,
  sizesTextColourHex,
  sizesLinkColourHex,
}) => {
  const [isActive, setIsActive] = useState(true);
  const scrollRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const { isDesktop } = useMobileWatcher(['desktop'], theme.vars);
  const toggles = useSelector(selectToggles);
  const { engs14345ReactRoutingATagsToLinks } = toggles;

  const handlePlusMinusClick = (): void => {
    setIsActive(!isActive);
  };

  const handleRightClick = (): void => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += 150;
    }
  };

  const componentProps = {
    title,
    links,
    isDesktop: !!isDesktop,
    isActive,
    handlePlusMinusClick,
    handleRightClick,
    scrollRef,
    fontSize,
    shouldCenter,
    sizesTextColourHex,
    sizesLinkColourHex,
    useReactRoutingLinks: engs14345ReactRoutingATagsToLinks,
  };

  return <SizesGroupStructure {...componentProps} />;
};

export default SizesGroup;
