import styled from 'styled-components';

import { GlobalAnimationStyleObject } from '../../../../../sites/animations';
import { MarginWrapper } from '../../../../styles/contentBlockMedia';
import { Margin, TextAlignment } from '../../../../types/middleware-types';
import { StyledPriceValues } from '../../../Price/PriceStyles';
import {
  StyledBrand,
  StyledContentContainer,
  StyledProductName,
} from '../../../Product/ProductCard/ProductCardStyles';

interface StyledCarouselProductItemWrapperProps {
  $marginMobile: Margin;
  $marginDesktop: Margin;
  $textAlignment?: TextAlignment;
  $colourTheme: string;
}

export const StyledCarouselProductItemWrapper = styled.div<StyledCarouselProductItemWrapperProps>`
  width: auto;
  overflow: hidden;
  text-align: ${({ $textAlignment }) =>
  $textAlignment !== 'CENTRE' ? $textAlignment?.toLowerCase() : 'center'};
  color: ${({ theme, $colourTheme }) => {
    if ($colourTheme === 'LIGHT') {
      return theme.blockTextColors.light;
    }
    if ($colourTheme === 'DARK') {
      return theme.blockTextColors.dark;
    }
    return theme.blockTextColors.default;
  }};

  ${MarginWrapper} 

  a {
    p {
      font-size: 1.4rem;
      margin: 0 0.4rem;
      line-height: ${({ theme }) => theme.vars.sizeM};
      font-weight: 400;

      &.brand {
        font-weight: 800;
      }
    }
  }

  ${StyledContentContainer} {
    margin-bottom: -0.2rem;

    img {
      margin-bottom: 0;
    }
  }

  .content {
    padding-bottom: 0.8rem;
  }
`;

interface StyledproductCardAnimationWrapperProps {
  $animationType?: string;
  $animate?: boolean;
  $animationStyle: GlobalAnimationStyleObject;
  $showBrandNames?: boolean;
  $showPrices?: boolean;
  $showProductNames?: boolean;
}

export const StyledproductCardAnimationWrapper = styled.div<StyledproductCardAnimationWrapperProps>`
  ${({ $animationType, theme }) =>
  $animationType === 'MOVEFADE' || $animationType === 'BLOCKMOVEFADE'
    ? `
      transform: translateY(-100%);
      background:${theme.vars.white};`
    : ''};
  ${({ $animate, $animationType, $animationStyle }) => {
      if ($animate) {
        if ($animationType === 'MOVEFADE' || $animationType === 'BLOCKMOVEFADE') {
          return $animationStyle.scrollDown;
        }
      }
      return null;
    }}

  picture {
    img,
    source {
      margin-bottom: 0.4rem;
      width: 100%;
      ${({ $animationType }) =>
      $animationType === 'MOVEFADE' || $animationType === 'BLOCKMOVEFADE' ? 'opacity: 0' : ''};
      ${({ $animate, $animationType, $animationStyle }) => {
        if ($animate) {
          if ($animationType === 'MOVEFADE' || $animationType === 'BLOCKMOVEFADE') {
            return $animationStyle.fadeIn;
          }
        }
        return null;
      }}
    }
  }

  ${StyledBrand} {
    display: ${({ $showBrandNames }) => !$showBrandNames && 'none'};
  }
  ${StyledProductName} {
    display: ${({ $showProductNames }) => !$showProductNames && 'none'};
    white-space: nowrap;
  }
  ${StyledPriceValues} {
    display: ${({ $showPrices }) => !$showPrices && 'none'};
  }
`;
