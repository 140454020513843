/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC } from 'react';

import { componentList } from './componentList';

interface ComponentPickerProps {
  componentName: string | undefined,
  layout: string | undefined,
}

const ComponentPicker: FC<ComponentPickerProps> = ({ componentName, layout, ...props }) => {
  if (componentName === undefined) {
    return null
  }

  const Component = componentList?.components[componentName];

  return (
    <>
      {Component && <Component layout={layout} {...props} />}
    </>
  );
};

export default ComponentPicker;