import React, { FC } from 'react';

import { Margin } from '../../../types/middleware-types';
import cleanHtml from '../../../utils/formatters/cleanHtml';
import { StyledTab, StyledTabContent, StyledTabHolder, StyledTabs } from './TabsStyles';
import { Tab } from '.';

interface TabStructureProps {
  tabsData: Tab[];
  changeActiveTab: (index: number) => void;
  activeTab: number;
  marginMobile: Margin;
  marginDesktop: Margin;
}

const TabsStructure: FC<TabStructureProps> = ({
  tabsData,
  changeActiveTab,
  activeTab,
  marginMobile,
  marginDesktop,
}) => {
  return (
    <StyledTabHolder $marginMobile={marginMobile} $marginDesktop={marginDesktop}>
      <StyledTabs>
        {tabsData.map((tab, index) => (
          <StyledTab
            $tabActive={activeTab === index}
            onClick={() => changeActiveTab(index)}
            key={tab.title}
            data-hookid={`tab ${index}`}
          >
            {tab.title}
          </StyledTab>
        ))}
      </StyledTabs>

      <StyledTabContent
        dangerouslySetInnerHTML={{ __html: cleanHtml(tabsData[activeTab].rawHtml) }}
      />
    </StyledTabHolder>
  );
};

export default TabsStructure;
