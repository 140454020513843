import styled from 'styled-components';

import { FontSize, Maybe } from '../../../../types/middleware-types';

interface StyledSizesGroupWrapperProps {
  $sizesTextColourHex?: Maybe<string>;
}

export const StyledSizesGroupWrapper = styled.div<StyledSizesGroupWrapperProps>`
  button {
    border: none;
    background: transparent;

    svg {
      color: ${({ $sizesTextColourHex, theme }) =>
  $sizesTextColourHex ?? theme.blockTextColors.default};
    }
  }

  h3 {
    ${({ theme }) => theme.types.h4Style};
    color: ${({ $sizesTextColourHex, theme }) =>
    $sizesTextColourHex ?? theme.blockTextColors.default};
    display: inline-block;
    width: 9.6rem;
    text-align: left;
    margin: 2.4rem 0 1.6rem;
    text-transform: uppercase;

    @media ${({ theme }) => theme.devices.large} {
      width: auto;
    }
  }
`;

interface StyledSizesGroupLinksProps {
  $fontMediaSize: FontSize;
  $shouldCenter?: boolean;
  $sizesLinkColourHex?: Maybe<string>;
}

export const StyledSizesGroupLinks = styled.div<StyledSizesGroupLinksProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ $shouldCenter }) => ($shouldCenter ? 'center' : 'left')};
  color: ${({ $sizesLinkColourHex, theme }) => $sizesLinkColourHex ?? theme.blockTextColors.default};

  div {
    overflow-x: scroll;
    scroll-behavior: smooth;

    @media ${({ theme }) => theme.devices.large} {
      overflow: hidden;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  a {
    ${({ theme }) => theme.types.tertiaryLinkStyles};
    margin-right: calc((100vw - 16rem) / 5);
    color: ${({ $sizesLinkColourHex, theme }) => $sizesLinkColourHex ?? theme.blockTextColors.default};

    @media ${({ theme }) => theme.devices.large} {
      margin-right: 4.8rem;
    }

    &:last-child {
      margin-right: ${({ theme }) => theme.vars.sizeM};
    }
  }

  button {
    position: sticky;
    right: 0;
    font-size: ${({ theme }) => theme.vars.sizeM};
    height: ${({ theme }) => theme.vars.sizeXL};
    width: ${({ theme }) => theme.vars.sizeXL};
    padding: 0;
    margin: 0 0.4rem;

    svg {
      color: ${({ $sizesLinkColourHex, theme }) => $sizesLinkColourHex ?? theme.blockTextColors.default};
      height: 2.4rem !important;
      width: 2.4rem !important;
    }
  }
`;