'use client'

import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { DefaultTheme, useTheme, withTheme } from 'styled-components';

import { selectPageName, selectToggles } from '../../../features/static';
import {
  BlockMediaBasicItem,
  ContentBlockAnimationMediaCtAs,
  ContentBlockMediaCtAs,
  HtmlLink,
  Maybe,
  MultipleDefaultContentPositions,
  TextAlignment,
} from '../../../types/middleware-types';
import useAnimationOnScroll from '../../../utils/customHooks/useAnimationOnScroll';
import useMobileWatcher from '../../../utils/customHooks/useMobileWatcher';
import MediaCTAsStructure from './MediaCTAsStructure';

export interface MediaCTAsProps extends ContentBlockMediaCtAs {
  index?: number;
  mediaCTAs: Array<HtmlLink>;
  CTAsMediaItem: Maybe<BlockMediaBasicItem>;
  animationMediaCTAs?: Maybe<ContentBlockAnimationMediaCtAs>;
  theme?: DefaultTheme;
}

// Module F
const MediaCTAs: FC<MediaCTAsProps> = ({
  id,
  marginMobile,
  marginDesktop,
  textAlignment = TextAlignment.Centre,
  colourTheme,
  callToActionButtonBackgroundColourHex = 'transparent',
  contentDefaultPositionDesktop,
  contentCustomPositionDesktop: { topPercent: topDesktop, leftPercent: leftDesktop } = {},
  contentDefaultPositionMobile,
  contentCustomPositionMobile: { topPercent: topMobile, leftPercent: leftMobile } = {},
  callToActionStyleMobile,
  callToActionArrangementMobile,
  callToActionStyleDesktop,
  callToActionArrangementDesktop,
  callToActionIntro,
  mediaCTAs: callToActions,
  CTAsMediaItem: mediaItem,
  animationMediaCTAs: animationType,
  index: moduleIndex = 0,
  fontSize,
  backgroundColour = 'transparent',
}) => {
  const theme = useTheme();
  const { previewImage, engs14345ReactRoutingATagsToLinks } = useSelector(selectToggles);
  const { isDesktop } = useMobileWatcher(['desktop'], theme.vars);
  const { enteredView, animationStyle, forwardedRef } = useAnimationOnScroll(animationType);

  const textAlign =
    textAlignment?.toLowerCase() === 'centre' ? 'center' : textAlignment?.toLowerCase();
  const absoluteDesktop = contentDefaultPositionDesktop === MultipleDefaultContentPositions.Inside;
  const absoluteMobile = contentDefaultPositionMobile === MultipleDefaultContentPositions.Inside;

  const pageName = useSelector(selectPageName);

  const getButtonType = (): string => {
    if (isDesktop) {
      return callToActionStyleDesktop === 'TEXTLINK' ? 'tertiary' : 'secondary';
    }
    return callToActionStyleMobile === 'TEXTLINK' ? 'tertiary' : 'secondary';
  };

  const hasLinks = Object.values(callToActions).every(x => x.url !== null || x.url !== '');

  const componentProps = {
    id,
    marginMobile,
    marginDesktop,
    textAlignment: textAlign,
    colourTheme,
    callToActionButtonBackgroundColourHex,
    contentDefaultPositionDesktop,
    contentDefaultPositionMobile,
    absoluteDesktop,
    absoluteMobile,
    topDesktop,
    leftDesktop,
    topMobile,
    leftMobile,
    callToActionArrangement: isDesktop
      ? callToActionArrangementDesktop
      : callToActionArrangementMobile,
    callToActionStyle: isDesktop ? callToActionStyleDesktop : callToActionStyleMobile,
    callToActionIntro,
    callToActions,
    mediaItem: mediaItem
      ? {
        ...mediaItem,
        fontSizeParent: fontSize,
        marginDesktop: {},
        marginMobile: {},
      }
      : null,
    pageName,
    forwardedRef,
    animationType,
    animate: enteredView,
    animationStyle,
    moduleIndex,
    buttonType: getButtonType(),
    fontSize,
    backgroundColour,
    previewImage,
    useReactRoutingLinks: engs14345ReactRoutingATagsToLinks,
  };

  return (<>{hasLinks && (<MediaCTAsStructure {...componentProps} />)}</>)
};

export default withTheme(MediaCTAs);
