import styled from 'styled-components';

import { Margin, Maybe, MultipleDefaultContentPositions } from '../../../types/middleware-types';
import { StyledModuleContentWrapper } from '../MediaModuleContent/ModuleContentStyles';
import {
  StyledModuleItemLinkWrapper,
  StyledModuleItemMediaWrapper,
  StyledModuleItemWrapper,
} from '../MediaModuleItem/ModuleItemStyles';

interface StyledMediaParallaxProps {
  $marginDesktop: Margin;
  $contentDefaultPositionDesktop: MultipleDefaultContentPositions;
  $backgroundColour: Maybe<string>;
  $totalItems: number;
}

export const StyledMediaParallax = styled.div<StyledMediaParallaxProps>`
  display: flex;
  justify-content: flex-start;
  position: relative;

  /* Since the module items are set to 120% width, we have to set the width of
   the container so the first and the last item don't stay clipped */
  width: ${({ $totalItems }) => ($totalItems > 0 ? 100 / (1 + 0.2 / $totalItems) : 100)}%;
  margin: 0 auto;
  background-color: ${({ $backgroundColour }) => $backgroundColour};
  flex-direction: ${({ $contentDefaultPositionDesktop }) =>
  $contentDefaultPositionDesktop === 'BELOW' ? 'column' : 'column-reverse'};
  padding: ${({ $marginDesktop: { topPixels, rightPixels, bottomPixels, leftPixels } }) =>
    `${topPixels ?? 0}px ${rightPixels ?? 0}px ${bottomPixels ?? 0}px ${leftPixels ?? 0}px`};
`;

interface StyledParallaxWrapperProps {
  index: number;
}

export const StyledParallaxWrapper = styled.div<StyledParallaxWrapperProps>`
  flex: 1;
  ${StyledModuleItemLinkWrapper}
  ,
  ${StyledModuleItemWrapper} {
    overflow: unset;

    ${StyledModuleItemMediaWrapper} {
      width: 120%;
      margin-top: ${({ index }) => index * 10}%;
      margin-left: -10%;
      z-index: ${({ index }) => index + 1};
    }

    ${StyledModuleContentWrapper} {
      z-index: 10;
    }
  }
`;

export const StyledModuleItemsWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;
