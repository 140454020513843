'use client'

import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { selectPageName } from '../../../features/static';
import {
  BlockMediaCarouselItem,
  ContentBlockAnimationMediaCarousel,
  ContentBlockMediaCarousel,
  HtmlLink,
  Maybe,
  TextAlignment,
} from '../../../types/middleware-types';
import useAnimationOnScroll from '../../../utils/customHooks/useAnimationOnScroll';
import useMobileWatcher from '../../../utils/customHooks/useMobileWatcher';
import parseCMSMediaProp from '../../../utils/formatters/parseCMSMediaProp';
import MediaCarouselStructure from './MediaCarouselStructure';
import { mobileSettings, settings } from './settings';

export interface MediaCarouselProps extends ContentBlockMediaCarousel {
  mediaCarouselTitle?: Maybe<string>;
  mediaCarouselSubtitle?: Maybe<string>;
  mediaCarouselCta?: Maybe<HtmlLink>;
  mediaCarouselItems: Array<BlockMediaCarouselItem>;
  animationMediaCarousel?: Maybe<ContentBlockAnimationMediaCarousel>;
}

// Module F
const MediaCarousel: FC<MediaCarouselProps> = ({
  id,
  marginDesktop,
  marginMobile,
  mediaCarouselTitle: title,
  mediaCarouselSubtitle: subtitle,
  mediaCarouselCta: callToAction,
  textAlignment = TextAlignment.Centre,
  colourTheme,
  contentDefaultPositionDesktop,
  contentCustomPositionDesktop,
  contentCustomPositionMobile,
  contentDefaultPositionMobile,
  shouldScrollOnMobile,
  shouldScrollOnDesktop,
  background,
  mediaCarouselItems: items,
  fontSize,
  animationMediaCarousel: animationType,
  backgroundColour = 'transparent',
}) => {
  const theme = useTheme();
  const { isDesktop } = useMobileWatcher(['desktop'], theme.vars);
  const { enteredView, animationStyle, forwardedRef } = useAnimationOnScroll(animationType);

  const pageName = useSelector(selectPageName);

  const oneElementWidth = 100 / items.length;

  const moduleItemMarginsMobile = {
    leftPixels: 0,
    rightPixels: shouldScrollOnMobile ? 20 : 0,
    topPixels: 0,
    bottomPixels: 0,
  };

  const moduleItemsProps = items.map((item) => ({
    ...item,
    fontSizeParent: fontSize,
    marginDesktop: {},
    marginMobile: moduleItemMarginsMobile,
  }));

  const contentProps = {
    title,
    subtitle,
    callToAction,
    colourTheme,
    textAlignment,
    contentCustomPositionDesktop,
    contentDefaultPositionDesktop,
    fontSize,
    contentCustomPositionMobile,
    contentDefaultPositionMobile,
  };

  const hasNoItems = items.length === 0 || Object.values(items).every(x => !x.colourTheme);

  const componentProps = {
    id,
    contentDefaultPositionDesktop,
    contentDefaultPositionMobile,
    marginDesktop,
    marginMobile,
    moduleItemsProps,
    contentProps,
    shouldScrollOnMobile,
    shouldScrollOnDesktop,
    background: parseCMSMediaProp(background),
    oneElementWidth,
    settings: isDesktop ? settings : mobileSettings,
    pageName,
    forwardedRef,
    animationType,
    animate: enteredView,
    animationStyle,
    isDesktop,
    fontSize,
    backgroundColour
  };

  return <>{!hasNoItems && <MediaCarouselStructure {...componentProps} />}</>;
};

export default MediaCarousel;
