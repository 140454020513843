import styled from 'styled-components';

import { FullMediaWrapper } from '../../../../styles/contentBlockMedia';
import { Margin, MultipleDefaultContentPositions } from '../../../../types/middleware-types';
import { StyledSliderArrowContainer, StyledSliderWrapper } from '../../../Slider/SliderStyles';
import { StyledModuleContentWrapper } from '../../MediaModuleContent/ModuleContentStyles';
import { StyledCarouselABCDCarouselWrapper } from '../ContentBlockCarouselStyles';

interface StyledCarouselBCWrapperProps {
  $marginDesktop: Margin;
  $marginMobile: Margin;
  $backgroundColour: string;
  $showArrows: boolean;
  $contentDefaultPositionDesktop: MultipleDefaultContentPositions;
  $contentDefaultPositionMobile: MultipleDefaultContentPositions;
  $is404:boolean;
}

export const StyledCarouselBCWrapper = styled.div<StyledCarouselBCWrapperProps>`
  width: 100%;
  display: flex;
  position: relative;
  ${FullMediaWrapper};

  ${StyledCarouselABCDCarouselWrapper} {
    width: 100%;
  }

  ${StyledSliderWrapper} {
    padding-left: 33%;

    @media ${({ theme }) => theme.devices.large} {
      padding-left: 0;
      margin: 0 ${({ $showArrows }) => ($showArrows ? '0' : '4.8rem')};
    }
  }

  ${StyledSliderArrowContainer} {
    button {
      width: 4.8rem;
    }
  }

  ${StyledModuleContentWrapper} {
    h2 {
      ${({ theme, $is404 }) => $is404 && theme.types.h4Style};
    }
  }
`;
