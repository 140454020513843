import React, { forwardRef } from 'react';
import { Parallax } from 'react-scroll-parallax';

import {
  BlockMediaBasicItem,
  ColourTheme,
  ContentCustomPosition,
  FontSize,
  HtmlLink,
  Margin,
  Maybe,
  MultipleDefaultContentPositions,
  TextAlignment,
} from '../../../types/middleware-types';
import ModuleContent from '../MediaModuleContent';
import ModuleItem from '../MediaModuleItem';
import {
  StyledMediaParallax,
  StyledModuleItemsWrapper,
  StyledParallaxWrapper,
} from './MediaParallaxStyles';

export interface MediaParallaxStructureProps {
  id?: string;
  fontSize: FontSize;
  colourTheme: ColourTheme;
  backgroundColour: Maybe<string>;
  title?: Maybe<string>;
  subtitle?: Maybe<string>;
  pageName: Maybe<string> | undefined;
  isDesktop: boolean;
  items: Array<Maybe<BlockMediaBasicItem & { velocity?: number }>>;
  marginDesktop: Margin;
  textAlignment: Maybe<TextAlignment>;
  callToAction?: Maybe<HtmlLink>;
  contentDefaultPositionDesktop: MultipleDefaultContentPositions;
  contentCustomPositionDesktop: ContentCustomPosition;
}

const MediaParallaxStructure = forwardRef<HTMLDivElement, MediaParallaxStructureProps>(
  (
    {
      id,
      title,
      subtitle,
      callToAction,
      textAlignment,
      colourTheme,
      contentDefaultPositionDesktop,
      contentCustomPositionDesktop,
      marginDesktop,
      items,
      isDesktop,
      pageName,
      fontSize,
      backgroundColour,
    },
    ref,
  ) =>
    isDesktop ? (
      <StyledMediaParallax
        ref={ref}
        $marginDesktop={marginDesktop}
        $backgroundColour={backgroundColour}
        $contentDefaultPositionDesktop={contentDefaultPositionDesktop}
        data-hookid={`${pageName}MediaParallax`}
        $totalItems={items.length}
      >
        <StyledModuleItemsWrapper>
          {items.map(
            (item, i) =>
              item && (
                // eslint-disable-next-line react/no-array-index-key
                <StyledParallaxWrapper key={`${id}-${item?.title}-${i}`} index={i}>
                  {/* @ts-ignore */}
                  <Parallax y={[`${0}px`, `${item?.velocity ?? 0}px`]}>
                    <ModuleItem {...item} index={i} id={id} fontSizeParent={fontSize} />
                  </Parallax>
                </StyledParallaxWrapper>
              ),
          )}
        </StyledModuleItemsWrapper>

        <ModuleContent
          title={title}
          subtitle={subtitle}
          callToAction={callToAction}
          textAlignment={textAlignment}
          colourTheme={colourTheme}
          contentCustomPositionDesktop={contentCustomPositionDesktop}
          contentDefaultPositionDesktop={contentDefaultPositionDesktop}
          fontSize={fontSize}
        />
      </StyledMediaParallax>
    ) : null,
);

export default MediaParallaxStructure;
