import React, { FC } from 'react';
import Link from 'next/link';

import { GlobalAnimationsStyle } from '../../../../sites/animations';
import {
  BlockMediaBasicItem,
  ContentBlockAnimationMediaBasic,
  ContentBlockMediaDoubleHeightImageOnLeftSide,
  HtmlLink,
  Maybe,
} from '../../../types/middleware-types';
import { isImageV2 } from '../../../types/type-checkers';
import formatPromoEvent from '../../../utils/DataLayer/formatPromoEvent';
import ModuleContent from '../MediaModuleContent';
import ModuleItem from '../MediaModuleItem';
import {
  StyledModuleItemsCol,
  StyledModuleItemsWrapper,
  StyledModuleWrapper,
} from './MediaDoubleHeightImageOnLeftSideStyles';

export interface MediaDoubleHeightImageOnLeftSideStructureProps
  extends ContentBlockMediaDoubleHeightImageOnLeftSide {
  pageName: string | undefined;
  forwardedRef: (node: HTMLDivElement) => void;
  animate: boolean;
  animationType?: Maybe<ContentBlockAnimationMediaBasic>;
  animationStyle: GlobalAnimationsStyle;
  itemsDoubleHeightOnLeft?: Maybe<Array<Maybe<BlockMediaBasicItem>>>;
  callToAction?: Maybe<HtmlLink>;
  useReactRoutingLinks: boolean;
}

const MediaDoubleHeightImageOnLeftSideStructure: FC<
  MediaDoubleHeightImageOnLeftSideStructureProps
> = ({
  id,
  title,
  subtitle,
  callToAction,
  textAlignment,
  colourTheme,
  contentDefaultPositionDesktop,
  contentCustomPositionDesktop,
  contentCustomPositionMobile,
  contentDefaultPositionMobile,
  marginDesktop,
  marginMobile,
  itemsDoubleHeightOnLeft: items,
  forwardedRef,
  animationType,
  animate,
  animationStyle,
  pageName = '',
  fontSize,
  leftColumnWidth,
  backgroundColour,
  useReactRoutingLinks,
}) => {
  const otherItemProps = { parentIsLink: !!callToAction?.url };
  const linkAttr = callToAction?.newWindow ? { target: '_blank', rel: 'noreferrer' } : {};

  return (
    <StyledModuleWrapper
      ref={forwardedRef}
      $marginMobile={marginMobile}
      $marginDesktop={marginDesktop}
      $backgroundColour={backgroundColour}
      $contentDefaultPositionDesktop={contentDefaultPositionDesktop}
      $contentDefaultPositionMobile={contentDefaultPositionMobile}
      data-hookid={`${pageName}ModuleJ`}
    >
      {callToAction?.url ? (
        <>
          {useReactRoutingLinks ? (
            <Link href={callToAction.url} passHref legacyBehavior>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <a
                data-hookid="doubleHeightImageLinkWrapper"
                {...linkAttr}
                onClick={() =>
                  formatPromoEvent(
                    title,
                    1,
                    callToAction.url,
                    isImageV2(items?.[0]?.media) ? items?.[0]?.media?.desktop.src : '',
                    'cta1',
                    'click',
                  )}
              >
                <StyledModuleItemsWrapper
                  $animationType={animationType}
                  $animate={animate}
                  $animationStyle={animationStyle()}
                >
                  {items && items.length > 0 && (
                    <>
                      <StyledModuleItemsCol $leftColumnWidth={leftColumnWidth}>
                        {!!items[0] && (
                          <ModuleItem
                            {...items[0]}
                            {...otherItemProps}
                            id={id}
                            key={`${id}${items[0].title}${0}`}
                            animationType={animationType}
                            animate={animate}
                            animationStyle={animationStyle}
                            fontSizeParent={fontSize}
                          />
                        )}
                      </StyledModuleItemsCol>
                      <StyledModuleItemsCol $leftColumnWidth={leftColumnWidth}>
                        {!!items[1] && (
                          <ModuleItem
                            {...items[1]}
                            {...otherItemProps}
                            id={id}
                            key={`${id}${items[1].title}${1}`}
                            animationType={animationType}
                            animate={animate}
                            animationStyle={animationStyle}
                            fontSizeParent={fontSize}
                          />
                        )}
                        {!!items[2] && (
                          <ModuleItem
                            {...items[2]}
                            {...otherItemProps}
                            key={`${id}${items[2].title}${2}`}
                            animationType={animationType}
                            animate={animate}
                            animationStyle={animationStyle}
                            fontSizeParent={fontSize}
                          />
                        )}
                      </StyledModuleItemsCol>
                    </>
                  )}
                </StyledModuleItemsWrapper>
              </a>
            </Link>
          ) : (
            <a
              data-hookid="doubleHeightImageLinkWrapper"
              href={callToAction.url}
              {...linkAttr}
              onClick={() =>
                formatPromoEvent(
                  title,
                  1,
                  callToAction.url,
                  isImageV2(items?.[0]?.media) ? items?.[0]?.media?.desktop.src : '',
                  'cta1',
                  'click',
                )}
            >
              <StyledModuleItemsWrapper
                $animationType={animationType}
                $animate={animate}
                $animationStyle={animationStyle()}
              >
                {items && items.length > 0 && (
                  <>
                    <StyledModuleItemsCol $leftColumnWidth={leftColumnWidth}>
                      {!!items[0] && (
                        <ModuleItem
                          {...items[0]}
                          {...otherItemProps}
                          id={id}
                          key={`${id}${items[0].title}${0}`}
                          animationType={animationType}
                          animate={animate}
                          animationStyle={animationStyle}
                          fontSizeParent={fontSize}
                        />
                      )}
                    </StyledModuleItemsCol>
                    <StyledModuleItemsCol $leftColumnWidth={leftColumnWidth}>
                      {!!items[1] && (
                        <ModuleItem
                          {...items[1]}
                          {...otherItemProps}
                          id={id}
                          key={`${id}${items[1].title}${1}`}
                          animationType={animationType}
                          animate={animate}
                          animationStyle={animationStyle}
                          fontSizeParent={fontSize}
                        />
                      )}
                      {!!items[2] && (
                        <ModuleItem
                          {...items[2]}
                          {...otherItemProps}
                          key={`${id}${items[2].title}${2}`}
                          animationType={animationType}
                          animate={animate}
                          animationStyle={animationStyle}
                          fontSizeParent={fontSize}
                        />
                      )}
                    </StyledModuleItemsCol>
                  </>
                )}
              </StyledModuleItemsWrapper>
            </a>
          )}
        </>
      ) : (
        <StyledModuleItemsWrapper
          $animationType={animationType}
          $animate={animate}
          $animationStyle={animationStyle()}
        >
          {items && items.length > 0 && (
            <>
              <StyledModuleItemsCol $leftColumnWidth={leftColumnWidth}>
                {!!items[0] && (
                  <ModuleItem
                    {...items[0]}
                    {...otherItemProps}
                    id={id}
                    key={`${id}${items[0].title}${0}`}
                    animationType={animationType}
                    animate={animate}
                    animationStyle={animationStyle}
                    fontSizeParent={fontSize}
                  />
                )}
              </StyledModuleItemsCol>
              <StyledModuleItemsCol $leftColumnWidth={leftColumnWidth}>
                {!!items[1] && (
                  <ModuleItem
                    {...items[1]}
                    {...otherItemProps}
                    key={`${id}${items[1].title}${1}`}
                    animationType={animationType}
                    animate={animate}
                    animationStyle={animationStyle}
                    fontSizeParent={fontSize}
                  />
                )}
                {!!items[2] && (
                  <ModuleItem
                    {...items[2]}
                    {...otherItemProps}
                    key={`${id}${items[2].title}${2}`}
                    animationType={animationType}
                    animate={animate}
                    animationStyle={animationStyle}
                    fontSizeParent={fontSize}
                  />
                )}
              </StyledModuleItemsCol>
            </>
          )}
        </StyledModuleItemsWrapper>
      )}
      <ModuleContent
        title={title}
        subtitle={subtitle}
        callToAction={callToAction}
        textAlignment={textAlignment}
        colourTheme={colourTheme}
        contentCustomPositionDesktop={contentCustomPositionDesktop}
        contentDefaultPositionDesktop={contentDefaultPositionDesktop}
        fontSize={fontSize}
        contentCustomPositionMobile={contentCustomPositionMobile}
        contentDefaultPositionMobile={contentDefaultPositionMobile}
      />
    </StyledModuleWrapper>
  );
};

export default MediaDoubleHeightImageOnLeftSideStructure;
