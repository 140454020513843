import React, { FC } from 'react';

import { GlobalAnimationStyleObject } from '../../../../../sites/animations';
import {
  Margin,
  Maybe,
  Media,
  ProductListItem,
  ProductMedia,
  TextAlignment,
} from '../../../../types/middleware-types';
import { isProductMedia } from '../../../../types/type-checkers';
import ProductCard from '../../../Product/ProductCard';
import {
  StyledCarouselProductItemWrapper,
  StyledproductCardAnimationWrapper,
} from './CarouselProductItemStyles';

interface CarouselProductItemStructureProps {
  product: Omit<ProductListItem, 'media'>;
  images: Maybe<Media> | ProductMedia | undefined;
  animationType?: string;
  animate?: boolean;
  animationStyle: GlobalAnimationStyleObject;
  marginMobile: Margin;
  marginDesktop: Margin;
  textAlignment?: TextAlignment;
  colourTheme: string;
  pageName: string | undefined;
  showQuickBuy: boolean;
  addProductPositionToLocalStorage: (productPosition: number, lineNumber: string) => void;
  isNewInPLP: boolean | null;
  showBrandNames?: boolean;
  showPrices?: boolean;
  showProductNames?: boolean;
}

const CarouselProductItemStructure: FC<CarouselProductItemStructureProps> = ({
  product,
  marginMobile,
  marginDesktop,
  textAlignment,
  colourTheme,
  animationType,
  animate,
  animationStyle,
  pageName,
  showQuickBuy = false,
  addProductPositionToLocalStorage,
  images,
  isNewInPLP,
  showBrandNames,
  showPrices,
  showProductNames,
}) => {
  return (
    <StyledCarouselProductItemWrapper
      $marginMobile={marginMobile}
      $marginDesktop={marginDesktop}
      $textAlignment={textAlignment}
      $colourTheme={colourTheme}
    >
      <StyledproductCardAnimationWrapper
        $animationType={animationType}
        $animate={animate}
        $animationStyle={animationStyle}
        $showBrandNames={showBrandNames}
        $showPrices={showPrices}
        $showProductNames={showProductNames}
      >
        {isProductMedia(images) ? (
          <ProductCard
            {...product}
            images={images}
            showQuickBuy={showQuickBuy}
            addProductPositionToLocalStorage={addProductPositionToLocalStorage}
            pageName={pageName}
            componentName="CarouselProductItem"
            isNewInPLP={isNewInPLP}
          />
        ) : null}
      </StyledproductCardAnimationWrapper>
    </StyledCarouselProductItemWrapper>
  );
};

export default CarouselProductItemStructure;
