'use client'

import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { selectIsCompactHeader, selectPageName, selectShowBrandName } from '../../../features/static';
import { CustomMedia } from '../../../types/custom-types';
import {
  ContentBlockAnimationCelebrityStyleCarousel,
  ContentBlockCelebrityStyleCarousel,
} from '../../../types/middleware-types';
import useAnimationOnScroll from '../../../utils/customHooks/useAnimationOnScroll';
import useMobileWatcher from '../../../utils/customHooks/useMobileWatcher';
import { SliderSettings } from '../../Slider/Slider.types';
import CelebrityCarouselStructure from './CelebrityCarouselStructure';
import { mobileSettings, settings } from './settings';

export interface CelebrityCarouselProps extends ContentBlockCelebrityStyleCarousel {
  sliderCustomSettings?: SliderSettings;
  contentBlockCelebrityStyleCarouselTitle?: string;
  animationCelebrityStyleCarousel?: ContentBlockAnimationCelebrityStyleCarousel;
}

const CelebrityCarousel: FC<CelebrityCarouselProps> = ({
  items,
  contentBlockCelebrityStyleCarouselTitle: title,
  seeMoreCallToAction,
  itemCallToActionText,
  marginMobile,
  marginDesktop,
  animationCelebrityStyleCarousel: animationType,
  sliderCustomSettings,
  backgroundColour,
}) => {
  const theme = useTheme();
  const { isMobile, isTablet, isDesktop } = useMobileWatcher(
    ['mobile', 'tablet', 'desktop'],
    theme.vars,
  );
  const showBrandName = useSelector(selectShowBrandName);
  const isCompactHeader = useSelector(selectIsCompactHeader);
  const { enteredView, animationStyle } = useAnimationOnScroll(animationType);
  const [carouselState, setCarouselState] = useState({
    shownPanel: false,
    currentPanel: { index: 0, data: items[0] },
  });
  const pageName = useSelector(selectPageName);

  const { shownPanel, currentPanel } = carouselState;

  /**
   * Show/Hide celebrity card data and find data to show on mobile
   * @param index
   * @param open
   */
  const setPanelState = (index: number, open = true): void => {
    setCarouselState({ shownPanel: open, currentPanel: { index, data: items[index] } });
  };

  /**
   * Show/Hide celebrity card data
   * @param shown
   */
  const showPanel = (shown: boolean): void => {
    setCarouselState({ shownPanel: shown, currentPanel });
  };

  /**
   * Keep shown data and current celebrity card visible on the screen consistent when scrolling
   * @param index
   */
  const handleMobileSwiped = (index: number): void => {
    setCarouselState({ shownPanel, currentPanel: { index, data: items[index] } });
  };

  const hasNoItems =
    items.length === 0 || Object.values(items).every((x) => !x.name || !x.product?.lineNumber);

  const celebrityCards = items?.map((item, i) => {
    return {
      data: item,
      itemCallToActionText,
      cardIndex: i,
      shownPanel,
      currentPanel,
      showPanel,
      setPanelState,
      isMobile: !!isMobile,
      isTablet: !!isTablet,
      isDesktop: !!isDesktop,
      showOnMobile: false,
    };
  });

  const panelImage: CustomMedia = {
    mediaList: {
      mobile: {
        src: `${currentPanel.data?.product?.media?.productImages[0]?.url}`,
        width: 200,
        height: 200,
      },
      tablet: {
        src: `${currentPanel.data?.product?.media?.productImages[0]?.url}`,
        width: 200,
        height: 200,
      },
      desktop: {
        src: `${currentPanel.data?.product?.media?.productImages[0]?.url}`,
        width: 200,
        height: 200,
      },
    },
    defaultImageUrl: `${currentPanel.data?.product?.media?.productImages[0]?.url}`,
    alt: currentPanel.data?.product?.media?.productImages[0]?.alt || '',
    type: 'next-image',
  };

  const sliderSettings = isMobile
    ? {
      ...mobileSettings,
      ...sliderCustomSettings,
      afterChange: handleMobileSwiped,
    }
    : { ...settings, ...sliderCustomSettings };

  const componentProps = {
    celebrityCards,
    title: title || '',
    seeMoreCallToAction,
    shownPanel,
    currentPanel,
    showPanel,
    panelImage,
    sliderSettings,
    marginMobile,
    marginDesktop,
    pageName,
    isCompactHeader,
    animationType,
    animate: enteredView,
    animationStyle,
    showBrandName,
    backgroundColour,
  };

  return !hasNoItems ? <CelebrityCarouselStructure {...componentProps} /> : null;
};

export default CelebrityCarousel;
