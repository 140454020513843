import React, { FC } from 'react';

import { GlobalAnimationsStyle } from '../../../../../sites/animations';
import {
  BlockMediaCarouselItem,
  FontSize,
  Margin,
  Maybe,
  Media,
  ProductListItem,
  ProductMedia,
} from '../../../../types/middleware-types';
import { isCarouselDProductItem } from '../../../../types/type-checkers';
import Carousel from '../../../Carousel';
import ModuleItem from '../../MediaModuleItem';
import CarouselProductItem from '../CarouselProductItem';
import { StyledCarouselABCDCarouselWrapper } from '../ContentBlockCarouselStyles';
import {
  StyledCarouselDDesktopScrolbarItem,
  StyledCarouselDDesktopScrollbar,
  StyledCarouselDMediaWrapper,
  StyledCarouselDScrollbarWrapper,
  StyledCarouselDWrapper,
} from './CarouselDStyles';

interface CarouselDItemExtension {
  pageName: string;
  fontSizeParent: FontSize;
  componentName: string;
  marginMobile: { leftPixels: number; rightPixels: number };
  marginDesktop: { leftPixels: number; rightPixels: number };
  brand?: string;
  name?: string;
  campaignId?: Maybe<string>;
  showBrandNames?: boolean;
  showPrices?: boolean;
  showProductNames?: boolean;
}

export interface CarouselDBlockItemExtension
  extends CarouselDItemExtension,
    Omit<BlockMediaCarouselItem, 'media'> {
  media: Maybe<Media> | ProductMedia | undefined;
}

interface CarouselDProductItem extends Omit<ProductListItem, 'media'> {
  media: Maybe<Media> | ProductMedia | undefined;
  campaignId?: Maybe<string>;
}

export type CarouselDProductItemExtension = CarouselDProductItem & CarouselDItemExtension;

interface CarouselDStructureProps {
  isProductCarousel: boolean;
  mediaItem: Omit<BlockMediaCarouselItem, 'media'> & {
    fontSizeParent: FontSize;
    media: Maybe<Media> | undefined;
    marginDesktop: Margin;
    marginMobile: Margin;
  };
  items: Array<CarouselDBlockItemExtension | CarouselDProductItemExtension>;
  marginDesktop: Margin;
  marginMobile: Margin;
  isDesktop: boolean;
  top: number;
  pageName?: string;
  forwardedRef: (node: HTMLDivElement) => void;
  animationType?: string;
  animate?: boolean;
  animationStyle: GlobalAnimationsStyle;
  backgroundColour: string;
  isNewInPLP: boolean | null;
}

const CarouselDStructure: FC<CarouselDStructureProps> = ({
  isProductCarousel,
  mediaItem,
  items,
  marginMobile,
  marginDesktop,
  isDesktop,
  top,
  pageName,
  forwardedRef,
  animationType = '',
  animate = false,
  animationStyle,
  backgroundColour,
  isNewInPLP,
}) => {
  return (
    <StyledCarouselDWrapper
      $marginMobile={marginMobile}
      $marginDesktop={marginDesktop}
      $backgroundColour={backgroundColour}
      $top={top}
      data-hookid={`${pageName}CarouselD`}
      ref={forwardedRef}
    >
      <StyledCarouselDMediaWrapper $top={top}>
        <ModuleItem
          {...mediaItem}
          animationType={animationType}
          animate={animate}
          animationStyle={animationStyle}
        />
      </StyledCarouselDMediaWrapper>
      <StyledCarouselDScrollbarWrapper>
        {isDesktop ? (
          <StyledCarouselDDesktopScrollbar>
            {items.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <StyledCarouselDDesktopScrolbarItem key={`${item.brand}-${item.name}-${index}`}>
                {isProductCarousel && isCarouselDProductItem(item) ? (
                  <CarouselProductItem
                    animationType={animationType}
                    animate={animate}
                    animationStyle={animationStyle}
                    {...item}
                    index={index}
                    isNewInPLP={isNewInPLP}
                  />
                ) : (
                  <ModuleItem
                    {...item}
                    animationType={animationType}
                    animate={animate}
                    animationStyle={animationStyle}
                    index={index}
                  />
                )}
              </StyledCarouselDDesktopScrolbarItem>
            ))}
          </StyledCarouselDDesktopScrollbar>
        ) : (
          <StyledCarouselABCDCarouselWrapper>
            <Carousel
              showArrows={false}
              items={items}
              component={isProductCarousel ? CarouselProductItem : ModuleItem}
              scrollableOnMobile={true}
              animationType={animationType}
              animate={animate}
              animationStyle={animationStyle}
            />
          </StyledCarouselABCDCarouselWrapper>
        )}
      </StyledCarouselDScrollbarWrapper>
    </StyledCarouselDWrapper>
  );
};

export default CarouselDStructure;
