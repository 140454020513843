import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { selectPageName , selectToggles } from '../../../features/static';
import {
  BlockMediaBasicItem,
  ContentBlockAnimationMediaOverlap,
  ContentBlockMediaOverlap,
  HtmlLink,
  Maybe,
  TextAlignment,
} from '../../../types/middleware-types';
import useAnimationOnScroll from '../../../utils/customHooks/useAnimationOnScroll';
import useMobileWatcher from '../../../utils/customHooks/useMobileWatcher';
import MediaOverlapStructure from './MediaOverlapStructure';

export interface MediaOverlapProps extends ContentBlockMediaOverlap {
  animationMediaOverlap?: Maybe<ContentBlockAnimationMediaOverlap>;
  overlapItems?: Maybe<Array<Maybe<BlockMediaBasicItem>>>;
  callToAction?: Maybe<HtmlLink>;
}

// Module E
const MediaOverlap: FC<MediaOverlapProps> = ({
  textAlignment = TextAlignment.Centre,
  backgroundColour = 'transparent',
  ...otherProps
}) => {
  const { animationMediaOverlap: animationType, overlapItems: items } = otherProps;
  const { enteredView, animationStyle, forwardedRef } = useAnimationOnScroll(animationType);
  const theme = useTheme();
  const { isDesktop } = useMobileWatcher(['desktop'], theme.vars);
  const pageName = useSelector(selectPageName);
  const toggles = useSelector(selectToggles);
  const { engs14345ReactRoutingATagsToLinks } = toggles;

  const componentProps = {
    textAlignment,
    backgroundColour,
    ...otherProps,
    isDesktop: !!isDesktop,
    pageName,
    forwardedRef,
    animationType,
    animate: enteredView,
    animationStyle,
    items,
    useReactRoutingLinks: engs14345ReactRoutingATagsToLinks,
  };

  return items && items?.length > 0 ? <MediaOverlapStructure {...componentProps} /> : null;
};

export default MediaOverlap;
