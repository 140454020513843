import React, { FC } from 'react';
import Link from 'next/link';

import { GlobalAnimationsStyle } from '../../../../sites/animations';
import { ContentBlockMediaOverlap, HtmlLink, Maybe } from '../../../types/middleware-types';
import { isImageV2 } from '../../../types/type-checkers';
import formatPromoEvent from '../../../utils/DataLayer/formatPromoEvent';
import ModuleContent from '../MediaModuleContent';
import ModuleItem from '../MediaModuleItem';
import { StyledModuleItemsWrapper, StyledModuleWrapper } from './MediaOverlapStyles';

export interface MediaOverlapStructureProps extends ContentBlockMediaOverlap {
  pageName: Maybe<string> | undefined;
  callToAction?: Maybe<HtmlLink>;
  forwardedRef: (node: HTMLDivElement) => void;
  isDesktop: boolean;
  animate?: boolean;
  animationType?: Maybe<string>;
  animationStyle: GlobalAnimationsStyle;
  useReactRoutingLinks: boolean;
}

const MediaOverlapStructure: FC<MediaOverlapStructureProps> = ({
  id,
  title,
  subtitle,
  callToAction,
  textAlignment,
  colourTheme,
  contentDefaultPositionDesktop,
  contentCustomPositionDesktop,
  contentCustomPositionMobile,
  contentDefaultPositionMobile,
  marginDesktop,
  marginMobile,
  items,
  forwardedRef,
  animationType,
  animate,
  animationStyle,
  isDesktop,
  pageName,
  fontSize,
  itemOverlapTopGap,
  backgroundColour,
  useReactRoutingLinks
}) => {
  const otherItemProps = { parentIsLink: !!callToAction?.url };
  const linkAttr = callToAction?.newWindow ? { target: '_blank', rel: 'noreferrer' } : {};

  return (
    <StyledModuleWrapper
      ref={forwardedRef}
      $marginMobile={marginMobile}
      $marginDesktop={marginDesktop}
      $backgroundColour={backgroundColour}
      $contentDefaultPositionDesktop={contentDefaultPositionDesktop}
      $contentDefaultPositionMobile={contentDefaultPositionMobile}
      data-hookid={`${pageName}ModuleE`}
    >
      {callToAction?.url ? (
        <>
          {useReactRoutingLinks ? (
            <Link href={callToAction.url} passHref legacyBehavior>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <a
                data-hookid="MediaOverlapWrapper"
                {...linkAttr}
                onClick={() =>
                  formatPromoEvent(
                    title,
                    1,
                    callToAction.url,
                    isImageV2(items?.[0]?.media) ? items?.[0]?.media?.desktop.src : '',
                    'cta1',
                    'click',
                  )}
              >
                <StyledModuleItemsWrapper
                  $animationType={animationType}
                  $animate={animate}
                  $animationStyle={animationStyle()}
                  $itemTop={itemOverlapTopGap}
                >
                  {isDesktop
                    ? items?.map(
                      (item, i) =>
                        item && (
                          <ModuleItem
                            {...item}
                            {...otherItemProps}
                            id={id}
                            index={i}
                            // eslint-disable-next-line react/no-array-index-key
                            key={`ModuleItem-${id}-${item?.title}-${i}`}
                            animationType={animationType}
                            animate={animate}
                            animationStyle={animationStyle}
                            fontSizeParent={fontSize}
                          />
                        ),
                    )
                    : items?.[0] && (
                      <ModuleItem
                        {...items[0]}
                        {...otherItemProps}
                        animationType={animationType}
                        animate={animate}
                        animationStyle={animationStyle}
                        fontSizeParent={fontSize}
                      />
                    )}
                </StyledModuleItemsWrapper>
              </a>
            </Link>
          ) : (
            <a
              data-hookid="MediaOverlapWrapper"
              href={callToAction.url}
              {...linkAttr}
              onClick={() =>
                formatPromoEvent(
                  title,
                  1,
                  callToAction.url,
                  isImageV2(items?.[0]?.media) ? items?.[0]?.media?.desktop.src : '',
                  'cta1',
                  'click',
                )}
            >
              <StyledModuleItemsWrapper
                $animationType={animationType}
                $animate={animate}
                $animationStyle={animationStyle()}
                $itemTop={itemOverlapTopGap}
              >
                {isDesktop
                  ? items?.map(
                    (item, i) =>
                      item && (
                        <ModuleItem
                          {...item}
                          {...otherItemProps}
                          id={id}
                          index={i}
                          // eslint-disable-next-line react/no-array-index-key
                          key={`ModuleItem-${id}-${item?.title}-${i}`}
                          animationType={animationType}
                          animate={animate}
                          animationStyle={animationStyle}
                          fontSizeParent={fontSize}
                        />
                      ),
                  )
                  : items?.[0] && (
                    <ModuleItem
                      {...items[0]}
                      {...otherItemProps}
                      animationType={animationType}
                      animate={animate}
                      animationStyle={animationStyle}
                      fontSizeParent={fontSize}
                    />
                  )}
              </StyledModuleItemsWrapper>
            </a>
          )}
        </>
      ) : (
        <StyledModuleItemsWrapper
          $animationType={animationType}
          $animate={animate}
          $animationStyle={animationStyle()}
          $itemTop={itemOverlapTopGap}
        >
          {isDesktop
            ? items?.map(
              (item, i) =>
                item && (
                  <ModuleItem
                    {...item}
                    {...otherItemProps}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`ModuleItem-${id}-${item?.title}-${i}`}
                    animationType={animationType}
                    animate={animate}
                    animationStyle={animationStyle}
                    fontSizeParent={fontSize}
                  />
                ),
            )
            : items?.[0] && (
              <ModuleItem
                {...items[0]}
                {...otherItemProps}
                animationType={animationType}
                animate={animate}
                animationStyle={animationStyle}
                fontSizeParent={fontSize}
              />
            )}
        </StyledModuleItemsWrapper>
      )}

      <ModuleContent
        title={title}
        subtitle={subtitle}
        callToAction={callToAction}
        textAlignment={textAlignment}
        colourTheme={colourTheme}
        contentCustomPositionDesktop={contentCustomPositionDesktop}
        contentDefaultPositionDesktop={contentDefaultPositionDesktop}
        fontSize={fontSize}
        contentCustomPositionMobile={contentCustomPositionMobile}
        contentDefaultPositionMobile={contentDefaultPositionMobile}
      />
    </StyledModuleWrapper>
  );
};

export default MediaOverlapStructure;
