import React, { FC } from 'react';

import MediaElement from '../../basic/MediaElement';
import { CustomMedia } from '../../types/custom-types';
import { isNextMediaList } from '../../types/type-checkers';
import {
  GlobalBackgroundStyle,
  StyledLogoContainer,
  StyledLogoImageContainer,
} from './BackgroundStyles';

interface BackgroundStructureProps {
  background: CustomMedia;
  logo: CustomMedia;
  topOffset: number;
}

const BackgroundStructure: FC<BackgroundStructureProps> = ({
  background,
  logo,
  topOffset,
}) => (
  <>
    {background?.mediaList && isNextMediaList(background.mediaList) && (
      <GlobalBackgroundStyle
        $mobileBackground={background.mediaList.mobile?.src}
        $desktopBackground={background.mediaList.desktop?.src}
        $top={topOffset}
      />
    )}
    <StyledLogoContainer data-hookid="globalBackground">
      <StyledLogoImageContainer>
        <MediaElement {...logo} />
      </StyledLogoImageContainer>
    </StyledLogoContainer>
  </>
);

export default BackgroundStructure;
