'use client'

import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { selectPageName , selectToggles } from '../../../features/static';
import {
  BlockMediaBasicItem,
  ContentBlockAnimationMediaAndText50PercentSplit,
  ContentBlockMediaAndText50PercentSplit,
  Maybe,
  TextAlignment,
} from '../../../types/middleware-types';
import useAnimationOnScroll from '../../../utils/customHooks/useAnimationOnScroll';
import useMobileWatcher from '../../../utils/customHooks/useMobileWatcher';
import cleanHtml from '../../../utils/formatters/cleanHtml';
import { settings } from '../ContentBlockCarousel/settings';
import MediaAndText50PercentSplitStructure from './MediaAndText50PercentSplitStructure';

export interface MediaAndText50PercentSplitProps extends ContentBlockMediaAndText50PercentSplit {
  mediaItemModuleK: BlockMediaBasicItem;
  animationMediaAndText?: Maybe<ContentBlockAnimationMediaAndText50PercentSplit>;
  index?: number;
  scrollableOnDesktop?: boolean;
  arrowType?: string;
}

// Module K
const MediaAndText50PercentSplit: FC<MediaAndText50PercentSplitProps> = ({
  id,
  mediaItemModuleK: mediaItem,
  colourTheme,
  callToActions,
  title,
  subtitle,
  fontSize,
  contentCustomPositionDesktop: { topPercent, leftPercent },
  contentDefaultPositionDesktop,
  marginMobile,
  marginDesktop,
  mediaLeftOnDesktop,
  mediaTopOnMobile,
  animationMediaAndText: animationType,
  campaign,
  scrollableOnDesktop,
  contentHtml = '',
  index: moduleIndex = 0,
  arrowType = 'bottom',
  textAlignment = TextAlignment.Centre,
  backgroundColour = 'transparent',
  accordion,
}) => {
  const pageName = useSelector(selectPageName);

  const theme = useTheme();
  const { isMobile, isTablet } = useMobileWatcher(['mobile', 'tablet'], theme.vars);
  const isMobileOrTablet = isMobile || isTablet;

  const { enteredView, animationStyle, forwardedRef } = useAnimationOnScroll(animationType);
  /* Sets up pageName from context */
  const productItems = campaign?.products || [];

  const hasNoItems =
    productItems.length === 0 || Object.values(productItems).every((x) => !x.lineNumber);

  const toggles = useSelector(selectToggles);
  const useReactRoutingLinks = toggles?.engs14345ReactRoutingATagsToLinks;

  const componentProps = {
    id,
    marginMobile,
    marginDesktop,
    title,
    subtitle,
    colourTheme,
    textAlignment,
    fontSize,
    absolute:
      topPercent !== null || leftPercent !== null || contentDefaultPositionDesktop === 'INSIDE',
    top: topPercent,
    left: leftPercent,
    contentDefaultPositionDesktop,
    mediaItem: {
      ...mediaItem,
      fontSizeParent: fontSize,
      marginDesktop: {},
      marginMobile: {},
      moduleIndex,
    },
    callToActions,
    contentHtml: cleanHtml(contentHtml),
    mediaLeftOnDesktop,
    mediaTopOnMobile,
    pageName,
    forwardedRef,
    animationType,
    animate: enteredView,
    animationStyle,
    moduleIndex,
    hasCarousel: Array.isArray(productItems) && productItems.length > 0,
    carouselItems: !hasNoItems
      ? productItems?.map((item) => ({
        ...item,
        marginMobile: { leftPixels: 8, rightPixels: 8 },
        marginDesktop: { leftPixels: 8, rightPixels: 8 },
        campaignId: campaign?.id,
      }))
      : [],
    sliderSettings: { ...settings, arrows: arrowType === 'side', slidesToShow: 3 },
    showArrows: !isMobileOrTablet && arrowType === 'bottom' && !scrollableOnDesktop,
    scrollableOnDesktop,
    backgroundColour,
    accordion,
    useReactRoutingLinks,
  };

  return <MediaAndText50PercentSplitStructure {...componentProps} />;
};

export default MediaAndText50PercentSplit;
