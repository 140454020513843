import React, { FC } from 'react';
import { useTheme } from 'styled-components';

import { GlobalAnimationsStyle } from '../../../../../sites/animations';
import {
  BlockMediaCarouselItem,
  ContentBlockMediaCarouselWithMediaItem,
  ContentBlockProductCarouselWithMediaItem,
  ProductListItem,
  ProductRecommendations,
} from '../../../../types/middleware-types';
import { DEFAULT_ON_SCROLL_STYLE } from '../../../../utils/customHooks/useAnimationOnScroll';
import useMobileWatcher from '../../../../utils/customHooks/useMobileWatcher';
import { settings } from '../settings';
import CarouselAStructure from './CarouselAStructure';

interface ProductCarouselAProps extends ContentBlockProductCarouselWithMediaItem {
  pageName: string;
  forwardedRef: (node: HTMLDivElement) => void;
  animationType?: string;
  animate?: boolean;
  animationStyle?: GlobalAnimationsStyle;
  medias?: Array<BlockMediaCarouselItem>;
}

interface MediaCarouselAProps extends ContentBlockMediaCarouselWithMediaItem {
  pageName: string;
  forwardedRef: (node: HTMLDivElement) => void;
  animationType?: string;
  animate?: boolean;
  animationStyle?: GlobalAnimationsStyle;
  campaign?: ProductRecommendations;
  showBrandNames?: boolean;
  showPrices?: boolean;
  showProductNames?: boolean;
}

export type CarouselAProps = ProductCarouselAProps | MediaCarouselAProps;

const CarouselA: FC<CarouselAProps> = ({
  id,
  marginMobile,
  marginDesktop,
  mediaItem,
  campaign,
  medias = [],
  arrowPositionDesktop,
  scrollStyleOnDesktop,
  pageName,
  forwardedRef,
  animationType,
  animate,
  animationStyle = DEFAULT_ON_SCROLL_STYLE,
  fontSize,
  backgroundColour,
  showBrandNames,
  showPrices,
  showProductNames,
}) => {
  const theme = useTheme();
  const { isMobile, isTablet } = useMobileWatcher(['mobile', 'tablet'], theme.vars);
  const isMobileOrTablet = isMobile || isTablet;

  const products: ProductListItem[] = campaign?.products ?? [];

  const hasNoItems =
    (products.length === 0 && medias.length === 0) ||
    (products.length > 0 && Object.values(products).every((x) => !x.lineNumber)) ||
    (medias.length > 0 && Object.values(medias).every((x) => !x.colourTheme));

  const componentProps = {
    id,
    isProductCarousel: products.length > 0,
    mediaItem: {
      ...mediaItem,
      fontSizeParent: fontSize,
      media: mediaItem.media,
      marginDesktop: {},
      marginMobile: { bottomPixels: 20 },
    },
    carouselItems: (products.length ? products : medias).map(
      (item: ProductListItem | BlockMediaCarouselItem) => ({
        ...item,
        pageName,
        fontSizeParent: fontSize,
        componentName: 'CarouselA',
        marginMobile: { leftPixels: 8, rightPixels: 8 },
        marginDesktop: { leftPixels: 8, rightPixels: 8 },
        campaignId: campaign?.id,
        sizes: '(max-width: 1024px) 70vw, 25vw',
        showBrandNames,
        showPrices,
        showProductNames,
      }),
    ),
    marginMobile,
    marginDesktop,
    sliderSettings: {
      ...settings,
      arrows: arrowPositionDesktop === 'SIDE',
      slidesToShow: 3,
    },
    showArrows:
      !isMobileOrTablet &&
      arrowPositionDesktop === 'BOTTOM' &&
      scrollStyleOnDesktop !== 'SCROLLBAR',
    shouldScrollOnDesktop: scrollStyleOnDesktop === 'SCROLLBAR',
    pageName,
    forwardedRef,
    animationType,
    animate,
    animationStyle,
    fontSize,
    backgroundColour: backgroundColour ?? 'transparent',
  };

  return !hasNoItems ? <CarouselAStructure {...componentProps} /> : null;
};

export default CarouselA;
