import React, { FC } from "react";
import Link from 'next/link';

import NextImage from '../../../../basic/NextImage';
import { createMediaList } from "../../../../utils/helpers/createMediaList";
import { StyledCarouselItemCard, StyledCarouselItemCardImageWrapper, StyledCategoryTitle } from "./CarouselItemCardStyles";
import { BlockMediaCarouselSimpleMobileOnlyItemVariation } from ".";

const CarouselItemCardStructure: FC<BlockMediaCarouselSimpleMobileOnlyItemVariation> = (
  { callToActionMobileCarousel: callToAction, image, useReactRoutingLinks },
) => (
  <>
    {useReactRoutingLinks ? (
      <Link href={callToAction.url} passHref legacyBehavior>
        <StyledCarouselItemCard>
          <StyledCarouselItemCardImageWrapper>
            <NextImage
              mediaList={createMediaList(image.mobile)}
              alt={image.alt || ''}
            />
          </StyledCarouselItemCardImageWrapper>
          <StyledCategoryTitle>{callToAction.text}</StyledCategoryTitle>
        </StyledCarouselItemCard>
      </Link>
    ) : (
      <StyledCarouselItemCard
        href={callToAction.url}
      >
        <StyledCarouselItemCardImageWrapper>
          <NextImage
            mediaList={createMediaList(image.mobile)}
            alt={image.alt || ''}
          />
        </StyledCarouselItemCardImageWrapper>
        <StyledCategoryTitle>{callToAction.text}</StyledCategoryTitle>
      </StyledCarouselItemCard>
    )}
  </>
)

export default CarouselItemCardStructure;
