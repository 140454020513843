'use client'

import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { GlobalAnimationsStyle } from '../../../../../sites/animations';
import { selectElements } from '../../../../features/elements';
import {
  BlockMediaCarouselItem,
  ContentBlockMediaCarouselWithMediaItem,
  ContentBlockProductCarouselWithMediaItem,
  ProductListItem,
  ProductRecommendations,
} from '../../../../types/middleware-types';
import useMobileWatcher from '../../../../utils/customHooks/useMobileWatcher';
import CarouselDStructure from './CarouselDStructure';

interface ProductCarouselDProps extends ContentBlockProductCarouselWithMediaItem {
  pageName: string;
  forwardedRef: (node: HTMLDivElement) => void;
  animationType?: string;
  animate?: boolean;
  animationStyle: GlobalAnimationsStyle;
  medias?: Array<BlockMediaCarouselItem>;
  isNewInPLP: boolean | null;
}

interface MediaCarouselDProps extends ContentBlockMediaCarouselWithMediaItem {
  pageName: string;
  forwardedRef: (node: HTMLDivElement) => void;
  animationType?: string;
  animate?: boolean;
  animationStyle: GlobalAnimationsStyle;
  campaign?: ProductRecommendations;
  isNewInPLP: boolean | null;
  showBrandNames?: boolean;
  showPrices?: boolean;
  showProductNames?: boolean;
}

export type CarouselDProps = MediaCarouselDProps | ProductCarouselDProps;

const CarouselD: FC<CarouselDProps> = ({
  marginMobile,
  marginDesktop,
  mediaItem,
  campaign,
  medias = [],
  pageName,
  forwardedRef,
  animationType,
  animate,
  animationStyle,
  fontSize,
  backgroundColour,
  isNewInPLP,
  showBrandNames,
  showPrices,
  showProductNames,
}) => {
  const theme = useTheme();
  const { header } = useSelector(selectElements);
  const [headerHeight, setHeaderHeight] = useState<number | null>(null);
  const { isDesktop } = useMobileWatcher(['desktop'], theme.vars);
  const products = campaign?.products ?? [];

  useEffect(() => {
    const computeHeaderHeight = () => {
      if (header && isDesktop) {
        setHeaderHeight(header.offsetHeight);
      }
    };

    window.addEventListener('scroll', computeHeaderHeight);

    return () => window.removeEventListener('scroll', computeHeaderHeight);
  }, [isDesktop, header]);

  const hasNoItems =
    (products.length === 0 && medias.length === 0) ||
    (products.length > 0 && Object.values(products).every((x) => !x.lineNumber)) ||
    (medias.length > 0 && Object.values(medias).every((x) => !x.colourTheme));

  const componentProps = {
    isProductCarousel: products.length > 0,
    mediaItem: {
      ...mediaItem,
      fontSizeParent: fontSize,
      media: mediaItem.media,
      marginDesktop: {},
      marginMobile: { bottomPixels: 20 },
    },
    items: (products.length ? products : medias).map((item: ProductListItem | BlockMediaCarouselItem) => ({
      ...item,
      pageName,
      fontSizeParent: fontSize,
      componentName: 'CarouselD',
      marginMobile: { leftPixels: 8, rightPixels: 8 },
      marginDesktop: { leftPixels: 8, rightPixels: 8 },
      media: item.media,
      campaignId: campaign?.id,
      sizes: '(max-width: 1024) 70vw, 25vw',
      showBrandNames,
      showPrices,
      showProductNames,
    })),
    marginMobile,
    marginDesktop,
    isDesktop: !!isDesktop,
    top: headerHeight ?? 0,
    pageName,
    forwardedRef,
    animationType,
    animate,
    animationStyle,
    backgroundColour: backgroundColour ?? 'transparent',
    isNewInPLP,
  };

  return !hasNoItems ? <CarouselDStructure {...componentProps} /> : null;
};

export default CarouselD;
