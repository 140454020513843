import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectPageName , selectToggles } from '../../../features/static';
import {
  BlockMediaBasicItem,
  ContentBlockAnimationMediaBasic,
  ContentBlockMediaDoubleHeightImageOnLeftSide,
  Maybe,
  TextAlignment,
} from '../../../types/middleware-types';
import useAnimationOnScroll from '../../../utils/customHooks/useAnimationOnScroll';
import MediaDoubleHeightImageOnLeftSideStructure from './MediaDoubleHeightImageOnLeftSideStructure';

export interface MediaDoubleHeightImageOnLeftSideProps
  extends ContentBlockMediaDoubleHeightImageOnLeftSide {
  animationMediaCollage?: Maybe<ContentBlockAnimationMediaBasic>;
  itemsDoubleHeightOnLeft?: Maybe<Array<Maybe<BlockMediaBasicItem>>>;
}

// Module J2
const MediaDoubleHeightImageOnLeftSide: FC<MediaDoubleHeightImageOnLeftSideProps> = ({
  animationMediaCollage: animationType,
  backgroundColour = 'transparent',
  textAlignment = TextAlignment.Centre,
  ...otherProps
}) => {
  const { enteredView, animationStyle, forwardedRef } = useAnimationOnScroll(animationType);
  const pageName = useSelector(selectPageName);
  const toggles = useSelector(selectToggles);
  const { engs14345ReactRoutingATagsToLinks } = toggles;

  const componentProps = {
    ...otherProps,
    pageName,
    forwardedRef,
    animationType,
    animate: enteredView,
    animationStyle,
    backgroundColour,
    textAlignment,
    useReactRoutingLinks: engs14345ReactRoutingATagsToLinks,
  };
  return <MediaDoubleHeightImageOnLeftSideStructure {...componentProps} />;
};

export default MediaDoubleHeightImageOnLeftSide;
