'use client'

import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectPageName } from '../../../features/static';
import {
  BlockMediaBasicItem,
  ContentBlockAnimationMediaShopBySize,
  ContentBlockMediaShopBySize,
  Maybe,
} from '../../../types/middleware-types';
import useAnimationOnScroll from '../../../utils/customHooks/useAnimationOnScroll';
import MediaShopBySizeStructure from './MediaShopBySizeStructure';

export interface MediaShopBySizeProps extends ContentBlockMediaShopBySize {
  index?: number;
  animationMediaShopBySize?: Maybe<ContentBlockAnimationMediaShopBySize>;
  shopBySizeMediaItem?: Maybe<BlockMediaBasicItem>;
}

const MediaShopBySize: FC<MediaShopBySizeProps> = ({
  id,
  marginDesktop,
  marginMobile,
  mediaPromoTitle,
  fontSize,
  shopBySizeMediaItem: mediaItem,
  sizesBackgroundColourHex,
  sizesTextColourHex,
  sizesLinkColourHex,
  sizesItem,
  mediaItemOnLeft,
  animationMediaShopBySize: animationType,
  index: moduleIndex = 0,
}) => {
  const { enteredView, animationStyle, forwardedRef } = useAnimationOnScroll(animationType);
  const pageName = useSelector(selectPageName);
  
  const componentProps = {
    id,
    marginDesktop,
    marginMobile,
    mediaPromoTitle,
    fontSize,
    mediaItem,
    sizesItem,
    sizesBackgroundColourHex,
    sizesTextColourHex,
    sizesLinkColourHex,
    mediaItemOnLeft,
    pageName,
    forwardedRef,
    animationType,
    animate: enteredView,
    animationStyle,
    moduleIndex
  };
  return <MediaShopBySizeStructure {...componentProps} />;
};

export default MediaShopBySize;
