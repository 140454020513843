import React, { FC, Fragment } from 'react';

import {
  StyeldTierValue,
  StyledGrayOverlay,
  StyledLoyaltyDescription,
  StyledLoyaltyHeader,
  StyledLoyaltyWrapper,
  StyledRainbow,
  StyledTierInfo,
  StyledTierName,
  StyledTierSquare,
} from './LoyaltySchemeStyles';
import { LoyaltySchemeProps, LoyaltyTierFormated } from '.';

interface LoyaltySchemeStructureProps extends LoyaltySchemeProps {
  tiersFormated: Array<LoyaltyTierFormated>;
  currentPosition: number;
  currentTierName: string;
}

const LoyaltySchemeStructure: FC<LoyaltySchemeStructureProps> = ({
  tiersFormated,
  currentPosition,
  description,
  titleLoyalty: title,
  subtitleLoyalty: subtitle,
  currentTierName,
  currentPoints,
  pendingPoints,
  marginDesktop,
  marginMobile,
}) => {
  return (
    <StyledLoyaltyWrapper $marginDesktop={marginDesktop} $marginMobile={marginMobile}>
      <StyledLoyaltyHeader>
        <h2>{title}</h2>
        <p>{subtitle}</p>
        <StyledTierInfo>
          <h3>Your Loyalty Level: {currentTierName}</h3>
          <h3>Kindness Points: {currentPoints}</h3>
          <h3>Pending Kindness Points: {pendingPoints}</h3>
        </StyledTierInfo>
      </StyledLoyaltyHeader>
      <StyledRainbow>
        {tiersFormated && tiersFormated.map((tier, index) => (
          <Fragment key={tier.id}>
            <StyledTierSquare>
              <StyledTierName $currentIndex={index} $numberOfTiers={tiersFormated.length}>
                {tier.barLabel}
              </StyledTierName>
            </StyledTierSquare>
            {tier.tierLength && <StyeldTierValue $length={tier.tierLength} />}
          </Fragment>
        ))}
        <StyledGrayOverlay $length={currentPosition} />
      </StyledRainbow>
      <StyledLoyaltyDescription>({description})</StyledLoyaltyDescription>
    </StyledLoyaltyWrapper>
  );
};

export default LoyaltySchemeStructure;
