import React, { FC } from 'react';
import Link from 'next/link';

import { GlobalAnimationsStyle } from '../../../../sites/animations';
import MediaElement from '../../../basic/MediaElement';
import { CustomMedia } from '../../../types/custom-types';
import {
  ColourTheme,
  ContentBlockAnimationMediaFocus,
  ContentCustomPosition,
  FontSize,
  HtmlLink,
  Margin,
  Maybe,
  TextAlignment,
} from '../../../types/middleware-types';
import { isNextMediaList } from '../../../types/type-checkers';
import formatPromoEvent from '../../../utils/DataLayer/formatPromoEvent';
import {
  StyledLargeImageContainer,
  StyledMediaFocusWrapper,
  StyledSmallImageContainer,
  StyledSmallImageContent,
  StyledSmallImageLink,
  StyledTextContainer,
} from './MediaFocusStyles';

interface MediaFocusStructureProps {
  marginDesktop: Margin;
  marginMobile: Margin;
  textAlignment: Maybe<TextAlignment>;
  fontSize: FontSize;
  colourTheme: ColourTheme;
  backgroundColour: Maybe<string>;
  callToAction: HtmlLink;
  contentCustomPositionDesktop: ContentCustomPosition;
  pageName: Maybe<string> | undefined;
  forwardedRef: (node: HTMLDivElement) => void;
  animate: boolean;
  animationType?: Maybe<ContentBlockAnimationMediaFocus>;
  animationStyle: GlobalAnimationsStyle;
  index: number;
  title: string;
  subtitle: string;
  mediaLeftOnDesktop: boolean;
  mediaTopOnMobile: boolean;
  largeMediaObject: CustomMedia;
  smallMediaObject: CustomMedia;
  useReactRoutingLinks: boolean;
}

export const MediaFocusStructure: FC<MediaFocusStructureProps> = ({
  largeMediaObject,
  smallMediaObject,
  callToAction,
  colourTheme,
  marginDesktop,
  marginMobile,
  contentCustomPositionDesktop,
  title,
  subtitle,
  textAlignment,
  mediaLeftOnDesktop,
  mediaTopOnMobile,
  forwardedRef,
  animationType,
  animate,
  animationStyle,
  pageName,
  index,
  fontSize,
  backgroundColour,
  useReactRoutingLinks,
}) => {
  return (
    <StyledMediaFocusWrapper
      ref={forwardedRef}
      $marginDesktop={marginDesktop}
      $marginMobile={marginMobile}
      $backgroundColour={backgroundColour}
      $mediaLeftOnDesktop={mediaLeftOnDesktop}
      $mediaTopOnMobile={mediaTopOnMobile}
      data-hookid={`${pageName}ModuleH`}
      onClick={() =>
        formatPromoEvent(
          title,
          index,
          callToAction.url,
          isNextMediaList(largeMediaObject?.mediaList)
            ? largeMediaObject?.mediaList?.desktop?.src
            : '',
          'hero banner',
          'click',
        )}
    >
      <StyledLargeImageContainer
        $animationType={animationType}
        $animate={animate}
        $animationStyle={animationStyle()}
        $moduleIndex={index}
      >
        {useReactRoutingLinks ? (
          <Link href={callToAction.url} passHref legacyBehavior>
            <a
              {...(callToAction.newWindow ? { target: '_blank', rel: 'noreferrer' } : {})}
            >
              <MediaElement {...largeMediaObject} sizes="(max-width: 1024px) 100vw, 70vw" />
            </a>
          </Link>
        ) : (
          <a
            href={callToAction.url}
            {...(callToAction.newWindow ? { target: '_blank', rel: 'noreferrer' } : {})}
          >
            <MediaElement {...largeMediaObject} sizes="(max-width: 1024px) 100vw, 70vw" />
          </a>
        )}
      </StyledLargeImageContainer>
      <StyledSmallImageContainer>
        <StyledSmallImageContent $contentPositionDesktop={contentCustomPositionDesktop}>
          <StyledTextContainer
            $textAlignment={textAlignment}
            $colourTheme={colourTheme}
            $animationType={animationType}
            $animate={animate}
            $animationStyle={animationStyle()}
            $fontMediaSize={fontSize}
            $contentPositionDesktop={contentCustomPositionDesktop}
          >
            {title && (
              <div className="custom-size-title">
                <h2>{title}</h2>
              </div>
            )}
            <p className="custom-size-subtitle">{subtitle}</p>
            {useReactRoutingLinks && callToAction.text && (
              <Link href={callToAction.url} passHref legacyBehavior>
                <a
                  className="custom-size-link"
                  {...(callToAction.newWindow ? { target: '_blank', rel: 'noreferrer' } : {})}
                >
                  {callToAction.text}
                </a>
              </Link>
            )}
            {!useReactRoutingLinks && callToAction.text && (
              <a
                className="custom-size-link"
                href={callToAction.url}
                {...(callToAction.newWindow ? { target: '_blank', rel: 'noreferrer' } : {})}
              >
                {callToAction.text}
              </a>
            )}
          </StyledTextContainer>
          
          {useReactRoutingLinks ? (
            <Link href={callToAction.url} passHref legacyBehavior>
              <StyledSmallImageLink
                {...(callToAction.newWindow ? { target: '_blank', rel: 'noreferrer' } : {})}
              >
                <MediaElement {...smallMediaObject} imageType="intrinsic" />
              </StyledSmallImageLink>
            </Link>
          ) : (
            <StyledSmallImageLink
              href={callToAction.url}
              {...(callToAction.newWindow ? { target: '_blank', rel: 'noreferrer' } : {})}
            >
              <MediaElement {...smallMediaObject} imageType="intrinsic" />
            </StyledSmallImageLink>
          )}
        </StyledSmallImageContent>
      </StyledSmallImageContainer>
    </StyledMediaFocusWrapper>
  );
};
